import React, { useEffect, useRef } from 'react'
import { Header, HeroBlock, HeroFooter, HowWeWork, ImpactBlock, PayBlock, ProjectList, Testimonial } from './components'
import Footer from './components/Footer'
import ReactGA from 'react-ga4'

const LandingV3 = () => {
  const blocksRef = useRef({})

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        const blockName = entry.target.getAttribute('data-blockname')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (blocksRef.current[blockName!]) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blocksRef.current[blockName!] = true

        ReactGA.event({
          category: 'Scrolling LandingV3',
          action: `Scrolled to block LandingV3 ${blockName}`,
          label: `Scrolled to block LandingV3 ${blockName}`,
        })
        if (Object.keys(blocksRef.current).length === 7) {
          ReactGA.event({
            category: 'Scrolling LandingV3',
            action: 'Scrolled through entire page LandingV3',
          })
        }
      }
    })
  }

  useEffect(() => {
    const hash = window.location.hash
    if (hash && hash === '#landing_pay_block') {
      const teamSection = document.getElementById('landing_pay_block')
      if (teamSection) {
        window.scrollTo({
          top: teamSection.offsetTop - 120,
          behavior: 'smooth',
        })
      }
    }
  }, [])


  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  })

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-blockname]')
    blocks.forEach((block) => observer.observe(block))
    return () => {
      blocks.forEach((block) => observer.unobserve(block))
    }
  }, [observer])

  return (
    <div className={'w-full flex-col h-full flex-1 flex '}>
      <div data-blockname='Header'><Header /></div>
      <div data-blockname='HeroBlock'><HeroBlock /></div>
      <div data-blockname='HowWeWork'><HowWeWork /></div>
      <div data-blockname='ProjectList'><ProjectList /></div>
      <div data-blockname='ImpactBlock'><ImpactBlock /></div>
      <div data-blockname='PayBlock' className={'max-sm:px-4 sm:mx-auto'}><PayBlock /></div>
      <div data-blockname='Testimonial'><Testimonial /></div>
      <div data-blockname='HeroFooter'><HeroFooter /></div>
      <div data-blockname='Footer'><Footer /></div>
    </div>
  )
}

export default LandingV3
