import React from 'react'
import Header from '../LandingV4/components/Header'
import PrimaryButton from '../../components/PrimaryButton'
import { CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import ReactGA from 'react-ga4'
import { Bounce, toast } from 'react-toastify'
import * as Yup from 'yup'
import { AuthService } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import Success from '../Login/assets/svg/success.svg'

const validationSchema = Yup.object({
  password: Yup.string().required('Required'),
  repeatPassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const ResetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')

  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const handleLogin = () => {
    navigate('/checkout')
  }

  if (!token) {
    navigate('/login')
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      ReactGA.event({
        category: 'RP_BTN_REQUIRE_NEW_PASSWORD',
        action: 'RP_BTN_REQUIRE_NEW_PASSWORD',
        label: 'RP_BTN_REQUIRE_NEW_PASSWORD',
      })
      try {
        await AuthService.resetPassword({
          token: token ?? '',
          newPassword: values.password,
        })
        setIsSubmitted(true)
        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        let errorMessage = 'An error occurred during requesting change of password. Please try again.'
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message
        }
        toast.error(errorMessage, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
      }
    },
  })
  return (
    <div className={'w-full h-full flex min-h-[100vh] flex-col'}
         style={{
           background: 'radial-gradient(139.41% 126.64% at 28.52% -10.08%, #16BACF 3.72%, #189B0C 100%)',
         }}
    >
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div className={'h-full min-h-[90vh] w-full flex items-center justify-center px-4'}>
        {isSubmitted ? (
          <div
            className={'flex flex-col items-center sm:max-w-[420px] p-10 bg-white rounded-[16px] w-full justify-center gap-4'}>
            <img src={Success} alt={'success'} className={'w-40 h-40'} />
            <div
              className={'font-semibold font-Inter text-center sm:text-[28px] text-[20px]  pb-1 sm:leading-[32px] leading-[24px] max-sm:pt-4 max-sm:pb-5 text-[#0F0F0F] '}>
              Your password has been reset
            </div>
            <div className={'text-[#737373] text-center font-Inter'}>
              You can now sign in with your new password
            </div>
            <PrimaryButton
              onClick={handleLogin}
              className='!bg-black !w-full h-[48px] !my-[16px] rounded-xl justify-center items-center gap-2 inline-flex'
            >
              <div className='text-center text-white !text-[17px] font-semibold !font-Inter normal-case '>Sign In</div>
            </PrimaryButton>
          </div>
        ) : (
          <form className={'gap-4 flex sm:max-w-[420px] p-10 bg-white rounded-[16px] w-full flex-col'}
                onSubmit={formik.handleSubmit}>
            <div
              className={'font-semibold font-Inter sm:text-[28px] text-[20px]  pb-1 sm:leading-[32px] leading-[24px] max-sm:pt-4 max-sm:pb-5 text-[#0F0F0F] text-start'}>
              Change your password
            </div>
            <div>
              <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
                Password
              </div>
              <input
                id='password'
                name='password'
                placeholder='Enter your new password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.password}</div>
              ) : null}
            </div>
            <div>
              <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
                Repeat Password
              </div>
              <input
                id='repeatPassword'
                name='repeatPassword'
                placeholder='Repeat your password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.repeatPassword}
                className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
              />
              {formik.touched.repeatPassword && formik.errors.repeatPassword ? (
                <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.repeatPassword}</div>
              ) : null}
            </div>
            <PrimaryButton
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
              className=' !bg-black !w-full h-[48px] !my-[16px] rounded-xl justify-center items-center gap-2 inline-flex'
            >
              {formik.isSubmitting ? (<CircularProgress size={24} />) :
                <div
                  className='text-center text-white !text-[17px] font-semibold !font-Inter normal-case '>Reset password</div>
              }
            </PrimaryButton>
          </form>
        )}
      </div>
    </div>
  )
}

export default ResetPassword