import React from 'react'
import Arrow from '../../../LandingV3/icons/Arrow'

interface ProjectCardProps {
  title: string;
  description: string;
  image: string;
  logo: string;
  url: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  image,
  logo,
  url,
}) => {
  return <div className={'relative rounded-[14px] w-full sm:h-[320px] h-[506px] overflow-hidden '}>
    <img src={image} alt={title} className={'w-full h-full object-cover object-center'} />
    <div className={'absolute flex left-4 right-4 top-4 bottom-4 justify-between  flex-col'}>
      <div className='w-full flex-col justify-start items-start gap-1 inline-flex'>
        <div
          className='self-stretch text-white text-[28px] text-start font-semibold font-Inter leading-[28px]'>{title}</div>
        <div
          className='self-stretch text-white text-[17px] text-start font-normal font-Inter leading-[24px]'>{description}</div>
      </div>
      <div className='w-full flex-row justify-between items-end gap-1 inline-flex'>
        <img src={logo} alt={description} className={'max-h-[56px]'} />
        <div className='px-3 py-1.5 bg-green-600 rounded-md justify-start items-center gap-1.5 inline-flex'
             onClick={() => {
               window.open(`/projects${url}`, '_self')
             }}
        >
          <div className='text-white text-[17px] cursor-pointer font-medium font-Inter leading-tight'>Learn more
          </div>
          <Arrow stroke={'#fff'} />
        </div>
      </div>
    </div>
  </div>
}

export default ProjectCard