import React, { useState } from 'react'
import Button from '../Button'
import Arrow from '../../icons/Arrow'
import ReactGA from 'react-ga4'
import { Transition } from '@headlessui/react'
import KevinHorizontal from '../../assets/images/kevin_horiz.webp'
import KevinVertical from '../../assets/images/kevin_vert.webp'


const HowWeWork = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayClick = () => {
    ReactGA.event({
      category: 'HOME_BTN_PLAY_CLICKED_NEAR_VIDEO',
      action: 'HOME_BTN_PLAY_CLICKED_NEAR_VIDEO',
      label: 'HOME_BTN_PLAY_CLICKED_NEAR_VIDEO',
    });
    const horizontalVideo = document.getElementById('responsiveVideoHorizontal') as HTMLVideoElement | null;
    const verticalVideo = document.getElementById('responsiveVideoVertical') as HTMLVideoElement | null;

    const videoToPlay = window.innerWidth >= 729 ? horizontalVideo : verticalVideo;

    if (videoToPlay) {
      const playPromise = videoToPlay.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Playback started successfully
        }).catch(error => {
          console.error('Error attempting to play the video:', error);
          // Handle the error for not being able to play
        });
      }
    }
  };


  return (
    <div className='w-full px-4 py-16 flex-col justify-start items-start gap-8 inline-flex md:max-w-[640px] md:self-center'>
      <div className='flex-col justify-start items-start gap-6 flex'>
        <div className='flex-col justify-start items-start gap-2 flex'>
          <div
            className='text-[#37992E] text-xs font-bold uppercase tracking-wide'>Shape your tomorrow
          </div>
          <div className='text-[28px] text-start font-semibold leading-9'
               style={{
                 fontFamily: 'IBM Plex Serif',
               }}
          >Realise the rare convergence of profit and purpose
          </div>
        </div>
        <div className="text-start  text-[#525252] text-[17px] font-normal font-['Inter'] leading-normal">Pyrpose represents a watershed moment to influence change through the power of conscientious capital allocation.<br />
          Become a part of a vanguard collective earning 6-12% while catalysing positive impact on our planet.
        </div>
      </div>
      <div className='relative w-full h-full rounded-lg overflow-hidden '>
        <video
          id='responsiveVideoHorizontal'
          className='w-full h-full rounded-lg hidden md:block'
          controls
          playsInline
          onPlay={() => setIsPlaying(true)}
        >
          <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_horizontal_1107.webm'} type='video/webm' />
          <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_horizontal_1107.MP4'} type='video/mp4' />
        </video>
        <video
          id='responsiveVideoVertical'
          className='w-full h-full rounded-lg md:hidden min-h-[70vh]'
          onCanPlay={() => console.log('Video is ready to play')}
          onError={(e) => console.error('Video error:', e)}
          controls
          playsInline
          onPlay={() => setIsPlaying(true)}
        >
          {/* <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_vertical_231102.webm'} type={'video/webm'}/> */}
          <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_vertical_1108.MP4'} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <Transition
          show={!isPlaying}
          enter='transition-opacity duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className=" absolute bottom-4 flex items-start justify-start left-4 flex-col z-[10] w-full  ">
              <div className="text-start text-white text-[17px] font-semibold font-['Inter'] leading-normal">Kevin Kyer</div>
              <div className=" text-white text-[15px] font-normal font-['Inter'] leading-tight">CEO, Founder of Pyrpose</div>
            </div>
            <picture className='w-full h-full object-cover'>
              {/* Ensure KevinVertical and KevinHorizontal are valid image paths */}
              <source srcSet={KevinVertical} media="(max-width: 728px)" />
              <source srcSet={KevinHorizontal} media="(min-width: 729px)" />
              <img
                src={KevinVertical} // Use a valid fallback image path here
                alt='Kevin'
                className='w-full h-full object-cover rounded-lg'
              />
            </picture>
            <button
              className='absolute text-white rounded-lg p-2'
              onClick={handlePlayClick}
            >
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="72" height="72" rx="36" fill="white"/>
                <path d="M48 36L30 46.3923L30 25.6077L48 36Z" fill="#37992E"/>
              </svg>
            </button>
          </div>
        </Transition>
      </div>

      <Button text={<div className={'flex flex-row items-center justify-center gap-2'}>
        <div className='text-center text-[16px] font-medium '>Our mission</div>
        <Arrow />
      </div>} onClick={
        () => {
          ReactGA.event({
            category: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
            action: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
            label: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
          })
          window.open('/mission', '_self')
        }
      } disabled={false} type={'M'} />
    </div>
  )
}

export default HowWeWork