import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {client, getPaymentStatus} from '../../utils/api'
import IntroBg from '../../assets/svg/intro-bg.svg'
import CoverEmissionButton from '../Results/components/CoverEmissionButton'
import LoaderPulse from '../../components/LoaderPulse'
import TreeSphere from '../../assets/img/tree-sphere.png'
import LogoGreen from '../../assets/svg/logo-green.svg'
import TranslationText from '../../components/TranslationText'

const CheckTransactionScreen: React.FC = () => {
  const [transactionStatus, setTransactionStatus] = useState<'loading' | 'success' | 'error'>(
    'loading',
  )
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const paymentIntentId = queryParams.get('payment_intent')

    if (paymentIntentId) {
      fetchPaymentIntentStatus(paymentIntentId).then()
    } else {
      setTransactionStatus('error')
    }
  }, [location.search])

  async function fetchPaymentIntentStatus(paymentIntentId: string) {
    try {
      const response = await getPaymentStatus(paymentIntentId)
      setTransactionStatus(response.status === 'succeeded' ? 'success' : 'error')

      if(response?.token) {
        localStorage.setItem('token', response.token)
        client.defaults.headers.common['Authorization'] = `Bearer ${response.token}`
      }
      if(response.status === 'succeeded') {
        localStorage.setItem('successPayment', 'true')
        navigate('/profile')
      }
    } catch (error) {
      console.error('Failed to fetch PaymentIntent status:', error)
      setTransactionStatus('error')
    }
  }

  const TransactionSuccess = () => {
    return (
      <div
        className={
          'flex flex-1 items-center max-sm:gap-10 max-sm:justify-start max-sm:mt-10 max-h-full justify-center flex-col w-full container px-4 sm:gap-12'
        }
      >
        <div className={'w-full flex flex-row items-center justify-center mb-[-40px]'}>
          <img src={LogoGreen} alt={'Logo'} className={'sm:max-w-[400px] w-2/3 ml-[-32px]'} />
        </div>
        <img
          src={TreeSphere}
          alt={'Tree sphere'}
          className={'sm:max-w-[400px] sm:max-h-[400px] max-sm:max-h-[300px] max-sm:max-w-[300px] w-full sm:h-11/12'}
        />
        <div className={'max-w-[512px] w-full'}>
          <div className={'flex flex-col gap-2'}>
            <TranslationText translationKey={'success-page.title'} className={'text-[24px] text-center font-bold'}/>
            <TranslationText translationKey={'success-page.text'} className={'text-[16px] text-center pb-4'}/>
          </div>
          <CoverEmissionButton
            onClick={() => {
              navigate('/profile')
            }}
            big
            text={'View my Profile'}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={'flex items-center justify-center flex-col flex-1  w-full'}
      style={{
        backgroundImage: `url(${IntroBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      {transactionStatus === 'loading' && (
        <div className={'flex flex-1 items-center justify-center w-full'}>
          <LoaderPulse />
        </div>
      )}
      {transactionStatus === 'success' && <TransactionSuccess />}
      {transactionStatus === 'error' && <div>Error in transaction. Please try again.</div>}
    </div>
  )
}

export default CheckTransactionScreen
