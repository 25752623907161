import React from 'react'
import Header from '../LandingV4/components/Header'
import Footer from '../LandingV4/components/Footer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleETF } from '../../utils/api'
import { Footer as MobileFooter } from '../LandingV3/components'

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  answer: Yup.string().required('Required'),
})

const FormETF: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      answer: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      await handleETF(values).then(() => {
        setIsLoading(false)
        setIsSubmitted(true)
      })
        .catch((error) => {
            setIsLoading(false)
            console.log(error)
          },
        )
    },
  })

  return (
    <div className={'w-full h-full flex min-h-[100vh] flex-col bg-white'}>
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div className={'flex md:flex-row flex-col w-full h-full flex-1 min-h-full items-stretch justify-center'}>
        <div className='md:w-1/2 w-full relative min-h-full flex items-center justify-center '
             style={{
               background: 'radial-gradient(139.41% 126.64% at 28.52% -10.08%, #16BACF 3.72%, #189B0C 100%)',
             }}
        >
          <div className={' px-4 py-10 max-w-[700px] flex items-start justify-start flex-col'}>
            <div
              className=' text-white text-[64px] font-black font-Inter text-start leading-[64px]'>Check <br />your
              ETF’s <br />eco - credibility
            </div>
            <div
              className=' text-white text-xl mt-3 font-medium font-Inter text-start leading-normal'>You may think you are
              funding the future of the planet, but is your ETF really supporting green solutions?

            </div>
          </div>
        </div>
        <div
          className='md:pl-16 md:w-1/2 w-full pt-[120px] pb-[148px] md:justify-start justify-center px-4 items-center flex'>
          <div className={'max-w-[700px] w-full flex items-center justify-end flex-col'}>
            <div className={''}>
              {isLoading ? (
                <div className='flex justify-center items-center w-full h-full'>
                  <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#16BACF]' />
                </div>) : isSubmitted ? (
                <div className='flex-col justify-center items-center gap-4 flex'>
                  <div
                    className='text-stone-950 text-[42px] font-semibold font-Inter'>Thank you for your interest
                  </div>
                  <div
                    className='text-stone-950 text-[17px] font-normal font-Inter'>We will get back to you soon.
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={formik.handleSubmit}
                  className=' self-stretch flex-col justify-start items-start gap-4 inline-flex'>
                  <div className='self-stretch justify-start items-start gap-4 inline-flex'>
                    <div className='grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex'>
                      <div className='self-stretch  flex-col justify-start items-start gap-1.5 flex'>
                        <div className='text-neutral-600 text-sm font-medium font-Inter text-start leading-tight'>First
                          name*
                        </div>
                        <div
                          className={`w-full h-12 px-3.5 py-2.5 bg-white border-[1px] ${
                            formik.touched.name && !!formik.errors.name ? '!border-red-500' : ' border-[#CCCCCC]'
                          }  rounded-lg shadow justify-start items-center inline-flex`}>
                          <input
                            id='name'
                            type='text'
                            placeholder='First name'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            className={'w-full h-full text-zinc-500 text-[17px] font-normal font-Inter leading-normal outline-none '}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex'>
                      <div className='self-stretch  flex-col justify-start items-start gap-1.5 flex'>
                        <div className='text-neutral-600 text-sm font-medium font-Inter text-start leading-tight'>Last
                          name*
                        </div>
                        <div
                          className={`w-full h-12 px-3.5 py-2.5 bg-white border-[1px] ${
                            formik.touched.lastName && !!formik.errors.lastName ? '!border-red-500' : ' border-[#CCCCCC]'
                          } rounded-lg shadow justify-start items-center inline-flex`}>
                          <input
                            id='lastName'
                            type='text'
                            placeholder='Last name'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            className={'w-full h-full text-zinc-500 text-[17px] font-normal font-Inter leading-normal outline-none '}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='self-stretch  flex-col justify-start items-start gap-1.5 flex'>
                    <div className='self-stretch  flex-col justify-start items-start gap-1.5 flex'>
                      <div className='text-neutral-600 text-sm font-medium font-Inter text-start leading-tight'>Email*
                      </div>
                      <div
                        className={`w-full h-12 px-3.5 py-2.5 bg-white  rounded-lg shadow justify-start border-[1px] ${
                          formik.touched.email && !!formik.errors.email ? '!border-red-500' : ' border-[#CCCCCC]'
                        } items-center inline-flex`}>
                        <input
                          id='email'
                          type='text'
                          placeholder='Email'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className={'w-full h-full text-zinc-500 text-[17px] font-normal font-Inter leading-normal outline-none '}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='self-stretch h-[120px] flex-col justify-start items-start gap-1.5 flex'>
                    <div className='self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex'>
                      <div className='text-neutral-600 text-sm font-medium font-Inter text-start leading-tight'>Which
                        ETFs
                        would
                        you
                        like to investigate?
                      </div>
                      <div
                        className={`w-full px-3.5 py-2.5 bg-white border-[1px] ${
                          formik.touched.answer && !!formik.errors.answer ? '!border-red-500' : ' border-[#CCCCCC]'
                        }  rounded-lg shadow justify-start items-center inline-flex`}>
                     <textarea
                       id='answer'
                       placeholder='JGRN, QCLN, TAN, etc.'
                       rows={3}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.answer}
                       className='w-full text-zinc-500 text-[17px] font-normal font-Inter text-start leading-normal outline-none resize-none'
                       style={{ minHeight: '60px' }}
                     />
                      </div>
                    </div>
                  </div>
                  <button type='submit' onClick={
                    () => {
                      formik.handleSubmit()
                      formik.setSubmitting(false)
                    }
                  } disabled={formik.isSubmitting}
                          className='cursor-pointer self-stretch h-16 pt-4 flex-col justify-start items-start gap-2.5 flex'>
                    <div
                      className='self-stretch px-6 py-3.5 bg-stone-950 rounded-xl justify-center items-center gap-2 inline-flex'>
                      <div className='text-center text-white text-[17px] font-semibold font-Inter leading-tight'>Send
                      </div>
                    </div>
                  </button>
                </form>
              )
              }
            </div>
          </div>
        </div>
      </div>
      <Footer isMargin={false} />
      <div className={'md:hidden'}>
        <MobileFooter />
      </div>
    </div>
  )
}


export default FormETF