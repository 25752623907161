import React from 'react'
import DocumentsImage from '../../assets/svg/documents-background.svg'
import PyrposeLogo from '../../assets/svg/pyrpose-logo.svg'

const RiskDisclosure: React.FC = () => {
  return (
    <div className={'w-full h-full flex '}
         style={{
           backgroundImage: `url(${DocumentsImage})`,
           backgroundRepeat: 'repeat',
           backgroundSize: 'cover',
         }}
    >
      <div className={'container mx-auto max-sm:px-4 flex flex-col items-center '}>
        <div
          className={'flex items-center justify-center ml-[-16px] sm:w-3/12 w-10/12 py-4 sm:py-8 max-w-[512px]'}>
          <img src={PyrposeLogo} alt={'logo'} />
        </div>
        <section className='section-5'>
          <p className='paragraph-6'>
            <span className='text-span-3'>
              <strong className={'font-bold text-[56px] leading-[60px] text-black'}>Risk Disclosure</strong><br/>
            </span>
            Effective as of April 20th, 2024.<br />
            <br />

            <ol className='text-left' type='A' style={{listStyleType: 'upper-alpha'}}>
              <li>
                PREAMBLE
                <ul>
                  <li>The terms in this document – in particular the terms beginning with a capital letter – have the same meaning as the terms defined in the Terms and Conditions.</li>
                  <li>Any subscription made by the User is made at the sole risk of the User. By making a subscription, the User expressly declares that it is aware that he/she/it is participating in the fundraising of a Project, which carries certain risks. Pyrpose and the Sponsors do not perform any verification of the suitability or appropriateness of the subscription made by the User with the experience, knowledge, financial situation, and investment objectives of the said User.</li>
                  <li>Users who contemplate subscribing to a Project shall take into consideration – in addition to the information contained in other documents such as a prospectus – the risk factors mentioned below. Each risk factor may have a negative impact on the Project, the Sponsor’s activity and its financial situation (including financial income) and the investment made by the User.</li>
                  <li>The list of risk factors below is not exhaustive. Pyrpose does not provide financial, tax, or legal advice to the User. Pyrpose hereby expressly recommends that the User consults a legal, financial, and/or tax advisor within its tax jurisdiction before subscribing to a Project. Moreover, Pyrpose expressly reserves the risks mentioned in any issuance prospectus or other ad hoc risk factors specific to the relevant Project.</li>
                </ul>
              </li>
              <li>
                GENERAL RISKS
                <ul>
                  <li>
                    PRECAUTIONARY MEASURES<br />
                    Prior to reading the general risk warnings, we would like to point out that inexperienced investors are always advised to consult a trusted financial expert. Never put “all eggs in one basket” and study our client&apos;s offering and the risk profiles of the investments in detail. You should never invest more than 5% of your net worth into individual products with a high-risk profile. FINMA, the Swiss Financial Market Supervisory Authority, offers support and inputs about how to properly evaluate offerings from individual and non-regulated providers. You can always reach out to us if you feel insecure or have any questions. We are always happy to help!
                  </li>
                  <li>
                    All Projects listed on the Website are related to climate and energy transition and therefore carry the risks associated with an investment in loans. Furthermore, as the Website and part of the Projects are run through the internet, specific associated risks may also be applicable when using the Website and communicating with Pyrpose through electronic channels (see the non-exhaustive list indicated under C below).
                  </li>
                  <li>
                    Risks linked to the investment instrument<br/>
                    The investment via an investment instrument may be made via standardized debts.<br/>
                    When subscribing to an investment instrument, the User is exposed to the risks in relation to the investment instrument.<br/>
                  </li>
                </ul>
                <ol className='list-decimal' type='1'>
                  <li>
                    Credit and default risks<br />
                    The Sponsor, can get into financial difficulties and in bankruptcy proceeding, including making it incapable of payment of the interests rates. The User is aware that in case the Sponsor is in bankruptcy proceeding, the User may lose all its investment.
                  </li>
                  <li>
                    Limited liquidity<br />
                    Loans are limited liquidity markets. Loans on the Website are not subject to an organized secondary market. Therefore, in particular, there is no guarantee whatsoever that the User who subscribes to such investment instruments finds an investor interested in purchasing of these loans.
                  </li>
                </ol>
              </li>
              <li>
                Use of internet (including the Website), User Account, and emails
                <ol className='list-decimal' type='1'>
                  <li>
                    Internet and accessibility of the Website<br/>
                    There are specific characteristics, constraints, and risks related to Internet use which may lead to
                    a lack of access to the Website, including due to disruption of network operators or internet access
                    providers or network congestion. Pyrpose shall not be responsible for damages resulting from such
                    lack of access to the Website.<br/>
                    <br/>
                    Internet use may also imply a lack of privacy, and the attention of the User is drawn on the privacy
                    policy which forms an integral part of Pyrpose’s Terms and Conditions and describes the processing
                    of the User’s personal data on the Website.
                  </li>
                  <li>
                    User Account access<br />
                    It cannot be excluded that third parties gain access to the Users’ User Account, and Users should not provide information allowing access to their User Account to third parties to reduce this risk. Pyrpose shall not be responsible for all activities that occur under their User Account.
                  </li>
                  <li>
                    Communications by emails<br/>
                    Communications by emails may be intercepted, altered, or lost. Pyrpose disclaims all liability in
                    respect thereof.
                  </li>
                  <li>
                    Trojans, worms, and other viruses<br />
                    It cannot be excluded that the Website is infected with Trojans, worms, and other viruses, as well as programs that allow downloading of information, and there is a risk that the User and/or the User’s IT infrastructure could be affected by such programs through the use of the Website.
                  </li>
                </ol>
              </li>
            </ol>
          </p>
        </section>
      </div>
    </div>
  )
}

export default RiskDisclosure