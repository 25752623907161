import React from 'react'
import PrimaryButton from '../../../components/PrimaryButton'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import Typography from '@mui/material/Typography'

interface CoverEmissionButtonProps {
  setStep?: (step: number) => void
  showFriends?: number
  big?: boolean
  rainbow?: boolean
  onClick?: () => void
  text?: string
  disabled?: boolean
}

const CoverEmissionButton: React.FC<CoverEmissionButtonProps> = ({
  setStep,
  showFriends,
  big = false,
  rainbow = true,
  onClick,
  text,
  disabled,
}) => {
  return (
    <PrimaryButton
      disabled={disabled || false}
      onClick={() => {
        ReactGA.event({
          category: 'Reduce Button',
          action: 'Reduce button pressed',
          label: 'Reduce button pressed',
        })
        ReactPixel.track('InitiateCheckout')
        if (setStep) {
          setStep(1)
        }
        if (onClick) {
          onClick()
        }
      }}
      variant='contained'
      className={`w-full !font-bold !hover:bg-black sm:!h-[${
        big ? '54px' : '40px'
      }] max-sm:!h-[54px]`}
      sx={{
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#000',
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5)',
        },
        height: big ? '54px !important' : '40px !important',
      }}
      style={{
        borderRadius: '16px',
        backgroundColor: '#000 !important',
        textTransform: 'none',
        boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)',
        border: '1px solid #000',
      }}
    >
      <Typography
        variant={'h4'}
        sx={{
          fontSize: big ? '20px !important' : '16px !important',
        }}
        className={`${
          rainbow ? 'text-gradient animate-gradient ' : 'text-white '
        }  max-sm:!text-[20px] !font-bold sm:leading-[20px] leading-[24px]`}
      >
        {text
          ? text
          : showFriends === 0
          ? 'Cover your emission '
          : showFriends === 1
          ? 'Reduce friend`s'
          : 'Reduce both'}
      </Typography>
    </PrimaryButton>
  )
}

export default CoverEmissionButton
