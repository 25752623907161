import React from 'react'
import SuccessIcon from '../../../shared/icons/SuccessIcon'
import Button from '../../../../LandingV3/components/Button'

interface SuccessModalProps {
  closeModal: () => void

}

const SuccessModal: React.FC<SuccessModalProps> = ({ closeModal }) => {
  return (
    <div
      className={'fixed top-0 right-0 left-0 bottom-0 bg-[#00000020] z-[100] w-full h-[100vh] flex-1 flex items-center justify-center'}>
      <div className={'max-w-[468px] mx-4 w-full bg-white rounded-[20px] p-8 flex-col gap-6 flex items-center'}>
        <div className={'flex items-center flex-col justify-center w-full'}>
          <SuccessIcon />
          <div className='pt-3 text-center text-[17px] font-Inter leading-[24px]'>
            Payment is successful
          </div>
        </div>
        <div className={'flex  w-full flex-col gap-2'}>
          <div className={'text-[20px] font-Inter font-semibold leading-[28px] text-start text-[#0F0F0F]'}>
            Thank you for being part of the journey!
          </div>
          <div className={'text-[17px] font-Inter leading-[24px] text-start text-[#525252]'}>
            We are working on putting your contribution into action.
          </div>
          <div className={'flex flex-row w-full gap-2'}>
            📧
            <div className={'text-[17px] font-Inter leading-[24px] text-start text-[#525252]'}>
              Expect an email confirmation and the invoice to arrive soon in your inbox.
            </div>
          </div>
          <div className={'flex flex-row w-full gap-2'}>
            🌟
            <div className={'text-[17px] font-Inter leading-[24px] text-start text-[#525252]'}>
              Stay tuned for the updates on how your contribution is making a positive impact.
            </div>
          </div>
        </div>
        <Button
          text={<div className='text-center text-white  text-[17px] font-semibold font-[Inter]'>Continue</div>}
          onClick={closeModal}
          type='L'
        />
      </div>
    </div>
  )
}

export default SuccessModal