import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const usePageViewTimer = () => {
  useEffect(() => {
    const location = window.location.pathname
    const sendGAEvent = (time: number) => {
      ReactGA.event({
        category: `Page View Duration: ${time} --- ${location}`,
        action: `User stayed for ${time} seconds on ${location}`,
        label: location,
      })
    }



    const timersInSeconds = [5, 10, 15, 30, 60]
    const timers = timersInSeconds.map((time) =>
      setTimeout(() => sendGAEvent(time), time * 1000),
    )

    return () => timers.forEach((timer) => clearTimeout(timer))
  }, [window.location.pathname])
}

export default usePageViewTimer
