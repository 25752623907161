import * as React from 'react'
import { SVGProps } from 'react'

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      stroke={props?.stroke || '#0F0F0F'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.667}
      d='M4.167 10h11.667m0 0L10 4.167M15.834 10 10 15.833'
    />
  </svg>
)
export default ArrowIcon
