import React, { FunctionComponent } from 'react'
import { ThemeProvider } from '@mui/material'

import { theme } from './utils/theme'

type RootThemeProps = {
  // eslint-disable-next-line
  children: React.ReactElement<any, any>
}

export const RootThemeProvider: FunctionComponent<RootThemeProps> = (props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
)
