import React, { type FC } from 'react'
import { FootballField } from '../index'

interface IFootballFields {
  // eslint-disable-next-line
  fields: any
}

const FootballFields: FC<IFootballFields> = ({ fields }) => {
  // eslint-disable-next-line
  return fields?.map((field: any, index: number) => (
    <div
      key={index}
      style={{
        width:
          fields?.length === 1
            ? '80%'
            : fields?.length % 2 === 0 && fields?.length < 5
            ? '48%'
            : '30%',
      }}
    >
      <FootballField field={(field * 100).toString()} trees={(field * 100).toString()} />
    </div>
  ))
}

export default FootballFields
