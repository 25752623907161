import React from 'react'
import { GlassIcon, CheckIcon, CloudIcon, MoneyIcon } from '../../../../../assets/svg/InfoIconsV2'
import TranslationText from '../../../../../components/TranslationText'

const CardElements = [
  {
    color: '#C3DBF8',
    title: 'landing-page.main-block.infocard1.title',
    text: 'landing-page.main-block.infocard1.text',
    icon: CheckIcon,
  },
  {
    color: '#C4F2BD',
    title: 'landing-page.main-block.infocard2.title',
    text: 'landing-page.main-block.infocard2.text',
    icon: CloudIcon,
  },
  {
    color: '#AEE8DD',
    title: 'landing-page.main-block.infocard3.title',
    text: 'landing-page.main-block.infocard3.text\n',
    icon: MoneyIcon,
  },
  {
    color: '#FFD1D4',
    title: 'landing-page.main-block.infocard4.title',
    text: 'landing-page.main-block.infocard4.text',
    icon: GlassIcon,
  },
]

interface CardProps {
  color: string
  title: string
  text: string
  icon: string
}

const InfoCards: React.FC = () => {
  const Card: React.FC<CardProps> = ({ color, title, text, icon }) => (
    <div
      className='w-full h-full p-4 rounded-2xl mb-2 shadow shadow-inner flex-col justify-start items-start gap-4 inline-flex'
      style={{ background: color }}
    >
      <div className='w-12 h-12 p-2 bg-white rounded-[32px] justify-center items-center gap-2 inline-flex'>
        <div className='w-8 h-8 relative'>
          <img src={icon} alt={`icon:${color}`} className='w-full h-full object-contain' />
        </div>
      </div>
      <TranslationText translationKey={title} className="self-stretch text-start text-black text-lg font-bold font-['Inter'] leading-snug" />
      <TranslationText className="self-stretch text-start text-black text-sm font-normal font-['Inter'] leading-[18px]"
                       translationKey={text} />
    </div>
  )

  return (
    <div className={'flex flex-wrap items-stretch justify-between w-full px-4'}>
      {CardElements.map((card, index) => (
        <div key={index} className={'w-[45%]  m-1 flex-grow '}>
          <Card color={card.color} title={card.title} text={card.text} icon={card.icon} />
        </div>
      ))}
    </div>
  )
}

export default InfoCards
