import React from 'react'
import IceBerg from '../../assets/svg/iceberg.svg'
import Diagram from '../../assets/svg/diagram.svg'
import DiagramMobile from '../../assets/svg/diagram-mobile.svg'
import { animated, useSpring } from '@react-spring/web'
import AnimatedNumber from '../AnimatedNumber'
import { CONSTANTS } from '../../../../constants/values'

export enum ResultsType {
  primary = 'primary',
  secondary = 'secondary'

}

const resultsInfo = (emission: number) => {
  return [
    {
      title: 'Netherlands',
      value: 7.1,
      type: ResultsType.secondary,
    },
    {
      title: 'Denmark',
      value: 4.9,
      type: ResultsType.secondary,
    },
    {
      title: 'You',
      value: emission,
      type: ResultsType.primary,
    },
    {
      title: 'World',
      value: 4.7,
      type: ResultsType.secondary,
    },
    {
      title: 'Africa',
      value: 1.0,
      type: ResultsType.secondary,
    },
  ]
}

const emissionInfo = (emission: number) => {
  return [
    {
      title: 'Offset emissions',
      value: emission,
      price: '€' + (emission * CONSTANTS.PRICE_PER_MT).toFixed(0),
      type: ResultsType.primary,
    },
    {
      title: 'Purchase electric vehicle',
      value: 2.5,
      price: '€15000',
      type: ResultsType.secondary,
    },
    {
      title: 'Ride your bike',
      value: 0.5,
      price: '€0',
      type: ResultsType.secondary,
    },
    {
      title: 'Plant trees',
      value: 0.3,
      price: '€50',
      type: ResultsType.secondary,
    },
  ]
}


interface ResultsBlockProps {
  emission: number

}

const ResultsBlock: React.FC<ResultsBlockProps> = ({
  emission,
}) => {

  const maxEmission = Math.max(...resultsInfo(emission).map(result => result.value))

  const resultsWithPercentages = resultsInfo(emission).map(result => ({
    ...result,
    percentage: (result.value / maxEmission) * 100,
  }))

  const maxEmissionInfo = Math.max(...emissionInfo(emission).map(result => result.value))

  const emissionInfoWithPercentages = emissionInfo(emission).map(result => ({
    ...result,
    percentage: (result.value / maxEmissionInfo) * 100,
  }))

  return (
    <div className={'w-full flex items-center sm:px-12 justify-center bg-white '}>
      <div
        className={'container w-full max-w-[1400px]  pb-[120px] max-sm:pb-[80px] lg:pb-[160px]  flex flex-col gap-4 justify-between items-center rounded-md '}>
        <div
          className='text-black text-[34px] font-semibold max-sm:pl-4 font-Inter pt-[80px] pb-[24px] text-start w-full leading-[38px]'>Here
          is what that means
        </div>
        <div className={'w-full flex-row  flex-grow justify-between flex flex-wrap gap-[2%] gap-y-[2vw] '}>
          <div className={'xl:w-[74%] w-full bg-[#E5E5DF80] rounded-[12px] flex-col px-10 py-8'}>
            <div className={'w-full flex items-start justify-between max-sm:flex-col'}>
              <div className='text-[#0F0F0F] text-[24px] font-semibold text-start font-Inter leading-[31px]'>Your
                footprint is <AnimatedNumber to={emission} /> tons of CO2 per year
              </div>
              <a href={'https://ourworldindata.org/co2-and-greenhouse-gas-emissions#co2-and-greenhouse-gas-emissions-country-profiles'} target={'_blank'}
                className='text-right text-[#737373] cursor-pointer text-[15px] max-sm:w-full max-sm:text-end  mt-2 font-normal font-Inter underline leading-tight' rel="noreferrer">Source
              </a>
            </div>
            <div className={'w-full sm:mt-[54px] flex flex-row flex-nowrap h-[215px]'}>
              {
                resultsWithPercentages.map((result, index) => {
                  const [spring, setSpring] = useSpring(() => ({
                    height: '0%',
                    opacity: 1,
                    config: {
                      duration: 1600,
                    },
                  }))
                  setSpring({
                    height: result.percentage + '%',
                    opacity: 1,
                  })

                  return (
                    <div className={'w-[20%] relative h-[215px] flex items-end justify-center'}
                         key={result.title + index}>
                      <animated.div className={'mx-[15%] w-full rounded-t-[12px] max-sm:rounded-t-[4px] '}
                                    style={{
                                      backgroundColor: result.type === ResultsType.primary ? '#1F6631' : '#D3D3D0',
                                      height: spring.height.to((value) => value),
                                    }}
                      >
                      </animated.div>
                      <animated.div
                        style={{
                          opacity: spring.opacity.to((value) => value),
                        }
                        }
                        className={`text-center ${result.type === ResultsType.primary ? 'text-white' : 'text-[#0F0F0F]'} text-[24px] max-sm:text-[16px] absolute self-center top-[50%] bottom-[50%]  left-0 right-0 font-medium font-inter leading-loose`}>
                        <AnimatedNumber to={result.value} />
                      </animated.div>
                    </div>
                  )
                })
              }
            </div>
            <div className={'h-[1px] w-full bg-black'} />
            <div className={'w-full mt-[12px] flex-row flex-nowrap flex'}>
              {
                resultsInfo(emission).map((result, index) => (
                  <div key={result.title + result.title + index}
                       className={`w-[20%] sm:truncate ${result.type === ResultsType.primary ? 'font-semibold' : 'font-normal'} text-center text-neutral-600 text-[17px] max-sm:text-[11px]  font-Inter leading-normal`}>{result.title}</div>
                ))
              }
            </div>


          </div>
          {window.innerWidth > 1200 ? (
            <>
              <div
                className={'xl:w-[24%] md:w-[29%] w-full bg-[#0C4725] rounded-[12px] xl:px-10 p-4 xl:py-8 flex items-center justify-center flex-col'}>
                <div
                  className='text-[#83F279] text-start text-[24px] font-semibold font-Inter leading-[31px] mb-[36px]'>Your
                  emissions
                  currently melt <AnimatedNumber to={parseFloat((emission * 1.5).toFixed(2))} /> tons of glacier ice
                </div>
                <img src={IceBerg} alt='iceberg' />
                <div className={'w-full flex justify-end items-center'}>
                  <a href={'https://www.zmescience.com/science/news-science/glacier-melting-research-21032018/#:~:text=One%20kilogram%20of%20CO2,rise%2C%20ice%20starts%20to%20melt.'} target={'_blank'}
                    className='text-right text-[#82C57C] text-[15px] font-normal font-Inter underline leading-tight' rel="noreferrer">Source
                  </a>
                </div>
              </div>
              <div
                className={'xl:w-[39%] md:w-[69%] relative w-full bg-[#0C4725] rounded-[12px] xl:px-10 p-4 xl:py-8 flex sm:justify-between  xl:justify-center xl:flex-col sm:flex-row max-sm:flex-col max-sm:justify-center'}>
                <div className={'flex flex-col max-xl:w-[30%]'}>
                  <div className=' text-start'><span
                    className='text-[#CEF7C6] text-[24px]  font-semibold font-Inter leading-7'>Offsetting your emissions has </span><span
                    className='text-[#83F279] text-[24px] font-semibold font-Inter leading-7'><AnimatedNumber
                    to={1.8} />x</span><span
                    className='text-[#CEF7C6] text-[24px] font-semibold font-Inter leading-7'> the immediate impact of driving an electric car</span>
                  </div>
                  <div className={'flex items-start mt-[36px] justify-between'}>
                    <div className={'text-start  flex-1'}><span
                      className='text-white text-start  text-opacity-60 text-[17px] font-normal font-Inter leading-normal'>Annual savings in tons of CO</span><span
                      className='text-white text-opacity-60 text-[17px] font-normal font-Inter leading-normal'>2</span>
                    </div>
                    <a href={'https://coolclimate.berkeley.edu/calculator'} target={'_blank'}
                       className='text-right text-[#82C57C] flex-1 text-[15px] self-start h-full mt-[2px] sm:absolute relative xl:relative max-xl:right-4 max-xl:top-4 max-sm:top-0 max-sm:bottom-0 font-normal font-Inter underline leading-tight' rel="noreferrer">Source
                    </a>
                  </div>
                </div>
                <div className={'flex flex-col max-xl:w-[65%]'}>
                  <div className={'w-full mt-[54px] flex flex-row flex-nowrap h-[256px]'}>
                    {
                      emissionInfoWithPercentages.map((result, index) => {
                        const [spring, setSpring] = useSpring(() => ({
                          height: '0%',
                          opacity: 1,
                          config: {
                            duration: 1600,
                          },
                        }))
                        setSpring({
                          height: result.percentage + '%',
                          opacity: 1,
                        })

                        return (
                          <div className={'w-[25%] relative h-[256px] flex items-end justify-center'}
                               key={result.title + index}>
                            <animated.div
                              className={'mx-[8px] w-full rounded-t-[2px] flex items-center justify-center '}
                              style={{
                                backgroundColor: result.type === ResultsType.primary ? '#83F279' : '#1D723A',
                                height: spring.height.to((value) => value),
                                minHeight: 50,
                              }}
                            >
                              <animated.div
                                style={{
                                  opacity: spring.opacity.to((value) => value),
                                }
                                }
                                className={'text-center  text-[24px] absolute self-center  font-medium font-inter leading-loose'}>
                                <div className='text-center flex items-center justify-center flex-col gap-[1px]'><span
                                  className={`text-opacity-60 text-[17px] ${result.type === ResultsType.primary ? 'text-[#05320999] font-semibold' : 'text-[#FFFFFF99]'} font-normal font-Inter leading-[22px] `}><AnimatedNumber
                                  to={result.value} /><br /></span><span
                                  className={` text-[17px] font-normal ${result.type === ResultsType.primary ? 'text-[#053209] font-semibold' : 'text-[#FFFFFF]'} font-Inter leading-[22px]`}>{result.price}</span>
                                </div>
                              </animated.div>
                            </animated.div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={'w-full mt-[12px] flex-row flex-nowrap flex'}>
                    {
                      emissionInfoWithPercentages.map((result, index) => (
                        <div key={result.title + result.title + index}
                             className={`w-[25%] text-center ${result.type === ResultsType.primary ? 'text-[#CEF7C6] !font-semibold' : 'text-[#FFFFFF99]'} text-center font-normal  text-[15px]  font-Inter leading-normal`}>{result.title}</div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </>) : (
            <>
              <div
                className={'xl:w-[29%] md:w-[69%] w-full bg-[#0C4725] relative rounded-[12px] xl:px-10 p-4 xl:py-8 flex sm:justify-between  xl:justify-center xl:flex-col sm:flex-row max-sm:flex-col max-sm:justify-center'}>
                <div className={'flex flex-col sm:w-[30%] xl:w-full w-full'}>
                  <div className='xl:w-[80%] text-start'><span
                    className='text-[#CEF7C6] text-[24px]  font-semibold font-Inter leading-7'>Offsetting your emissions has </span><span
                    className='text-[#83F279] text-[24px] font-semibold font-Inter leading-7'><AnimatedNumber
                    to={1.8} />x</span><span
                    className='text-[#CEF7C6] text-[24px] font-semibold font-Inter leading-7'> the immediate impact of driving an electric car</span>
                  </div>
                  <div className={'flex items-start mt-[36px] justify-between'}>
                  <div className={'text-start  flex-1'}><span
                    className='text-white text-start text-opacity-60 text-[17px] font-normal font-Inter leading-normal'>Annual savings in tons of CO</span><span
                    className='text-white text-opacity-60 text-[17px] font-normal font-Inter leading-normal'>2</span>
                  </div>
                    <a href={'https://coolclimate.berkeley.edu/calculator'} target={'_blank'}
                       className='text-right text-[#82C57C] flex-1 text-[15px] self-start h-full mt-[2px] sm:absolute relative xl:relative max-xl:right-4 max-xl:top-4 max-sm:top-0 max-sm:bottom-0 font-normal font-Inter underline leading-tight' rel="noreferrer">Source
                    </a>
                  </div>
                </div>
                <div className={'flex flex-col sm:w-[65%] xl:w-full w-full'}>
                  <div className={'w-full mt-[54px] flex flex-row flex-nowrap h-[256px]'}>
                    {
                      emissionInfoWithPercentages.map((result, index) => {
                        const [spring, setSpring] = useSpring(() => ({
                          height: '0%',
                          opacity: 1,
                          config: {
                            duration: 1600,
                          },
                        }))
                        setSpring({
                          height: result.percentage + '%',
                          opacity: 1,
                        })

                        return (
                          <div className={'w-[25%] relative h-[256px] flex items-end justify-center'}
                               key={result.title + index}>
                            <animated.div
                              className={'mx-[8px] w-full rounded-t-[2px] flex items-center justify-center '}
                              style={{
                                backgroundColor: result.type === ResultsType.primary ? '#83F279' : '#1D723A',
                                height: spring.height.to((value) => value),
                                minHeight: 50,
                              }}
                            >
                              <animated.div
                                style={{
                                  opacity: spring.opacity.to((value) => value),
                                }
                                }
                                className={'text-center  text-[24px] absolute self-center  font-medium font-inter leading-loose'}>
                                <div className='text-center flex items-center justify-center flex-col gap-[1px]'><span
                                  className={`text-white text-opacity-60 text-[17px] ${result.type === ResultsType.primary ? 'text-[#05320999] font-semibold' : 'text-[#FFFFFF99]'} font-normal font-Inter leading-[22px] `}><AnimatedNumber
                                  to={result.value} /><br /></span><span
                                  className={` text-[17px] font-normal ${result.type === ResultsType.primary ? 'text-[#053209] font-semibold' : 'text-[#FFFFFF]'} font-Inter leading-[22px]`}>{result.price}</span>
                                </div>
                              </animated.div>
                            </animated.div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={'w-full mt-[12px] flex-row flex-nowrap flex'}>
                    {
                      emissionInfoWithPercentages.map((result, index) => (
                        <div key={result.title + result.title + index}
                             className={`w-[25%] text-center ${result.type === ResultsType.primary ? 'text-[#CEF7C6] !font-semibold' : 'text-[#FFFFFF99]'} text-center font-normal  text-[12px]  font-Inter leading-normal`}>{result.title}</div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div
                className={'xl:w-[24%] md:w-[29%] w-full bg-[#0C4725] rounded-[12px] xl:px-10 p-4 xl:py-8 flex items-center justify-center flex-col'}>
                <div
                  className='text-[#83F279] text-start text-[24px] font-semibold font-Inter leading-[31px] mb-[36px]'>Your
                  emissions
                  currently melt <AnimatedNumber to={parseFloat((emission * 1.5).toFixed(2))} /> tons of glacier ice
                </div>
                <img src={IceBerg} alt='iceberg' />
                <div className={'w-full flex justify-end items-center'}>
                  <a href={'https://www.zmescience.com/science/news-science/glacier-melting-research-21032018/#:~:text=One%20kilogram%20of%20CO2,rise%2C%20ice%20starts%20to%20melt.'} target={'_blank'}
                     className='text-right text-[#82C57C] text-[15px] font-normal font-Inter underline leading-tight' rel="noreferrer">Source
                  </a>
                </div>
              </div>
            </>)}
          <div
            className={'xl:w-[59%] w-full bg-[#E5E5DF80] rounded-[12px] flex-col px-10 py-8 max-sm:px-4 max-sm:py-4'}>
            <div className={'w-full flex max-sm:flex-col items-start justify-between'}>
              <div className='text-[#0F0F0F] text-[24px] font-semibold text-start font-Inter leading-[31px]'>If our
                emissions continue at the current rate, temperatures will increase by an average of 6 degrees by 2100,
                bringing about a massive climate shift...
              </div>
              <a href={'https://ig.ft.com/sites/climate-change-calculator/'} target={'_blank'}
                className='text-right text-[#737373] max-sm:text-end max-sm:w-full sm:ml-6 text-[15px] mt-2 font-normal font-Inter underline leading-tight' rel="noreferrer">Source
              </a>
            </div>
            <picture>
              <source media='(max-width: 768px)' srcSet={DiagramMobile} />
              <img src={Diagram} alt='Diagram' className='w-full mt-[56px]' />
            </picture>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultsBlock