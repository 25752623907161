import Givewatts from '../assets/images/givewatts.png'
import SuperpowerAfrica from '../assets/images/superpower-africa.png'
import Students from '../assets/images/students.webp'
import Stoves from '../assets/images/Stoves.webp'
import AfroAmericanWomen from '../../LandingV3/assets/images/AfroAmericanWomen.webp'
import SolarWorkers from '../../LandingV3/assets/images/SolarWorkers.webp'
import SolarPanel from '../../LandingV3/assets/images/SolarPanel.webp'
import SolarPanels from '../../LandingV3/assets/images/SolarPanels.webp'
import { NumberType } from './types'
import React from 'react'

export interface ProjectData {
  title: string
  description: string
  logo: string
  mainPhoto: string
  numbers: NumberType[]
  video: string
  impactBlock: {
    block: React.ReactNode
  }
}

export interface Project {
  url: string
  id: string
  data: ProjectData
}

export const mockProjects: Project[] = [
  {
    url: '/clean-cooking',
    id: 'cooking',
    data: {
      title: 'Clean cooking in homes',
      description: 'Replacement of fossil fuels with clean cooking stoves in communities',
      logo: Givewatts,
      mainPhoto: Stoves,
      numbers: [
        {
          value: 75,
          label: 'less producing of smoke',
          metrics: '%',
        },
        {
          value: 60,
          label: 'of fuel saving while using clean stoves',
          metrics: '%',
        },
        {
          value: 97,
          label: 'of families feel safer with clean stoves',
          metrics: '%',
        },
        {
          value: 95,
          label: 'of users report better air quality',
          metrics: '%',
        },
      ],
      video: 'https://www.youtube.com/embed/kln2pc2_ffs?si=2KP38RHoCu9VyoCd',
      impactBlock: {
        block: <div className={'w-full flex items-center  bg-white justify-center mx-auto '}>
          <div className='max-w-[640px] px-4 py-20 bg-white flex-col justify-start items-start gap-12 inline-flex'>
            <div className='self-stretch  flex-col justify-start items-start gap-8 flex'>
              <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
                <div
                  className='self-stretch text-start text-green-600 text-xs font-bold font-Inter uppercase leading-tight tracking-wide'>make
                  an impact
                </div>
                <div
                  className='self-stretch text-start text-stone-950 text-[28px] font-semibold font-IBM leading-9'>How
                  the energy efficient stoves make an impact
                </div>
              </div>
              <img className='w-full ' src={AfroAmericanWomen} />
              <div
                className='self-stretch text-neutral-600 text-start text-[17px] font-normal font-Inter leading-normal'>Traditional
                charcoal cooking stoves produce indoor air pollution that causes millions of deaths each year, and
                contribute to growing deforestation and climate change. In Kenya alone, it is estimated that 23,000
                people die from indoor pollution alone which is higher than the deaths caused by malaria or even road
                accidents.<br />
                Adoption of the energy efficient stove does not require any behavioural adaptation or learning. The
                cooking processes are identical, and most adopters continue cooking the same types and quantities of
                food as before.
              </div>
            </div>
            <div
              className='self-stretch px-4 py-8 bg-green-100 rounded-xl flex-col justify-start items-center gap-6 flex'>
              <div
                className='self-stretch text-center text-green-900 text-[20px] font-semibold font-Inter leading-7'>As
                well as cost efficiency, the cook stoves we distribute have several benefits to the customers including:
              </div>
              <div className='self-stretch justify-center items-start gap-10 inline-flex flex-wrap'>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>⚡️</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>High
                    power efficiency and generating fuel savings of up to 25$ in charcoal per month compared to an
                    ordinary charcoal stove.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🫧</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Low
                    emissions and 75% less smoke. Once lit, the Stove does not produce visible smoke. Keeps your kitchen
                    cleaner, with less soot on the walls and roof.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🛡️</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Safety:
                    its design makes it easy to carry with silicone handles designed for safety and comfort of mobility.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🏺</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Aesthetics:
                    the stove keeps its color longer.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🧼</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Better
                    cooking experience: the stove keeps pots clean, which means less scrubbing.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>💪</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Strong
                    & Durable: quality built with durability of 5+ years with proper usage.
                  </div>
                </div>
                <div className='max-w-[406px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🔥</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Clean
                    burn: the secondary airflow in the combustion chamber allows a cleaner burn of the charcoal, thus
                    reducing smoke up to 75% compared to ordinary charcoal stoves.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
      },
    },
  },
  {
    url: '/solar-at-schools',
    id: 'superpower-africa',
    data: {
      title: 'Solar energy in schools',
      description: 'Transition from fossil fuels to renewable energy in schools.',
      logo: SuperpowerAfrica,
      mainPhoto: SolarWorkers,
      numbers: [
        {
          value: 69,
          label: 'average savings for the school on energy bills every month',
          metrics: '%',
        },
        {
          value: 39,
          label: 'monthly average of energy sourced from the sun',
          metrics: '%',
        },
        {
          value: 33,
          label: 'tonnes of carbon avoided over ten years',
          metrics: 'k',
        },
        {
          value: 360,
          label: 'of load shedding in South Africa in the last year (as of September 2023)',
          metrics: 'days',
        },
        {
          value: 57,
          label: 'of the blackout, with a help of solar panels, the school survived 5,283 hours of a power outage',
          metrics: 'days',
        },
      ],
      video: 'https://www.youtube.com/embed/IGgGB3k8Lf4?si=GoqOJzp654b153wF',
      impactBlock: {
        block: <div className={'w-full flex items-center  bg-white justify-center mx-auto '}>
          <div className=' px-4 py-16 max-w-[640px] bg-white flex-col justify-start items-start gap-8 inline-flex'>
            <div className='self-stretc flex-col justify-start items-start gap-2 flex'>
              <div
                className='self-stretch text-green-600 text-xs font-bold text-start font-Inter uppercase leading-tight tracking-wide'>make
                an impact
              </div>
              <div className='self-stretch text-stone-950 text-[28px] text-start font-semibold font-IBM leading-9'>How
                solar energy makes an impact in schools
              </div>
            </div>
            <img className='w-full ' src={SolarPanel} />
            <div className='w-full text-neutral-600 text-[17px] text-start font-normal font-Inter leading-normal'>South
              Africa’s energy sector is the most coal-dependent and carbon intensive of the G20 countries. As such,
              installing solar to displace grid electricity has a disproportionately positive effect on bringing down
              carbon emissions.<br />At the same time, the national utility ESKOM is foundering in its ‘death spiral’
              and the aging, over-burdened power stations are failing. The entire energy landscape is in disarray with
              daily energy black-outs disrupting industry, forcing the closure of small businesses and affecting the
              lives of millions of people. The rich are installing solar panels at unprecedented rates. The rest of
              South Africa can’t afford the high upfront costs, so as usual, they are left in the dark.
            </div>
            <div
              className='py-6 border-t border-b border-green-600 text-start justify-start items-start gap-2.5 inline-flex'>
              <div className='w-full text-neutral-500 text-xl text-start font-semibold font-Inter leading-loose'>69% of
                people live below the food poverty line in South Africa, four children are murdered each week and the
                employed leave the neighbourhood early and return late, leaving pensioners and children at the mercy of
                daily gang violence.
              </div>
            </div>
            <div className='w-full text-neutral-600 text-[17px] text-start font-normal font-Inter leading-normal'>The
              School is a rare oasis of peace and emblematic of the hope, that with a decent education, a better future
              for the children is a possibility. Teaching is crippled by the black-outs and yet the school cannot afford
              a back-up solar system.<br />The Smart Schools project is designed to solve exactly this problem. We pay
              for and install a solar system to reduce the school’s dependency on the grid and keep the classrooms
              functioning through the black-outs. Smart meters measure all energy use and the school pay for each unit
              of solar electricity used at a rate pegged at 20% lower than the standard grid tariff. This revenue is
              used to pay back the initial loan and provide for all maintenance of the system. On top of this, money is
              set aside for a School Improvements Fund for the school to spend on new library books or computers (for
              example). Finally, funds are also set aside for the Pay-It-Forward Fund which ensures that after a few
              years, there is enough capital in place to install solar on another school.<br />This way the project is
              not only sustainable, but doubles its impact every few years. Through the Smart Schools project, finance
              finally becomes regenerative.
            </div>
          </div>
        </div>,
      },
    },
  },
  {
    url: '/solar-at-homes',
    id: 'solars-home',
    data: {
      title: 'Solar energy in homes',
      description: 'Replacement of kerosene with solar lamps in homes',
      logo: Givewatts,
      mainPhoto: SolarPanels,
      numbers: [
        {
          value: 75,
          label: 'increase in daily reading and study time for children',
          metrics: '%',
        },
        {
          value: 94,
          label: 'of families feel safer with solar lamps',
          metrics: '%',
        },
        {
          value: 15,
          label: 'increase in monthly savings for households',
          metrics: '%',
        },
        {
          value: 84,
          label: 'of users report better air quality',
          metrics: '%',
        },
      ],
      video: 'https://www.youtube.com/embed/OhLgGyBu2Zg?si=g2CKkNwUO3c_ZR1O',
      impactBlock: {
        block: <div className={'w-full flex items-center  bg-white justify-center mx-auto '}>
          <div className='max-w-[640px] px-4 py-20 bg-white flex-col justify-start items-start gap-12 inline-flex'>
            <div className='self-stretch flex-col justify-start items-start gap-8 flex'>
              <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
                <div
                  className='self-stretch text-start text-green-600 text-xs font-bold font-Inter uppercase leading-tight tracking-wide'>make
                  an impact
                </div>
                <div className='self-stretch text-start text-stone-950 text-[28px] font-semibold font-IBM leading-9'>How
                  the solar energy makes an impact in homes
                </div>
              </div>
              <div
                className='self-stretch text-neutral-600 text-[17px] font-normal text-start font-Inter leading-normal'>Most
                families still use tiny kerosene lamps to light their homes at night. People travel long distances by
                foot to charge their phones at the closest shopping center or market.<br />
                Without electricity, most families rely on expensive, polluting, and harmful kerosene fuels and
                gas-powered generators to power and light their home. Many people walk miles just to charge their mobile
                phone, find a place to study, or finish their day’s work.<br />
                There are a variety of solar lamps ranging from lanterns to home systems.
              </div>
              <img className='w-full rounded' src={Students} />
              <div className='self-stretch flex-col justify-start items-start gap-3 flex'>
                <div className='text-start text-stone-950 text-xl font-semibold font-Inter leading-7'>Solar
                  lanterns
                </div>
                <div
                  className='self-stretch text-start text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>Solar
                  lanterns provide long lasting portable lighting. These lamps have a durability period of 5+ years,
                  they are at least 15 times brighter than the kerosene lamps, they are easy to use, they retain charge
                  for at least 36 hours and can be used for mobile charging as well.
                </div>
              </div>
              <div className='self-stretch flex-col justify-start items-start gap-3 flex'>
                <div className=' text-start text-stone-950 text-xl font-semibold font-Inter leading-7'>Home systems
                </div>
                <div
                  className='self-stretch text-start text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>A
                  small-scale, autonomous electricity supply for households with multiple lighting points and wider
                  range of applications. The Home systems are energy efficient, low maintenance, easy to install,
                  cost-effective, versatile, environmentally friendly, and safe.
                </div>
              </div>
            </div>
            <div
              className='self-stretch px-4 py-8 bg-green-100 rounded-xl flex-col justify-start items-center gap-6 flex'>
              <div className='self-stretch text-center text-green-900 text-xl font-semibold font-Inter leading-7'>Some
                of the benefits/uses of these solar lamps include:
              </div>
              <div className='self-stretch justify-evenly flex-wrap items-start gap-10 inline-flex'>
                <div className='max-w-[250px] rounded-md flex-col justify-center items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🔦</div>
                  <div
                    className='w-44 text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>General
                    lighting of the house.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>⏰</div>
                  <div
                    className='w-[186px] text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Students
                    get more hours to study.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🧡</div>
                  <div
                    className='w-[222px] text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Health
                    benefits: the users are not affected by any harmful fumes.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🛡️</div>
                  <div
                    className='w-[228px] text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Safety
                    — customers use these lamps at night as security lights.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🐄</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Farmers
                    use the solar lamps for tending to their animals in the early morning or after dark.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>📺</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Customers
                    with the home systems that can power a Television get to remain in touch with what happens around
                    the world since they are able to watch TV.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🏪</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Shop
                    owners use these lamps as a source of light to run their businesses.
                  </div>
                </div>
                <div className='max-w-[250px] rounded-md flex-col justify-start items-center gap-1 inline-flex'>
                  <div className='text-center text-green-900 text-[32px] font-normal font-Inter'>🐟</div>
                  <div
                    className='self-stretch text-center text-green-900 text-[17px] font-normal font-Inter leading-normal'>Fishermen
                    use these lamps when they go out fishing early in the morning.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
      },
    },
  },
]