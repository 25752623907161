import * as React from 'react'
import { SVGProps } from 'react'
const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <rect width={64} height={64} fill="#33B027" rx={32} />
    <rect width={64} height={64} fill="url(#a)" rx={32} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.5}
      d="M20.5 30.222 30.654 40 44.5 24"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(64.16 14.27 11.333) scale(90.0537 98.8333)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34BED0" />
        <stop offset={1} stopColor="#44CE37" />
      </radialGradient>
    </defs>
  </svg>
)
export default SuccessIcon
