import React from 'react'
import Logo from '../../../../LandingV3/icons/Logo'
import ReactGA from 'react-ga4'
import SocialIg from '../../../../LandingV3/assets/icons/social-ig.svg'
import SocialLinkedIn from '../../../../LandingV3/assets/icons/social-linkedin.svg'
import YouTubeIcon from '../../../shared/icons/YouTubeIcon'

const ProfileFooter: React.FC = () => {


  return (
    <div
      className={'w-full bg-stone-950 flex-col  max-md:hidden backdrop-blur-[100px] flex sm:px-20  items-center justify-center '}>
      <div
        className={'flex flex-row justify-between items-start relative px-4 pt-16 pb-4 max-w-[1200px] container  '}>
        <div className={'w-2/12  flex flex-col justify-between items-start pb-[40px]'}>
          <div className={'ml-[-16px]'}>
            <Logo />
          </div>
          <span
            className='text-white text-opacity-50 text-[13px] text-start font-semibold'>Purpose Group SA,  </span>
          <span
            className='text-white text-opacity-50 text-[13px] text-start font-normal'> Sentier de Sales 9, Geneve 1205,<br /> Switzerland</span><br />
          <a href={'mailto:support@pyrpose.io'}
             className='text-white text-opacity-50 text-[13px] cursor-pointer text-start font-normal font-Inter leading-tight'>support@pyrpose.io</a>
        </div>
        <div className={'flex flex-row items-center w-4/12 justify-between'}>
        <div
          className='flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div className='flex-col justify-start items-start gap-3 flex'>
            <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                  action: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                  label: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                })
                window.open('/projects', '_self')
              }
              }
              className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Projects</a>
          </div>
        </div>
        <div
          className=' flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div className='flex-col justify-start items-start gap-3 flex'>
            <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                  action: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                  label: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                })
                window.open('/investors')
              }
              }
              className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>Profile</a>
          </div>
        </div>
        <div
          className=' flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div className='flex-col justify-start items-start gap-3 flex'>
            <div className='flex-col justify-start items-start gap-3 flex'>
              <a
                onClick={() => {
                  ReactGA.event({
                    category: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                    action: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                    label: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                  })
                  window.open('/privacy-policy', '_blank')
                }
                }
                className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Referral</a>
            </div>
          </div>
        </div>
        </div>
        <div
          className='flex-col justify-start mt-4 items-start gap-8 inline-flex '>
          <a href='mailto:support@pyrpose.io' onClick={() => {
            ReactGA.event({
              category: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
              action: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
              label: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
            })
          }
          }
             className='font-semibold text-[17px] p-3 bg-[#303030] rounded-[12px] text-white cursor-pointer font-Inter leading-normal'>Contact
            Us</a>
          <div className='justify-between w-full items-start gap-3 flex'>
            <div className={'cursor-pointer'} onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
                action: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
                label: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
              })
              window.open('https://www.instagram.com/pyrpose.io/', '_blank')
            }}>
              <img
                alt='ig'
                className='w-6 h-6'
                src={SocialIg}
              />
            </div>
            <a className={'cursor-pointer'} onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_LINKED_CLICKED',
                action: 'HOME_BTN_FOOTER_LINKED_CLICKED',
                label: 'HOME_BTN_FOOTER_LINKED_CLICKED',
              })
              window.open('https://www.linkedin.com/company/pyrpose/', '_blank')
            }}>
              <img
                alt='linkedin'
                className='w-6 h-6'
                src={SocialLinkedIn}
              />
            </a>
            <a onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
                action: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
                label: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
              })
              window.open('https://www.youtube.com/@Pyrpose', '_blank')

            }} className={'cursor-pointer'} target={'_blank'} rel='noreferrer'>
              <YouTubeIcon fill={'#FFFFFF80'} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileFooter