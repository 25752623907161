import Field100 from '../../../assets/img/FootballField/field-100.webp'
import Field90 from '../../../assets/img/FootballField/field-90.webp'
import Field80 from '../../../assets/img/FootballField/field-80.webp'
import Field70 from '../../../assets/img/FootballField/field-70.webp'
import Field60 from '../../../assets/img/FootballField/field-60.webp'
import Field50 from '../../../assets/img/FootballField/field-50.webp'
import Field40 from '../../../assets/img/FootballField/field-40.webp'
import Field30 from '../../../assets/img/FootballField/field-30.webp'
import Field20 from '../../../assets/img/FootballField/field-20.webp'
import Field10 from '../../../assets/img/FootballField/field-10.webp'
import Field0 from '../../../assets/img/FootballField/field-0.webp'
import Trees100 from '../../../assets/img/FootballField/trees-100.webp'
import Trees90 from '../../../assets/img/FootballField/trees-90.webp'
import Trees80 from '../../../assets/img/FootballField/trees-80.webp'
import Trees70 from '../../../assets/img/FootballField/trees-70.webp'
import Trees60 from '../../../assets/img/FootballField/trees-60.webp'
import Trees50 from '../../../assets/img/FootballField/trees-50.webp'
import Trees40 from '../../../assets/img/FootballField/trees-40.webp'
import Trees30 from '../../../assets/img/FootballField/trees-30.webp'
import Trees20 from '../../../assets/img/FootballField/trees-20.webp'
import Trees10 from '../../../assets/img/FootballField/trees-10.webp'

export const getFieldFromImage = (fieldValue: string) => {
  switch (fieldValue) {
    case '100':
      return Field100
    case '90':
      return Field90
    case '80':
      return Field80
    case '70':
      return Field70
    case '60':
      return Field60
    case '50':
      return Field50
    case '40':
      return Field40
    case '30':
      return Field30
    case '20':
      return Field20
    case '10':
      return Field10
    case '0':
      return Field0
    default:
      return Field0
  }
}
export const getTreesFromImage = (fieldValue: string) => {
  switch (fieldValue) {
    case '100':
      return Trees100
    case '90':
      return Trees90
    case '80':
      return Trees80
    case '70':
      return Trees70
    case '60':
      return Trees60
    case '50':
      return Trees50
    case '40':
      return Trees40
    case '30':
      return Trees30
    case '20':
      return Trees20
    case '10':
      return Trees10
    default:
      return Trees100
  }
}
