import React from 'react'
import Pipes from '../../../shared/assets/Pipes.png'

const Project = () => {
  return (
    <div className={'w-full flex flex-row rounded-[12px] overflow-hidden bg-[#F2F2EF] '}>
      <div className={'w-[480px] min-w-[480px] relative'}>
        <img src={Pipes} width={'100%'} alt={'pipes'} className={' relative'} />
      </div>
      <div className={'p-8 flex flex-col justify-between'}>
        <div className={'flex flex-col gap-4 w-full'}>
          <div className={'flex flex-row justify-between items-center'}>
            <div className={'font-Inter font-semibold text-[28px] leading-[32px]'}>
              Pressure Corp
            </div>
            <div className={'flex flex-row gap-4 items-center'}>
              <a href='https://projects.pyrpose.io/pressure-corp ' target={'_blank'}
                 className={'text-[17px] text-[#37992E] '} rel="noreferrer">Learn more</a>
            </div>
          </div>
          <div className={'font-Inter text-[17px] text-[#525252] w-1/2 text-start leading-[24px]'}>
            Pressure Corp harness waste pressure to generate clean energy by deploying our waste pressure power system
            at pipeline pressure letdown stations
          </div>
        </div>
        <div className={'w-full flex flex-row gap-4 items-center'}>
          <div
            className={'py-[6px] px-3 rounded-full border-[1px] flex flex-row gap-2  border-[#00000012] '}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_5098_1524)'>
                <path d='M5 21C5.5 16.5 7.5 13 12 11' stroke='#737373' strokeWidth='1.5' strokeLinecap='round'
                      strokeLinejoin='round' />
                <path
                  d='M9.00033 18C15.2183 18 19.5003 14.712 20.0003 6V4H15.9863C6.98633 4 4.00033 8 3.98633 13C3.98633 14 3.98633 16 5.98633 18H8.98633H9.00033Z'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              </g>
              <defs>
                <clipPath id='clip0_5098_1524'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className={'text-[15px] font-Inter text-[#525252] pt-[2px]'}>
              Clean energy
            </div>
          </div>
          <div
            className={'py-[6px] px-3 rounded-full border-[1px] flex flex-row gap-2  border-[#00000012] '}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_5098_1530)'>
                <path d='M12 17L10 19L12 21' stroke='#737373' strokeWidth='1.5' strokeLinecap='round'
                      strokeLinejoin='round' />
                <path
                  d='M10 19H19C19.3186 18.9836 19.6287 18.8912 19.9043 18.7305C20.1799 18.5698 20.4131 18.3456 20.5843 18.0764C20.7556 17.8073 20.86 17.501 20.8889 17.1833C20.9177 16.8656 20.8701 16.5456 20.75 16.25L20.2 15.25'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M8.53627 11.0001L7.80427 8.26807L5.07227 9.00007' stroke='#737373' strokeWidth='1.5'
                      strokeLinecap='round' strokeLinejoin='round' />
                <path
                  d='M7.80447 8.26807L3.30447 16.0621C3.15952 16.3461 3.08459 16.6608 3.08596 16.9797C3.08732 17.2986 3.16493 17.6126 3.31231 17.8954C3.45969 18.1782 3.67257 18.4217 3.93318 18.6055C4.1938 18.7893 4.4946 18.9081 4.81047 18.9521L5.95148 18.9761'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M15.4639 11L18.1959 11.732L18.9279 9' stroke='#737373' strokeWidth='1.5'
                      strokeLinecap='round' strokeLinejoin='round' />
                <path
                  d='M18.1956 11.7318L13.6956 3.93782C13.522 3.67026 13.287 3.44809 13.0101 3.28986C12.7331 3.13162 12.4224 3.04191 12.1037 3.02821C11.7851 3.01451 11.4678 3.07722 11.1783 3.2111C10.8888 3.34497 10.6356 3.54614 10.4396 3.79782L9.84863 4.77382'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
              </g>
              <defs>
                <clipPath id='clip0_5098_1530'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className={'text-[15px] font-Inter text-[#525252] pt-[2px]'}>
              Waste recycling
            </div>
          </div>
          <div
            className={'py-[6px] px-3 rounded-full border-[1px] flex flex-row gap-2  border-[#00000012] '}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_5098_1540)'>
                <path
                  d='M2.18164 11.9998H3.27255M11.9998 2.18164V3.27255M20.7271 11.9998H21.818M5.018 5.018L5.78164 5.78164M18.9816 5.018L18.218 5.78164'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path
                  d='M8.72772 16.3636C7.81187 15.6767 7.13535 14.7191 6.79401 13.6263C6.45266 12.5336 6.46379 11.3611 6.82581 10.2751C7.18783 9.18901 7.88239 8.24438 8.81111 7.575C9.73984 6.90561 10.8556 6.54541 12.0004 6.54541C13.1453 6.54541 14.2611 6.90561 15.1898 7.575C16.1185 8.24438 16.8131 9.18901 17.1751 10.2751C17.5371 11.3611 17.5482 12.5336 17.2069 13.6263C16.8655 14.7191 16.189 15.6767 15.2732 16.3636C14.8472 16.7852 14.5266 17.3012 14.337 17.8697C14.1475 18.4383 14.0945 19.0435 14.1823 19.6363C14.1823 20.215 13.9524 20.7699 13.5432 21.1791C13.1341 21.5883 12.5791 21.8181 12.0004 21.8181C11.4218 21.8181 10.8668 21.5883 10.4577 21.1791C10.0485 20.7699 9.81863 20.215 9.81863 19.6363C9.9064 19.0435 9.85338 18.4383 9.66386 17.8697C9.47434 17.3012 9.15364 16.7852 8.72772 16.3636Z'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M9.49121 17.4546H14.5094' stroke='#737373' strokeWidth='1.5' strokeLinecap='round'
                      strokeLinejoin='round' />
              </g>
              <defs>
                <clipPath id='clip0_5098_1540'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <div className={'text-[15px] font-Inter text-[#525252] pt-[2px]'}>
              Innovative technology
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectsList = () => {
  return (
    <div className=' flex items-center bg-white justify-center gap-6  py-[100px] w-full max-w-[100vw]'>
      <div className={'flex-col justify-start items-start px-4  flex max-w-[1200px]'}>
      <div className='flex-col justify-start items-start gap-2 pb-2 flex w-full max-w-[100%]'>
        <div className={'w-full flex flex-row justify-between items-center'}>
          <div
            className=' text-green-600 text-[12px] font-bold uppercase'
          >projects
          </div>
        </div>
        <div className={'sm:flex-row flex-col flex items-center justify-between pb-[16px] w-full'}>
          <div className='max-sm:w-full text-start text-[28px] font-semibold' style={{
            fontFamily: 'Inter',
          }}>Projects that you funded
          </div>
        </div>
      </div>
      <div className={'flex flex-col gap-4 w-full'}>
        <Project />
      </div>
      <a href={'https://projects.pyrpose.io/'}
         className={'bg-[#37992E] px-5 py-3 items-center rounded-[12px] mt-10 flex flex-row gap-2'}
         target={'_blank'} rel='noreferrer'>
        <div className={'text-white text-[17px] font-Inter'}>
          Discover all projects
        </div>
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1.16699 6.99984H12.8337M12.8337 6.99984L7.00033 1.1665M12.8337 6.99984L7.00033 12.8332'
                stroke='white' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </a>
    </div>
    </div>
  )
}

export default ProjectsList