import React, { useState } from 'react'
import Heart from '../../../shared/icons/Heart'
import { formatDate } from '../../../../../utils/helpers'
import { ContainerCard } from '../../../shared/helpers'
import QuestionIcon from '../../../shared/icons/QuestionIcon'
import CloseIcon from '../../../shared/icons/CloseIcon'

interface MainBlockProps {
  totalCo2Reduced: number,
  totalContribution: number,
  // eslint-disable-next-line
  referenceTransactions: any,
  name?: string
}

const ReferenceTransactions = ({ referenceTransaction }: {
  // eslint-disable-next-line
  referenceTransaction: any
}) => {
  return (
    <div className='w-full sm:h-[48px] h-16 py-3 justify-between items-center inline-flex'>
      <div className='flex-col sm:flex-row sm:justify-between sm:w-2/3 justify-start items-start inline-flex'>
        <div
          className="opacity-80 text-stone-950 text-[15px] sm:w-1/2 sm:text-start font-medium font-['Inter'] leading-tight">{referenceTransaction.name}</div>
        <div className='justify-start items-center gap-1 inline-flex'>
          <div
            className="text-neutral-400 text-[13px] font-normal sm:w-1/2 sm:text-start font-['Inter'] leading-tight">{formatDate(new Date(referenceTransaction?.createdAt), true)}</div>
        </div>
        <div className={'max-sm:hidden'}></div>
      </div>
      <div className='justify-end items-center gap-0.5 flex'>
        <div
          className="opacity-80 text-right text-stone-950 text-[17px] font-semibold font-['Inter'] leading-normal">{referenceTransaction?.amountSpent}
        </div>
        <div
          className="opacity-80 text-right text-stone-950 text-[17px] font-semibold font-['Inter'] leading-normal">€
        </div>
      </div>
    </div>
  )
}

const MainBlock: React.FC<MainBlockProps> = ({
  totalCo2Reduced,
  totalContribution,
  referenceTransactions,
  name,
}) => {
  const lives = (totalContribution / 10).toFixed(0)
  const heartsArray = Array.from({ length: Math.min(Number(lives), 33) })
  const MAX_HEARTS_PER_ROW: number[] = [7, 6]
  let currentRow = 0
  let currentCount = 0

  const rows: JSX.Element[] = []
  let currentRowHearts: JSX.Element[] = []

  heartsArray.forEach((_, i) => {
    currentRowHearts.push(<Heart key={i} />)
    currentCount++

    if (currentCount === MAX_HEARTS_PER_ROW[currentRow % MAX_HEARTS_PER_ROW.length]) {
      rows.push(<div className='flex flex-row flex-wrap gap-x-[6.67px] w-full justify-center'>{currentRowHearts}</div>)
      currentRowHearts = []
      currentCount = 0
      currentRow++
    }
  })

  if (currentRowHearts.length > 0) {
    rows.push(<div className='flex flex-row flex-wrap gap-2'>{currentRowHearts}</div>)
  }

  const [displayCount, setDisplayCount] = useState(3)
  const [showMore, setShowMore] = useState(true)

  const transactionsToShow = referenceTransactions.slice(0, displayCount)

  const handleToggleShowMore = () => {
    if (showMore) {
      setDisplayCount(referenceTransactions.length)
    } else {
      setDisplayCount(3)
    }
    setShowMore(!showMore)
  }

  const InnerWrapper = window.window.innerWidth > 640 ? ContainerCard : 'div'
  const OuterWrapper = window.window.innerWidth > 640 ? 'div' : ContainerCard
  const [showInfoBlock, setShowInfoBlock] = React.useState(false)
  const handleQuestionIconClick = () => {
    setShowInfoBlock(!showInfoBlock)
  }
  return (
    <OuterWrapper className={'flex flex-col sm:flex-row sm:flex-wrap sm:!gap-[2%] sm:justify-between'}>
      <InnerWrapper className={'w-full flex flex-col sm:!w-[49%]'}>
        <div className='w-full flex-col justify-start items-start gap-1 inline-flex pb-5'>
          <div className="text-stone-950 text-[20px] font-semibold font-['Inter'] leading-7">Total Commitment:</div>
          <div
            className=" opacity-80 text-start text-neutral-500 text-[15px] font-normal font-['Inter'] leading-tight">
            This is what {name} and his/her friends have contributed to Pyrpose.
          </div>
        </div>
        <div className=' justify-between items-start inline-flex'>
          <div className="text-stone-950 text-[36px] font-semibold font-['Inter'] leading-10">{totalContribution}€</div>
          <div className='flex-col justify-start items-end inline-flex'>
            <div className='justify-end items-baseline gap-1 inline-flex flex-nowrap flex-row'>
              <div
                className="opacity-80 text-right text-stone-950 text-[17px] font-semibold font-['Inter'] leading-tight">{totalCo2Reduced}</div>
              <div
                className="opacity-80 text-right text-stone-950 text-[13px] font-normal font-['Inter'] leading-tight">tons
              </div>
            </div>
            <div className='justify-end items-center gap-1 inline-flex'>
              <div
                className="opacity-80 text-right text-neutral-500 text-sm font-medium font-['Inter'] leading-tight">CO2
              </div>
              <div className='relative inline-flex flex-col items-end group'>
                <QuestionIcon
                  className={'w-4 h-4 relative opacity-80 cursor-pointer'}
                  onClick={handleQuestionIconClick}
                />
                <div
                  className={'absolute bottom-full max-sm:hidden right-0 mt-1 z-10 transform scale-0 group-hover:scale-100 transition-transform duration-150 ease-in-out w-[294px] h-20 pl-6 pr-3 py-4 bg-white rounded-lg shadow-xl'}>
                  <div className="text-neutral-600 text-[17px] text-start font-medium font-['Inter']">
                    Amount of the carbon emissions that will be offset
                  </div>
                </div>
                {showInfoBlock && (
                  <div
                    className='w-[294px] pl-6 pr-3 py-4 sm:hidden bg-white flex flex-row justify-between rounded-lg shadow-xl absolute bottom-full right-0 mt-1 z-10'>
                    <div className="text-neutral-600 text-[17px] text-start font-medium font-['Inter']">
                      Amount of the carbon emissions that will be offset
                    </div>
                    <div>
                      <CloseIcon onClick={handleQuestionIconClick} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </InnerWrapper>
      <InnerWrapper
        className='w-full sm:!w-[49%] flex-col justify-start items-start gap-2 inline-flex border-t-[1px] max-sm:pt-[16px] max-sm:mt-[16px] border-[#E6E6E6]'>
        <div className='w-full flex-col justify-start items-start inline-flex'>
          <div
            className="w-full text-start text-stone-950 text-[17px] sm:text-[20px] mb-2 font-semibold font-['Inter'] leading-[28px]">
            This snowball has touched {lives} lives with green energy
          </div>
        </div>
        <div className={'flex flex-row flex-wrap w-full gap-[7px] sm:justify-between'}>
          {rows}
        </div>
      </InnerWrapper>
      {referenceTransactions?.length > 0 &&
        <InnerWrapper
          className='w-full flex-col justify-start items-start inline-flex border-t-[1px] sm:pt-[16px] pt-[24px] mt-[16px] border-[#E6E6E6]'>
          <div className='w-full flex-col justify-start items-start inline-flex pb-[8px]'>
            <div
              className="w-full text-start text-stone-950 text-[17px] sm:text-[20px] font-semibold font-['Inter'] leading-[28px]">People
              joined recently
            </div>
          </div>
          {
            // eslint-disable-next-line
            transactionsToShow.map((referenceTransaction: any) => (
            <ReferenceTransactions key={referenceTransaction?.id} referenceTransaction={referenceTransaction} />
        ))
      }
      {referenceTransactions.length > 3 && (
        <button onClick={handleToggleShowMore} className='w-full sm:pt-3 pt-5  justify-center items-center inline-flex'>
          <div
            className='pl-6 pr-4 py-1.5 bg-black bg-opacity-10 rounded-[40px] justify-start items-center gap-2 flex'>
            <div className="text-center text-neutral-500 text-[15px] font-normal font-['Inter'] leading-tight">{
              showMore ? 'Show more' : 'Show less'
            }
            </div>
            <div className={`w-4 h-4 ${showMore ? '' : 'rotate-180'} `}>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M12.5 6.5L8 11L3.5 6.5' stroke='#737373' strokeWidth='1.5' strokeLinecap='round'
                      strokeLinejoin='round' />
              </svg>
            </div>
          </div>
        </button>
      )}
    </InnerWrapper>
}
</OuterWrapper>
)
}

export default MainBlock