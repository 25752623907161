import React from 'react'
import { CircularProgress } from '@mui/material'
import PrimaryButton from '../../components/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Header from '../LandingV4/components/Header'
import { useAuth } from '../../utils/AuthContext/AuthProvider'
import { Bounce, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const Login: React.FC = () => {
  const { signIn } = useAuth()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().required('Required'),
      },
    ),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        await signIn(values).then(() => {
            navigate('/profile')
          },
        )
      } catch (error: any) {
        let errorMessage = 'An error occurred during Log In. Please try again.'
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message
        }
        toast.error(errorMessage, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
      }
      setSubmitting(false)
    },
  })
  return (
    <div className={'w-full h-full flex min-h-[100vh] flex-col'}
         style={{
           background: 'radial-gradient(139.41% 126.64% at 28.52% -10.08%, #16BACF 3.72%, #189B0C 100%)',
         }}
    >
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div className={'h-full min-h-[90vh] w-full flex items-center justify-center px-4'}>
        <div
          className={'gap-4 flex sm:max-w-[420px] p-10 bg-white rounded-[16px] w-full flex-col'}>
          <div
            className={'font-semibold font-Inter sm:text-[28px] text-[20px]  pb-1 sm:leading-[32px] leading-[24px] max-sm:pt-4 max-sm:pb-5 text-[#0F0F0F] text-start'}>
            Log In
          </div>
          <div className={'flex flex-col gap-4 w-full'}>
            <div>
              <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
                Email
              </div>
              <input
                id='email'
                name='email'
                placeholder='Enter your email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.email}</div>
              ) : null}
            </div>
            <div className={'pb-4'}>
              <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
                Password
              </div>
              <input
                id='password'
                name='password'
                type={'password'}
                placeholder='Enter your password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.password}</div>
              ) : null}
            </div>
            <PrimaryButton
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting}
              className=' bg-black !w-full rounded-xl justify-center items-center gap-2 inline-flex'
            >
              {formik.isSubmitting ? (<CircularProgress size={24} />) :
                <div className='text-center text-white text-[17px] font-semibold font-Inter normal-case '>Log In</div>
              }
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login