import React from 'react'
import Header from './components/Header'
import SignUpForm from './components/SignUpForm'
import SignInForm from './components/SignInForm'
import { CheckoutDetails } from './components/CheckoutDetails'
import LoaderPulse from '../../components/LoaderPulse'
import PaymentForm from './components/PaymentForm'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { useAuth } from '../../utils/AuthContext/AuthProvider'
import './styles/index.css'
import { useLocation } from 'react-router-dom'
import { updateLastTransaction } from '../../utils/api'
import { useQuery } from 'react-query'

export const CheckOutFlow: React.FC = () => {
  const { user, isLoading } = useAuth()
  const [isLogin, setIsLogin] = React.useState(false)
  const location = useLocation()
  const { amount, emission: emissionParam } = location.state || { amount: 10, emission: null }
  const emission = emissionParam ?? parseFloat(user?.co2 || '0') ?? 0

  useQuery('lastTransaction', () => updateLastTransaction({
        email: user?.email || '', lastTransaction:
          `${amount}`,
      },
    ),
    {
      enabled: !!user,
    },
  )

  const stripeOptions: StripeElementsOptions = {
    amount: amount * 100,
    currency: 'eur',
    mode: 'payment',
  }

  const activeFlowStep = () => {
    if (!!user && !isLoading) {
      return <PaymentForm
        stripeOptions={stripeOptions}
        amount={amount}
        currency={'eur'}
        co2={emission}
        offset={true}
        email={user.email}
        name={user.name}
      />
    } else {
      return isLogin ? (
        <SignInForm
          handleLogin={() => {
            setIsLogin(false)
          }}
        />
      ) : (
        <SignUpForm
          handleLogin={() => {
            setIsLogin(true)
          }}
        />
      )
    }
  }
  if (isLoading) return <LoaderPulse />
  return (
    <div className={'min-w-full w-full flex flex-col bg-white'}>
      <Header name={user?.name} email={user?.email} />
      <div className={'w-full sm:h-[76px] custom-gradient-bg h-[62px] max-sm:!bg-white'}
      />
      <div
        className={' flex h-full sm:flex-row custom-gradient-bg flex-col-reverse  flex-nowrap max-sm:!bg-white sm:gap-32 sm:items-center flex-grow justify-end sm:justify-center w-full'}
      >
        <div className={'sm:flex-1 items-center max-sm:!bg-white max-sm:p-4 max-sm:w-full  justify-end flex'}>
          {activeFlowStep()}
        </div>
        <div className={'sm:flex-1 items-center max-sm:!bg-[#F3F3F3] max-sm:p-4 justify-start flex'}>
          <CheckoutDetails amount={amount} emission={emission} />
        </div>
      </div>
      <div />
    </div>
  )
}

export default CheckOutFlow