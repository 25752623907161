import React from 'react';
import './TextAnimation.css';
interface TextAnimationProps {
  text: string;
  colorIndex: number;
}

const TextAnimation: React.FC<TextAnimationProps> = ({ text, colorIndex }) => {
  const letters = text.split('').map((letter, index) => (
    <span key={index} className={index < colorIndex ? 'text-[#0F0F0F]' : 'text-[#0F0F0F]'}>
      {letter}
    </span>
  ));

  return <div className="animate__animated animate__fadeInRight text-start font-Inter text-[40px] leading-[44px] font-[600]">{letters}</div>;
};

export default TextAnimation;

