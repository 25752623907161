import React, { FormEvent, useEffect, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { createPaymentCustomer, createPaymentIntent } from '../../utils/api'
import PrimaryButton from '../PrimaryButton'
import { CircularProgress, Typography } from '@mui/material'
import ReactGA from 'react-ga4'

interface StripeCheckoutFormProps {
  amount: number
  currency: string
  isSubmitting: boolean
  setIsSubmitting: (value: boolean) => void
  isOpened?: boolean
}
const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = ({
  amount,
  currency,
  isSubmitting,
  setIsSubmitting,
  isOpened,
}) => {
  const stripe: Stripe | null = useStripe()
  const elements: StripeElements | null = useElements()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isFocused, setIsFocused] = useState<string | null>(null)
  const referenceId = localStorage.getItem('referenceId')

  const deviceId = localStorage.getItem('machineId') || ''
  useEffect(() => {
    if (isOpened) {
      setErrorMessage(null)
      setName('')
      setEmail('')
    }
  }, [isOpened])

  // eslint-disable-next-line
  const handleNameChange = (e: any) => {
    setName(e.target.value)
  }

  const focusedStyle = {
    boxShadow: '0 0 0 3px hsla(210,96%,45%,0.25), 0 1px 1px 0 rgba(0,0,0,0.08)',
  }

  // eslint-disable-next-line
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!elements) {
      return
    }

    const getSelectedCountry = () => {
      const selectElement = document.getElementById('Field-countryInput');
      return selectElement ? selectElement : null;
    };

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setErrorMessage(submitError?.message || 'Error submitting payment')
      return
    }

    try {
      setIsSubmitting(true)
      const customer = await createPaymentCustomer({
        name: name,
        email: email,
        referenceId: referenceId || '',
      }).then()

      ReactGA.event({
        category: 'Payment',
        action: 'Payment Customer Created',
        label: 'Payment Customer Created',
      })

      const res = await createPaymentIntent({
        amount: amount,
        currency: currency,
        customerId: customer.id,
        deviceId: deviceId,
      })

      ReactGA.event({
        category: 'Payment',
        action: 'Payment Intent Created',
        label: 'Payment Intent Created',
      })

      const data: {clientSecret?: string, client_secret?:string} = await res
      const clientSecret: string = data?.client_secret || data?.clientSecret || ''

      if (!stripe) {
        console.log('Stripe hasn\'t been initialized yet.')
        setIsSubmitting(false)
        throw new Error('Stripe hasn\'t been initialized yet.')
      }

      const baseUrl = window.location.origin
      const { error } = await stripe?.confirmPayment({
        elements,
        clientSecret,
        redirect: 'always',
        confirmParams: {
          // eslint-disable-next-line camelcase
          return_url: baseUrl + '/payment-check',
          // eslint-disable-next-line camelcase
          payment_method_data: {
            // eslint-disable-next-line camelcase
            billing_details: {
              name: name,
              email: email,
            },
          },
        },
      })


      ReactGA.event({
        category: 'Payment',
        action: 'Payment Confirmed',
        label: 'Payment Confirmed',
      })

      if (error) {
        setIsSubmitting(false)
        ReactGA.event({
          category: 'Payment',
          action: 'Payment Error',
          label: 'Payment Error',
        })
        console.log('error', error)
        setErrorMessage(error.message || 'Error confirming payment')
      } else {
        setIsSubmitting(false)
        ReactGA.event({
          category: 'Payment',
          action: 'Payment Success',
          label: 'Payment Success',
        })
        console.log('success')
      }
    } catch (err) {
      setIsSubmitting(false)
      console.log('err', err)
      setErrorMessage('Error confirming payment')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className={'w-full py-2'}>
        <div className={'gap-4 flex-col '}>
          <div className='w-full text-start mb-2 '>
            <label className='block text-[0.93rem] mb-[0.25rem]' htmlFor='Field-expiryInput'>
              Email
            </label>
            <div className='relative'>
              <input
                type='email'
                inputMode='text'
                name='email'
                id='email'
                required={true}
                placeholder='Email'
                autoComplete='billing email'
                aria-invalid='false'
                onFocus={() => setIsFocused('email')}
                onBlur={() => setIsFocused(null)}
                style={isFocused ? focusedStyle : {}}
                aria-required='true'
                className='w-full p-[0.75rem] bg-white border border-[#e6e6e6] rounded-[5px] shadow-sm focus:outline-none focus:border-[hsla(210,96%,45%,0.5)] focus:shadow-[0 1px 1px rgba(0,0,0,0.03), 0 3px 6px rgba(0,0,0,0.02), 0 0 0 3px hsla(210,96%,45%,0.25), 0 1px 1px 0 rgba(0,0,0,0.08)]'
                value={email}
                onChange={handleEmailChange}
              />
            </div>
          </div>
          <div className='w-full text-start'>
            <label className='block text-[0.93rem] mb-[0.25rem]' htmlFor='Field-nameInput'>
              Name
            </label>
            <div className='relative'>
              <input
                type='text'
                inputMode='text'
                name='name'
                required={true}
                id='Field-nameInput'
                placeholder='Name'
                autoComplete='name'
                aria-invalid='false'
                onFocus={() => setIsFocused('name')}
                onBlur={() => setIsFocused(null)}
                style={isFocused === 'name' ? focusedStyle : {}}
                aria-required='true'
                className='w-full p-[0.75rem] bg-white border border-[#e6e6e6] rounded-[5px] shadow-sm focus:outline-none focus:border-[hsla(210,96%,45%,0.5)] focus:shadow-[0 1px 1px rgba(0,0,0,0.03), 0 3px 6px rgba(0,0,0,0.02), 0 0 0 3px hsla(210,96%,45%,0.25), 0 1px 1px 0 rgba(0,0,0,0.08)]'
                value={name}
                onChange={handleNameChange}
              />
            </div>
          </div>
          {errorMessage &&
            <div className={'w-full flex flex-row justify-start align-center py-4'}>
              <div className={'text-red-500 text-[14px] font-[500] '}>Error:</div>
              <div className={'text-red-500 text-[14px] font-[500] '}>{errorMessage}</div>
            </div>
          }
          <div className={'w-full flex flex-row justify-between align-center py-4 '}>
            <div className={'text-[#000000] text-[18px] font-[600] '}>Total:</div>
            <div className={'text-[#000000] text-[18px] font-bold '}>€{amount}</div>
          </div>
        </div>
        <div className={'text-[#000000] text-start text-[14px] pb-4'}>
          By making a purchase, you agree to the Pyrpose <br/> <a href={'terms-of-use'} className={'underline'}> Terms of
          Use </a> and <a href={'privacy-policy'} className={'underline'}>Privacy Policy</a>.
        </div>
        <PrimaryButton type='submit' disabled={!stripe || !elements}>
          {isSubmitting ? <CircularProgress size={24} /> :
            <Typography variant={'body2'} className={'text-[#FFFFFF] capitalize !text-[20px]'}>
              Pay
            </Typography>}

        </PrimaryButton>
      </div>
    </form>
  )
}

export default StripeCheckoutForm
