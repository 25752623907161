import React, { useEffect, useRef, useState } from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import HowItWorksImage from '../../assets/img/how-it-works.webp'
import { Markup } from 'interweave'
import ReactGA from 'react-ga4'

interface HowItWorksProps {
  index: string;
  name: string;
  testimonial: string;
}

const HowItWorksData = [
  {
    index: '1',
    name: 'Exclusive access to investment opportunities',
    testimonial: 'Gain exclusive entry into a selected set of projects that are making a difference to our planet. Our platform offers you a unique lens into the world of investment opportunities that are not just profitable but reduce carbon emissions.',
  },
  {
    index: '2',
    name: 'Select projects that reflect your vision',
    testimonial: 'If you are like us, you want your investments to reflect your values. Climate impacts all of us and our projects clearly show you how you are funding solutions that reduce carbon emissions. Projects with a proven track record and a clear path to scale.',
  },
  {
    index: '3',
    name: 'Join the community',
    testimonial: 'Become part of a community of like minded investors. We are all change makers and know the power of a collective movement of capital. Together we can reshape the planet and make for a more vibrant world for generations to follow.',
  },
]

const HowItWorksCard: React.FC<HowItWorksProps> = ({ index, name, testimonial }) => {
  const cardRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          }
        })
      },
      {
        threshold: 0.01,
      },
    )

    if (cardRef.current) {
      observer.observe(cardRef.current)
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current)
      }
    }
  }, [])

  const animationClass = isVisible ? 'animate__fadeInUp animate__animated animate__fadeIn' : ''

  return (
    <div
      ref={cardRef}
      style={{
        border: '1px solid rgba(255, 255, 255, 0.24)',
        backdropFilter: 'blur(80px)',
        borderRadius: '12px',
      }}
      className={`w-full lg:w-[32%] max-lg:max-w-[520px] max-lg:self-center  px-6 py-6 bg-[#16242226] border-opacity-80 flex-col justify-between items-start inline-flex ${animationClass}`}
    >
      <div>
        <div className='flex-row justify-start items-center gap-3 flex'>
          <div className=' text-[72px] text-[#CEF7C6] leading-[72px] font-semibold font-Inter'>{index}</div>
          <Markup className='text-[#CEF7C6] text-start max-w-1/2 leading-[24px] font-semibold text-[20px] font-Inter '
                  content={name} />
        </div>
        <div
          className='text-[#FFFFFFCC] max-sm:w-[300px] mt-3 text-[17px] font-normal font-Inter leading-[24px] text-start'>{testimonial}</div>
      </div>
      {/*
        index === '1' &&
        <a href={'/survey'} onClick={() => {
          ReactGA.event({
            category: 'HP_BTN_HIW_SURVEY',
            action: 'HP_BTN_HIW_SURVEY',
            label: 'HP_BTN_HIW_SURVEY',
          })
        }}
           className='cursor-pointer w-1/2 h-[48px] bg-[#CEF7C6] mt-3 rounded-xl justify-center items-center gap-2 inline-flex'>
          <div className='text-center text-green-800 text-[17px] font-semibold font-Inter'>Get started</div>
        </a>
      */}
    </div>
  )
}

const HowItWorks: React.FC = () => {

  return (
    <ParallaxBanner
      layers={[
        { image: HowItWorksImage, expanded: false, speed: -40 },
      ]}
      // className="aspect-[2/1]"
    >
      <div className={'flex justify-center items-center w-full sm:px-12'}>
        <div
          className={'container w-full flex flex-row gap-4 max-w-[1400px] justify-between items-center rounded-md py-[120px] max-sm:py-[80px] lg:py-[160px] max-md:px-4'}>
          <div className='flex-col justify-start items-start gap-12 flex sm:mx-auto'>
            <div className='flex-col justify-start items-start gap-2 flex w-full z-10'>
              <div className={'w-full flex flex-row justify-between items-center pr-2'}>
                <div
                  className=' text-green-600 text-xs font-bold uppercase '
                >How it works
                </div>
              </div>
              <div
                className='text-start text-white xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px]   font-semibold  font-Inter'
              >{/* A simple and efficient way to tackle climate change */}Your Gateway to climate investments
              </div>
              <div className='text-white text-[17px] leading-[23px] mt-4 text-start font-Inter sm:w-1/2  w-full'>Embrace the confluence of finance and technology with us. Begin your impact investing journey today and join a community where capital meets purpose, and where your investments pave the way for a sustainable legacy.
              </div>
              <a
                  // href={'/how-it-works'}
                  href={'#signup'}
                  onClick={
                    () => {
                      ReactGA.event({
                        category: 'HP_BTN_HIW_LEARN',
                        action: 'HP_BTN_HIW_LEARN',
                        label: 'HP_BTN_HIW_LEARN',
                      })
                    }
                  }
                 className=' cursor-pointer rounded-sm justify-start mt-3 flex-row items-center gap-1.5 inline-flex'>
                <div className='text-white text-[17px] font-medium font-Inter underline'>Learn more</div>
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.16675 10H15.8334M15.8334 10L10.0001 4.16669M15.8334 10L10.0001 15.8334' stroke='white'
                        strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </a>
            </div>
            <div
              className='w-full flex flex-col max-lg:gap-4 lg:flex-row gap-[1%] flex-wrap lg:flex-nowrap  lg:justify-between '>
              {HowItWorksData.map((item) => (
                <HowItWorksCard key={item.index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  )
}

export default HowItWorks