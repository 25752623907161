import React from 'react';

interface YouTubeBlockProps {
  url: string;
}

const YouTubeBlock:React.FC<YouTubeBlockProps> = ({
  url,
}) => {
  return (
    <div className='w-full px-4 py-16 flex-col justify-start items-start gap-8 inline-flex md:max-w-[640px] md:self-center'>
      <div className='flex-col justify-start items-start gap-6 flex'>
        <div className='flex-col justify-start items-start gap-2 flex'>
          <div
            className='text-[#37992E] text-xs font-bold uppercase tracking-wide'>Shape your tomorrow
          </div>
          <div className='text-[28px] text-start font-semibold leading-9'
               style={{
                 fontFamily: 'IBM Plex Serif',
               }}
          >{/* Everyone should have the opportunity to сommit to a sustainable future */}
              Everyone should have access to investments that matter for our planet
          </div>
        </div>
        <div className="text-start  text-[#525252] text-[17px] font-normal font-['Inter'] leading-normal">Gain access to exclusive, vetted opportunities in the climate sector, poised for climate impact and returns of 6 to 12%. By investing with Pyrpose, you&apos;re not just diversifying your portfolio; you&apos;re actively participating in the global shift towards sustainable energy.
        </div>
      </div>
      <div className='relative w-full h-0 rounded-[16px] overflow-hidden' style={{ paddingTop: '56.25%' }}>
        <iframe className='absolute top-0 left-0 w-full h-full' src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default YouTubeBlock;