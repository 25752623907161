import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { Box, Typography } from '@mui/material'
// import { ReactComponent as Next } from '../assets/svg/next.svg'

interface ISecondaryButton extends ButtonProps {
  children?: React.ReactNode
  next?: boolean
  nextLabel?: string
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
}

const SecondaryButton: React.FC<ISecondaryButton> = ({
  children,
  next,
  nextLabel,
  type,
  disabled,
  ...props
}) => {
  return (
    <Button
      variant='contained'
      className='rounded-full'
      type={type}
      size='large'
      disabled={disabled}
      style={{
        width: '100%',
        height: 64,
        backgroundColor: '#FFFF',
        opacity: disabled ? 0.5 : 1,
        borderRadius: '16px',
        border: '1px solid #444444',
      }}
      sx={{
        ':hover': {
          boxShadow: disabled
            ? ''
            : '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 7px 8px rgba(0, 0, 0, 0.07), 0px 12px 20px rgba(0, 0, 0, 0.05) !important',
        },
      }}
      {...props}
    >
      {next ? (
        <Box
          className={'w-full items-center justify-center flex'}
          sx={{
            path: {
              stroke: '#1B1C1D !important',
            },
            circle: {
              stroke: '#1B1C1D !important',
            },
          }}
        >
          <Typography
            variant={'h4'}
            style={{
              fontSize: 24,
              marginRight: 12,
              fontWeight: 'bold',
              textTransform: 'none',
              color: '#1B1C1D',
            }}
          >
            {nextLabel ? nextLabel : 'Next'}
          </Typography>
          {/* <Next style={{ width: '20px', height: 'auto', stroke: '#1B1C1D' }} /> */}
        </Box>
      ) : (
        children || ''
      )}
    </Button>
  )
}

export default SecondaryButton
