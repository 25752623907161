import { observable, action } from 'mobx'
import { ISurveyResponse } from '../screen.interfaces'

interface IAnswer {
  questionId: string | number
  // eslint-disable-next-line
  answer: any
  description: string
  type?: string | null
}

class QuizStore {
  @observable questions: ISurveyResponse = { screens: [] }
  @observable userAnswers: Record<string, IAnswer> = {}

  @action setQuestions(questions: ISurveyResponse) {
    this.questions = questions
  }

  @action setUserAnswer(questionId: string, answer: IAnswer) {
    this.userAnswers[questionId] = answer
  }

  @action getQuestions() {
    return this.questions as ISurveyResponse
  }

  @action getUserAnswers() {
    return this.userAnswers
  }

  @action getUserAnswerById(id: string) {
    return this.userAnswers[id]
  }

  @action getUserAnswerByIndex(index: number) {
    const keys = Object.keys(this.userAnswers)
    return this.userAnswers[keys[index]]
  }

  @action clearUserAnswers() {
    this.userAnswers = {}
  }

  @action setUserAnswers(answers: Record<string, IAnswer>) {
    this.userAnswers = answers
  }
}

const quizStore = new QuizStore()
export default quizStore
