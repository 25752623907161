import React from 'react'
import Person1 from '../../../../assets/img/person-8.webp'
import Person2 from '../../../../assets/img/person-7.webp'
import Person3 from '../../../../assets/img/person-6.webp'
import Person4 from '../../../../assets/img/person-4.webp'
import Person5 from '../../../../assets/img/person-5.webp'

interface TestimonialProps {
  imgSrc: string;
  name: string;
  testimonial: string;
}

const TestimonialCard: React.FC<TestimonialProps> = ({ imgSrc, name, testimonial }) => (
  <div
    className='max-sm:h-[380px] max-sm:w-[440px] sm:w-[48%] px-4 py-6 bg-[#E3E3DD] rounded-xl flex-col justify-start items-start gap-4 inline-flex'>
    <img className='w-[72px] h-[72px] rounded-full object-cover' src={imgSrc} alt={name} />
    <div
      className="text-neutral-600 max-sm:w-[300px] text-[17px] font-normal font-['Inter'] leading-normal text-start">{testimonial}</div>
    <div className='flex-col justify-start items-start flex'>
      <div className="text-stone-950 text-[17px] font-semibold font-['Inter'] leading-normal">{name}</div>
    </div>
  </div>
)

const Testimonials = () => {
  const testimonialsData = [
    {
      imgSrc: Person1,
      name: 'Clara B.',
      testimonial: '«I want to do good for the planet, but I struggle to find ways to change what I do.  Pyrpose gave me an outlet to help the planet.  Kudos for making it so easy»',
    },
    {
      imgSrc: Person2,
      name: 'Isabelle M.',
      testimonial: '«Pyrpose helps not only offset my carbon footprint, but also change someone’s life. And for me, this is the most important»',
    },
    {
      imgSrc: Person3,
      name: 'Lukas N.',
      testimonial: '«Thankfully to Pyrpose, I know that my money not only is in a safe place, but also making a significant impact on those in need»',
    },
    {
      imgSrc: Person4,
      name: 'Benjamin M.',
      testimonial: '«As a sustainability enthusiast, I\'ve always wanted to support companies making a positive impact on our planet. With Pyrpose, I can. It\'s easy to use, and most importantly, it\'s accessible to everyone. I\'m thrilled to be part of this movement for change!»',
    },
    {
      imgSrc: Person5,
      name: 'William S.',
      testimonial: '«The process of changing the world without going outside of my home is truly inspiring. I love that Pyrpose constantly shares the progress of the projects.»',
    },
  ]


  return (
    <div className='w-full px-4 py-16 max-w-full bg-white flex-col justify-start items-start gap-8 inline-flex'>
      <div className='flex-col justify-start items-start gap-6 flex sm:max-w-[640px] sm:mx-auto'>
        <div className='flex-col justify-start items-start gap-2 flex w-full'>
          <div className={'w-full flex flex-row justify-between items-center pr-2'}>
            <div
              className=' text-green-600 text-xs font-bold uppercase '

            >Testimonials
            </div>
            <svg width='36' height='20' viewBox='0 0 36 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M3 9.99996H32.5M32.5 9.99996L26.6667 4.16663M32.5 9.99996L26.6667 15.8333' stroke='#37992E'
                    strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </div>
          <div
            className='text-start text-stone-950 text-[28px] font-semibold'
            style={{
              fontFamily: 'IBM Plex Serif',
            }}
          >Testimonials
          </div>
        </div>
        <div className='w-full flex flex-row gap-4 pr-4 sm:flex-wrap max-sm:overflow-x-auto max-w-[95vw]'>
          {testimonialsData.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Testimonials
