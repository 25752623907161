import React, { useState, memo } from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Box from '@mui/material/Box'
import Arrow from '../../../assets/svg/arrow.svg'
import 'animate.css'
import { useFormik } from 'formik'
import { IQuestion, ISurvey } from '../../../screen.interfaces'
import Colors from '../../../constants/colors'
import PrimaryButton from '../../../components/PrimaryButton'
import QuizSlider from './QuizSlider'
import InputModal from './InputModal'
import { postLog } from '../../../utils/api'
import { Header } from '../../../components/Header'
import FlyModal from './FlyModal'

interface IScreenProps {
  step: ISurvey
  // eslint-disable-next-line
  handleNext: (values: any) => void
  // eslint-disable-next-line
  answers: any
  handleBack: () => void
  // eslint-disable-next-line
  steps: any
  activeStep: number
}

const Screen: React.FC<IScreenProps> = ({
  step,
  handleNext,
  answers,
  steps,
  activeStep,
  handleBack,
}) => {
  const [timer, setTimer] = useState(new Date().getTime())
  const [inputModalShow, setInputModalShow] = useState<boolean>(false)

  const sxStyle = (wrap?: boolean) => {
    return {
      width: wrap ? 'auto !important' : '100% !important',
      marginTop: '24px !important',
      '& .MuiToggleButton-root': {
        borderRadius: 0,
        border: 'none',
        color: '#000',
        padding: wrap ? '8px 16px' : '12px 8px',
        margin: wrap ? '4px 8px' : '4px 0',
        fontSize: 16,
        backgroundColor: '#ffffff70',
        '&.Mui-selected': {
          backgroundColor: '#000 !important',
          color: '#78FD85',
        },
      },
      '& .MuiToggleButtonGroup-grouped': {
        width: wrap ? 'auto !important' : '100% !important',
        borderRadius: '25px !important',
        textTransform: 'inherit !important',
      },
    }
  }

  const initVal = _.reduce(
    step?.questions,
    (acc, question) => {
      return {
        ...acc,
        [question.id]: {
          value:
            answers && answers[question?.id]
              ? answers[question?.id].value
              : question.type === 'slider'
                ? question.min || 0
                : null,
          details: null,
          type: answers[question?.id]?.type || null,
          id: question.id,
        },
      }
    },
    {},
  )

  const formik = useFormik({
    initialValues: answers ? { ...answers, ...initVal } : initVal,
    onSubmit: (values) => {
      const finishTime = new Date().getTime()
      postLog({
        screenId: step.id,
        time: Math.round((finishTime - timer) / 1000),
      }).then()
      setTimer(0)
      handleNext(values)
    },
  })

  // eslint-disable-next-line
  const renderQuestion = (question: IQuestion, formik: any) => {
    // eslint-disable-next-line
    const value = _.filter(question?.values, (value: any) => {
      return value?.value === (formik.values[question.id]?.value || question?.min)
    })[0]

    const showTitle = () => {
      return (
        <>
          {question.title && (
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                lineHeight: '32px',
                '@media (max-width: 768px)': {
                  fontSize: '24px',
                  lineHeight: '29px',
                },
              }}
            >
              {question.title}
            </Typography>
          )}
          {question?.subtitle && (
            <Typography style={styles.description}>{question?.subtitle}</Typography>
          )}
          {question?.labelMin && question?.labelMax && (
            <Box className='w-full flex flex-row justify-between items-center pt-6'>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '19px',
                  },
                }}
              >
                {question.labelMin}
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '19px',
                  },
                }}
              >
                {question.labelMax}
              </Typography>
            </Box>
          )}
        </>
      )
    }
    switch (question.type) {
    case 'slider':
      return (
        <div className={'w-full flex flex-col items-center'}>
          {showTitle()}
          <div className={'w-[90%]'}>
            <QuizSlider formik={formik} question={question} />
          </div>
          <div className={'w-full flex items-center justify-center '}>
            <div className={'animate__shakeX'}>
              <img src={Arrow} alt={'arrow'} className={'animate__shakeX animate__animated'} />
            </div>
          </div>
          {value ? (
            <Box style={styles.rootValue}>
              <Typography
                sx={{
                  color: Colors.basic.black,
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '24px',
                  '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '19px',
                  },
                }}
              >
                {value.label}
              </Typography>
              <Typography style={styles.descriptionValue}>{value?.description}</Typography>
            </Box>
          ) : (
            <Box style={styles.rootValue}>
              <Typography
                sx={{
                  color: Colors.basic.black,
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '24px',
                  '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '19px',
                  },
                }}
              >
                {!_.isEmpty(question?.labelPattern) &&
                  question?.labelPattern.replace('%s', formik?.values[question.id]?.value)}
                {!_.isEmpty(question?.labelPattern) &&
                  question?.labelPattern.search('km') !== -1 &&
                  ' / ' + Math.round(formik?.values[question.id]?.value * 0.621371) + ' mi'}
              </Typography>
              <Typography style={styles.descriptionValue}></Typography>
            </Box>
          )}
        </div>
      )
    case 'select':
      return (
        <div
          className={
            'w-full flex items-center ' + question.wrap ? 'flex-row flex-wrap' : 'flex-col'
          }
        >
          {showTitle()}
          <ToggleButtonGroup
            value={formik.values[question.id].value}
            exclusive
            onChange={(e, val) => {
              formik.setFieldValue(question.id, { ...formik.values[question.id], value: val })
            }}
            className={
              'flex ' + question.wrap
                ? 'flex-row flex-wrap items-center gap-1 justify-center'
                : 'flex-col w-full'
            }
            sx={sxStyle(question.wrap)}
            aria-label='text alignment'
          >
            {question.values.map((value, index) => (
              <ToggleButton
                key={index}
                value={value.value}
                disableFocusRipple={true}
                sx={{
                  '.MuiToggleButton-root': {
                    width: 'auto !important',
                  },
                  ' &:hover': {
                    '@media (max-width: 620px)': {
                      backgroundColor: '#ffffff70',
                    },
                  },
                }}
                aria-label='left aligned'
              >
                {value.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )
    case 'multiselect':
      return (
        <div
          className={
            'w-full flex items-center ' + question.wrap ? 'flex-row flex-wrap' : 'flex-col'
          }
        >
          {showTitle()}
          <ToggleButtonGroup
            value={formik.values[question.id].value}
            onChange={(e, val) => {
              formik.setFieldValue(question.id, { ...formik.values[question.id], value: val })
            }}
            className={
              'flex ' + question.wrap
                ? 'flex-row flex-wrap items-center gap-1 justify-center'
                : 'flex-col w-full'
            }
            sx={sxStyle(question.wrap)}
            aria-label='text alignment'
          >
            {question.values.map((value, index) => (
              <ToggleButton
                key={index}
                value={value.value}
                sx={{
                  ' &:hover': {
                    '@media (max-width: 620px)': {
                      backgroundColor: '#ffffff70',
                    },
                  },
                }}
                aria-label='left aligned'
              >
                {value.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )
    case 'checkbox':
      return (
        <div className={'w-full flex flex-col'}>
          {showTitle()}
          <ToggleButtonGroup
            value={formik.values[question.id].value}
            onChange={(e, val) => {
              formik.setFieldValue(question.id, { ...formik.values[question.id], value: val })
            }}
            aria-label='text alignment'
          >
            {question?.values.map((value, index) => (
              <ToggleButton key={index} value={value.value} aria-label='left aligned'>
                {value.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )
    case 'select_with_input':
      return (
        <div className={'w-full flex flex-col items-center'}>
          {showTitle()}
          <ToggleButtonGroup
            exclusive={true}
            value={formik.values[question.id].value}
            onChange={(e, val) => {
              if (val.type === 'input') setInputModalShow(true)
              else
                formik.setFieldValue(question.id, {
                  ...formik.values[question.id],
                  value: val,
                  type: null,
                })
            }}
            aria-label='text alignment'
            sx={sxStyle(question.wrap)}
            className={
              'flex ' + question.wrap
                ? 'flex-row flex-wrap items-center gap-1 justify-center'
                : 'flex-col w-full'
            }
          >
            {question?.values.map((value, index) => (
              <ToggleButton
                key={index}
                value={value.type === 'input' ? value : value.value}
                aria-label='left aligned'
                selected={
                  formik.values[question.id] &&
                  value &&
                  formik.values[question.id].type === 'input' &&
                  value.type === 'input'
                    ? true
                    : formik.values[question.id] &&
                    value &&
                    formik.values[question.id].type !== 'input' &&
                    value.type !== 'input' &&
                    formik.values[question.id].value === value.value
                }
              >
                {value.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <InputModal
            show={inputModalShow}
            onCancel={() => setInputModalShow(false)}
            formik={formik}
            question={question}
          />
        </div>
      )
    case 'multi_select': {
      const foundValue = question.values.find((value) => value.type === 'input')
      const [showFlyModal, setShowFlyModal] = useState(false)
      return (
        <div className={'w-full flex flex-col items-center'}>
          {showTitle()}
          <div className={'flex flex-col gap-4 w-full py-4'}>
            <ToggleButtonGroup
              exclusive={true}
              value={formik.values[question.id].value}
              onChange={(e, val) => {
                if (val.type === 'input') setShowFlyModal(true)
                else
                  formik.setFieldValue(question.id, {
                    ...formik.values[question.id],
                    value: val,
                    type: null,
                  })
              }}
              aria-label='text alignment'
              sx={sxStyle(question.wrap)}
              className={
                'flex ' + question.wrap
                  ? 'flex-row flex-wrap items-center gap-1 justify-center'
                  : 'flex-col w-full'
              }
            >
              {question?.values.map((value, index) => (
                <ToggleButton
                  key={index}
                  value={value.type === 'input' ? value : value.value}
                  aria-label='left aligned'
                  selected={
                    formik.values[question.id] &&
                    typeof formik.values[question.id] === 'object' &&
                    formik.values[question.id].type === 'input' &&
                    value.type === 'input'
                      ? true
                      : formik.values[question.id] &&
                      value &&
                      formik.values[question.id].type !== 'input' &&
                      value.type !== 'input' &&
                      formik.values[question.id].value === value.value
                  }
                >
                  {value.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FlyModal
              show={showFlyModal}
              onCancel={() => {
                setShowFlyModal(false)
              }}
              formik={formik}
              question={question}
              foundValue={foundValue}
            />
          </div>
        </div>
      )
    }
    default:
      return null
    }
  }

  return (
    <>
      <Header
        handleSkip={() => {
          formik.handleSubmit()
        }}
        handleBack={handleBack}
        activeStep={activeStep}
        steps={steps}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className={'flex flex-col items-center justify-between '}>
          <div className={'w-[100%] flex mx-[auto] flex-col items-center gap-4 justify-center'}>
            {step?.questions.map((question) => {
              return renderQuestion(question, formik)
            })}
          </div>
        </div>
      </form>
      <Box className={'pb-10 pt-4'}>
        {step?.isLast ? (
          <div className={'flex flex-col'}>
            <div className={'mb-4 text-[14px] text-left'}>
              By continuing, you agree to our{' '}
              <a
                href='/privacy-policy'
                className={'font-bold underline text-[14px]'}
              >
                Privacy Policy
              </a>
              {' '}and{' '}
              <a
                href='/terms-of-use'
                className={'font-bold underline text-[14px]'}
              >
                Terms of Service
              </a>
            </div>
            <PrimaryButton
              next={true}
              nextLabel={'See my results'}
              onClick={() => formik.handleSubmit()}
            >
              <div />
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryButton next={true} onClick={() => formik.handleSubmit()}>
            <div />
          </PrimaryButton>
        )}
      </Box>
    </>
  )
}
const styles = {
  rootContainer: {
    paddingHorizontal: 16,
    justifyContent: 'space-around',
    flex: 1,
  },
  root: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    paddingHorizontal: 20,
    borderRadius: 4,
    height: 40,
  },
  labels: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  label: {
    fontSize: 18,
    color: Colors.basic.black,
  },
  description: {
    fontSize: 16,
    color: Colors.basic.black,
    marginTop: 8,
  },
  rootValue: {
    marginTop: 12,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mainTitle: {
    fontSize: 26,
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.basic.black,
  },
  subTitle: {
    fontSize: 18,
    textAlign: 'center',
    color: Colors.basic.black,
    marginTop: 12,
  },
  thumb: {
    width: 50,
    height: 50,
    borderRadius: 30,
    backgroundColor: '#A9A9A9',
  },
  track: {
    height: 15,
    borderRadius: 10,
  },
  titleValue: {
    color: Colors.basic.black,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },
  descriptionValue: {
    fontSize: 12,
    height: 20,
    color: Colors.basic.black + '70',
    marginTop: 8,
  },
}

export default memo(Screen)
