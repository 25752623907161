import React, { useEffect, useRef, useState } from 'react'
import Person1 from '../../../../assets/img/person-8.webp'
import Person2 from '../../../../assets/img/person-7.webp'
import Person3 from '../../../../assets/img/person-6.webp'
import Person4 from '../../../../assets/img/person-4.webp'
import { Parallax } from 'react-scroll-parallax'

interface TestimonialProps {
  imgSrc: string;
  name: string;
  testimonial: string;
}

const testimonialsData = [
  {
    imgSrc: Person1,
    name: 'Clara B.',
    testimonial: '«Pyrpose helps not only in offsetting my carbon footprint, but also in changing someone\'s life. And for me, that\'s the most important part.»',
  },
  {
    imgSrc: Person2,
    name: 'Isabelle M.',
    testimonial: '«Thanks to Pyrpose, I know that my money is not only in a safe place, but is also making a significant impact on those in need»',
  },
  {
    imgSrc: Person3,
    name: 'Lukas N.',
    testimonial: '«I want to do good for the planet, but I struggle to find ways to change my lifestyle.  Pyrpose gave me the opportunity to help the planet just the way I wanted.  Kudos for making it so easy»',
  },
  {
    imgSrc: Person4,
    name: 'Benjamin M.',
    testimonial: '«As a sustainability enthusiast, I\'ve always wanted to support companies making a positive impact on our planet. With Pyrpose, I can. It\'s easy to use, and most importantly, it\'s accessible to everyone. I\'m thrilled to be part of this movement for change!»',
  },
]

const TestimonialCard: React.FC<TestimonialProps> = ({ imgSrc, name, testimonial }) => {
  const cardRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          }
        })
      },
      {
        threshold: 0.01,
      },
    )

    if (cardRef.current) {
      observer.observe(cardRef.current)
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current)
      }
    }
  }, [])

  const animationClass = isVisible ? 'animate__fadeInUp animate__animated animate__fadeIn' : ''

  return (
    <div
      ref={cardRef}
      className={`w-full lg:w-[24%] sm:w-[49%] px-4 py-6 bg-[#E3E3DD99] mb-4 rounded-xl flex-col justify-start items-start gap-4 inline-flex ${animationClass}`}
    >
      <div className='flex-row justify-start items-center gap-4 flex'>
        <img className='w-[72px] h-[72px] rounded-full object-cover' src={imgSrc} alt={name} />
        <div className="text-stone-950 text-[17px] font-semibold font-['Inter'] leading-normal">{name}</div>
      </div>
      <div
        className='text-neutral-600 max-sm:w-[300px] text-[17px] font-normal font-Inter leading-normal text-start'>{testimonial}</div>
    </div>
  )
}

const Testimonials = () => {
  return (
    <Parallax
      className={'w-full bg-white flex items-center justify-center sm:px-12 px-4 overflow-x-hidden'}>
      <div
        className={'container w-full max-w-[1400px] flex flex-row gap-4 justify-between items-center py-[120px] max-sm:py-[80px] lg:py-[160px] border-t-[1px] border-t-[#D9D9D2] '}>
        <div className='flex-col justify-start items-start gap-6 flex sm:mx-auto'>
          <div className='flex-col justify-start items-start gap-2 flex w-full'>
            <div className={'w-full flex flex-row justify-between items-center pr-2'}>
              <div
                className=' text-green-600 text-xs font-bold uppercase '
              >Join our community
              </div>
              <svg width='36' height='20' viewBox='0 0 36 20' className={'sm:hidden'} fill='none'
                   xmlns='http://www.w3.org/2000/svg'>
                <path d='M3 9.99996H32.5M32.5 9.99996L26.6667 4.16663M32.5 9.99996L26.6667 15.8333' stroke='#37992E'
                      strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            </div>
            <div
              className='text-start text-stone-950 xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px] font-semibold font-Inter pr-4'
            >Become part of the solution
            </div>
          </div>
          <div
            className='md:w-full flex flex-row gap-[1%] z-10 max-sm:max-w-[95vw] max-sm:gap-4 flex-nowrap sm:flex-wrap lg:flex-nowrap max-md:overflow-x-scroll md:justify-between '>
            {testimonialsData.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </div>
      </div>
    </Parallax>
  )
}

export default Testimonials
