import { SVGProps, memo } from 'react'

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={33}
    fill='none'
    {...props}
  >
    <path
      stroke={props?.stroke || '#fff'}
      strokeLinecap='round'
      strokeWidth={2}
      d='M25.899 6.6 6.1 26.399M25.899 26.399 6.101 6.601'
    />
  </svg>
)
const Memo = memo(CloseIcon)
export default Memo
