import React from 'react'
import { Header } from '../LandingV3/components'
import HowItWorksBg from './assets/img/how-it-works-bg.webp'
import Rectangle from '../Projects/assets/rectangle.svg'
import HeaderList from './components/HeaderList'
import GrayList from './components/GrayList'
import MiddleBg from './assets/img/middle-bg.webp'
import MiddleBgMobile from './assets/img/middle-bg-mobile.webp'
import CircleBlock from './components/CircleBlock'
import BlueList from './components/BlueList'
import Footer from '../LandingV3/components/Footer'

const HowItWorks: React.FC = () => {
  return (<div className={'w-full flex-col items-center h-full bg-white flex-1 flex '}>
      <div data-blockname='Header' className={'w-full'}><Header /></div>
      <div className={'w-full relative overflow-hidden h-full bg-white'} style={{
        backgroundImage: `url(${HowItWorksBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div
          className={' flex-col sm:gap-[64px] gap-[40px] pt-16 pb-24 max-sm:px-4 flex items-center justify-start '}>
          <div className='max-w-[608px] flex-col justify-start items-start gap-6 inline-flex'>
            <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
              <div className='text-white text-[56px] text-start font-medium font-IBM'>How it works</div>
              <div className='self-stretch text-white text-xl font-semibold font-Inter text-start'>A simple and
                efficient way to tackle climate change
              </div>
            </div>
            <div className='self-stretch text-white text-[17px] font-normal font-Inter text-start'>You can now
              contribute to the massive energy transition from fossil fuels to clean and renewable energy by actively
              supporting climate innovators.
            </div>
          </div>
          <HeaderList />
        </div>
        <img src={Rectangle} alt='Rectangle2' className={'absolute w-full  bottom-[-5px] object-fill'} />
      </div>
      <GrayList />
      <picture className={'w-full'}>
        <source srcSet={MiddleBgMobile} media='(max-width: 640px)' />
        <source srcSet={MiddleBg} media='(min-width: 640px)' />
        <img src={MiddleBg} alt={'MiddleBg'}
             className={'w-full object-contain max-sm:h-[100vw]  bg-white object-center'} />
      </picture>
      <CircleBlock />
      <BlueList />
      <Footer />
    </div>
  )
}

export default HowItWorks