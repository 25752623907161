const Colors = {
  basic: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: {
    main: '#167849',
    secondary: '#47BC84',
    darkGray: '#838383',
    gray: '#D9D9D9',
    blacked: '#464646',
    lightGray: '#3F3F3F30',
  },
  text: {
    gray: '#969696',
  },
  background: {
    main: '#F2F9F6',
  },
  error: {
    main: '#FF0000',
  },
  slider: {
    full: '#686868',
  },
}

export default Colors
