// eslint-disable-next-line
export function toFootballFields(value: any) {
  return parseFloat((value / 2.8).toFixed(1)) || 0
}

// eslint-disable-next-line
export function getFields(initialValues: any) {
  const wholePart = Math.floor(initialValues)
  const decimalPart = parseFloat((initialValues - wholePart).toFixed(1))
  const fields = new Array(wholePart).fill(1)
  if (decimalPart > 0) {
    fields.push(decimalPart)
  }
  return fields
}

export function commaSeparator(value: string | number) {
  if (!value) return value
  return value.toString().replace('.', ',')
}
