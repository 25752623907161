import React, { type FC, memo, useEffect, useState } from 'react'
import { ErrorTooltip, Header, V2Footer, WaitlistTooltip } from './components'
import { useQuery } from 'react-query'
import { getSurveyFootprint, postFunnel, postUser } from '../../utils/api'
import { useLocation, useNavigate } from 'react-router-dom'
import quizStore from '../../stores/answers'
import ReactGA from 'react-ga4'
import { AnimatedNumber } from './components/AnimatedValue'
import 'animate.css'
import CompareResultsModal from '../CompareResultsModal'
import ShareLinkModal from '../ShareLinkModal'
import LoaderPulse from '../../components/LoaderPulse'
import { animated, useSpring } from '@react-spring/web'
import { StripeElementsOptions } from '@stripe/stripe-js'
import PaymentScreen from '../PaymentScreen'
import FootballFields from './components/FootballFields'
import TranslationText from '../../components/TranslationText'

const Results: FC = () => {
  // eslint-disable-next-line
  const [result, setResult] = useState<any>(null)
  const [amount, setAmount] = useState<number>(0)
  // eslint-disable-next-line
  const [, setRefState] = useState<any>('')
  const [step, setStep] = useState<number>(0)
  const [showCompareModal, setShowCompareModal] = useState<boolean>(false)
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false)
  const [tooltip, setTooltip] = useState<boolean>(false)
  const [errorTooltip, setErrorTooltip] = useState<string>('')
  const [isStripeOpen, setIsStripeOpen] = useState<boolean>(false)

  const email = localStorage.getItem('email')
  const { state } = useLocation()
  const navigate = useNavigate()

  const { isLoading } = useQuery(
    ['getFootprint'],
    async () => {
      getSurveyFootprint(state?.ref).then((res) => {
        if (res) {
          postFunnel({
            co2: res?.myEmission,
          })
          setResult(res)
          if (res.refs) {
            setRefState(res.refs[0])
          }
        }
      })
    },
    {
      refetchOnMount: true,
    },
  )
  const reductionOptions = result?.prices
  const [reduceValue] = React.useState<number>(reductionOptions?.min || 1)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
  }, [reduceValue])
  const handleRetake = () => {
    quizStore.clearUserAnswers()
    ReactGA.event({
      category: 'RetakeQuiz',
      action: 'RetakeQuiz',
    })
    navigate('/')
  }
  const handleShare = () => {
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Open ShareLinkModal',
    })
    if (!email) {
      setShowCompareModal(true)
    } else {
      setShowLinkModal(true)
    }

    postUser({
      sharePressed: true,
    }).then()
  }

  const handleCompareResultsModalSkip = () => {
    ReactGA.event({
      category: 'CompareResultsModal',
      action: 'Skip CompareResultsModal',
    })
    setShowCompareModal(false)
    setShowLinkModal(true)
  }
  const handleShareLinkModalSkip = () => {
    setShowLinkModal(false)
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Skip ShareLinkModal',
    })
    setShowLinkModal(false)
  }

  const [springTooltip, setSpringTooltip] = useSpring(() => ({
    opacity: '0',
    display: 'none',
  }))

  const [springErrorTooltip, setSpringErrorTooltip] = useSpring(() => ({
    opacity: '0',
    display: 'none',
  }))

  useEffect(() => {
    if (tooltip) {
      setSpringTooltip({
        opacity: '1',
        display: 'flex',
      })
      setTimeout(() => {
        setSpringTooltip({
          opacity: '0',
          display: 'none',
        })
        setTooltip(false)
      }, 3000)
    }
    if (errorTooltip) {
      setSpringErrorTooltip({
        opacity: '1',
        display: 'flex',
      })
      setTimeout(() => {
        setSpringErrorTooltip({
          opacity: '0',
          display: 'none',
        })
        setErrorTooltip('')
      }, 3000)
    }
  }, [tooltip, errorTooltip])



  const stripeOptions: StripeElementsOptions = {
    amount: (amount | 10) * 100,
    currency: 'eur',
    mode: 'payment',
  }
  const activeComponents = () => {
    return (
      <>
        <div>
          <TranslationText translationKey={'result-page.header.title'}
                           className={
                             'sm:text-[32px] text-[24px] mt-4 text-center font-bold sm:leading-[35px] leading-[24px] max-sm:mb-2 max-sm:!px-4'
                           }
          />
          <div className={'w-full sm:mb-0px mb-[12px] mt-4'}>
            <AnimatedNumber
              value={
                result?.trees || 0
              }
              duration={100}
            />
          </div>
        </div>
        <div className={'flex flex-row flex-wrap gap-2 w-full items-end justify-center'}>
          <FootballFields fields={result?.fields} />
        </div>
        <V2Footer
          setMoney={(money) => {
            setAmount(money)
            setIsStripeOpen(true)
          }}
          co2={result?.myEmission}
        />
      </>
    )
  }


  if (isLoading) {
    return <LoaderPulse />
  }

  return (
    <div
      className={
        'w-full flex-1 flex max-sm:!flex-col max-sm:justify-between justify-center relative'
      }
      style={{
        backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #FAFBFB 48.34%, #D8F2FF 100%)',
        flexDirection: 'row',
      }}
    >
      <div
        className={`flex flex-col container items-center justify-center h-full   ${
          step === 0 ? 'sm:w-full sm:max-w-[512px] ' : 'sm:w-[72%] sm:max-w-[72%] w-full '
        }`}
      >
        <Header
          setStep={setStep}
          handleRetake={handleRetake}
          handleShare={handleShare}
          step={step}
        />
        <div
          className={
            'h-full max-sm:overflow-y-scroll w-full max-sm:!w-full  flex-col items-center flex sm:justify-center sm:pt-[50px] pt-[20px]'
          }
          style={{
            width: step === 0 ? '100%' : '72%',
            maxWidth: step === 0 ? '512px' : '72%',
            backgroundImage:
              step === 0
                ? 'linear-gradient(180deg, #FFFFFF10 0%, #FFFFFF50 48.34%, #FFFFFF80 100%)'
                : 'none',
          }}
        >
          {activeComponents()}
        </div>
      </div>
      <CompareResultsModal show={showCompareModal} onSkip={handleCompareResultsModalSkip} />
      <ShareLinkModal show={showLinkModal} onSkip={handleShareLinkModalSkip} />
      <animated.div
        className={
          'absolute right-4 top-4 z-[10000] max-sm:left-4  flex items-center justify-center'
        }
        style={{
          ...springTooltip,
        }}
      >
        <WaitlistTooltip />
      </animated.div>
      <animated.div
        className={
          'absolute right-4 top-4 z-[10000] max-sm:left-4  flex items-center justify-center'
        }
        style={{
          ...springErrorTooltip,
        }}
      >
        <ErrorTooltip errorTooltip={errorTooltip} />
      </animated.div>
      <PaymentScreen
        stripeOptions={stripeOptions}
        isOpened={isStripeOpen}
        setIsOpened={setIsStripeOpen}
        amount={amount || 10}
        currency={'eur'}
      />
    </div>
  )
}

export default memo(Results)
