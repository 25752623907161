import React from 'react'
import { Header, HeroFooter, PayBlock } from '../LandingV3/components'
import HeroBlock from './components/HeroBlock'
import NumbersBlock from './components/NumbersBlock'
import Footer from '../LandingV3/components/Footer'
import YouTubeBlock from './components/YouTubeBlock'
import { ProjectData } from './helpers/mock'

interface ProjectTemplateProps {
  data: ProjectData
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({
  data,
}) => {
  return (
    <div className={'w-full flex-col h-full flex-1 flex '}>
      <div data-blockname='Header'><Header /></div>
      <div data-blockname='HeroBlock'><HeroBlock description={data.description} title={data.title} logo={data.logo} mainPhoto={data.mainPhoto} /></div>
      <div data-blockname={'NumbersBlock'}><NumbersBlock numbers={data.numbers} /></div>
      <div data-blockname='YouTubeBlock'><YouTubeBlock url={data.video} /></div>
      <div data-blockname='PayBlock' className={'bg-[#E3E3DD] py-[48px]'}><PayBlock project={true} /></div>
      {data.impactBlock.block}
      <div data-blockname='HeroFooter'><HeroFooter
        description={'Commit to a sustainable future by directing your funds towards climate companies, which not only reduce future pollution but also have a positive impact on the lives of those most in need.'} />
      </div>

      <div data-blockname='Footer'><Footer /></div>
    </div>
  )
}

export default ProjectTemplate