import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import PrimaryButton from '../../../../../components/PrimaryButton'
import StarActive from '../../../../../assets/svg/star-active.svg'
import StarInactive from '../../../../../assets/svg/star-inactive.svg'
import Person1 from '../../../../../assets/img/person-1.png'
import Person2 from '../../../../../assets/img/person-2.png'
import Person3 from '../../../../../assets/img/person-3.png'
import TranslationText from '../../../../../components/TranslationText'

interface CarouselItemProps {
  title: string
  img: string
  text: string
  stars: number
  name: string
}

const FeedbackItems = [
  {
    title: 'landing-page.feedback-carousel.card1.title',
    img: Person1,
    stars: 5,
    name: 'landing-page.feedback-carousel.card1.name',
    text: 'landing-page.feedback-carousel.card1.text',
  },
  {
    title: 'landing-page.feedback-carousel.card2.title',
    img: Person2,
    stars: 5,
    name: 'landing-page.feedback-carousel.card2.name',
    text: 'landing-page.feedback-carousel.card2.text',
  },
  {
    title: 'landing-page.feedback-carousel.card3.title',
    img: Person3,
    name: 'landing-page.feedback-carousel.card3.name',
    stars: 5,
    text: 'landing-page.feedback-carousel.card3.text',
  },
]

const FeedbackItem: React.FC<CarouselItemProps> = ({
  title,
  img,
  text,
  stars,
  name,
}) => (
  <div
    className={'mx-6 rounded-[16px] bg-white flex-flex-col gap-6 mb-2 relative p-8 overflow-hidden shadow-md'}>
    <div className={'w-full flex items-center flex-row justify-start gap-2'}>
      {
        Array.from({ length: 5 }).map((_, index) => (
          <img
            key={index}
            src={index < stars ? StarActive : StarInactive}
            alt={'star'}
            className={'!w-[24px] !h-[24px] !bg-cover'}
          />
        ))
      }
    </div>
    <TranslationText
      className="w-full text-zinc-900 text-xl font-bold font-['Inter'] leading-[24px] pt-4 pb-8 text-start" translationKey={title}/>
    <TranslationText className=" text-black text-[14px] font-normal font-['Inter'] leading-[20px] text-start pb-4" translationKey={text}/>
    <div className='w-[54px] h-[0px] border border-black'></div>
    <div className='w-full flex flex-row gap-2 items-center pt-4'>
      <img className='!w-[40px] !h-[40px] rounded-full bg-cover' alt={name} src={img} />
      <TranslationText className="text-stone-900 text-base font-normal font-['Inter'] leading-snug" translationKey={name} />
    </div>
  </div>
)


const FeedbackCarousel: React.FC = () => {
  return (
    <div className={'w-full flex flex-col items-center justify-center bg-[#EBEFF1] pt-8 gap-8 '}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        transitionTime={500}
        className={'w-full h-[380px] '}
      >
        {FeedbackItems.map((item, index) => (
          <FeedbackItem key={index} title={item.title} img={item.img} text={item.text} stars={item.stars}
                        name={item.name} />
        ))}
      </Carousel>
      <div className={'py-[80px] w-full flex-col gap-4 flex items-center justify-center bg-[#B7F6C1] px-4 '}>
        <div className="w-[375px] text-center text-stone-900 text-[44px] flex flex-col gap-4 font-bold font-['Inter'] leading-[52px]" >
          <TranslationText translationKey={'landing-page.message-block'}/>
        <a href='#reduce-block' className={'w-full pt-8'}>
          <PrimaryButton
          >
            <div className={'normal-case text-active-green text-[24px] font-bold'}>
              Make an impact
            </div>
          </PrimaryButton>
        </a>
      </div>
    </div>
    </div>
  )
}

export default FeedbackCarousel