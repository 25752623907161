import React, { useEffect } from 'react'
import { CONSTANTS } from '../../../../../constants/values'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

interface CustomPayCardProps {
  emission: number;
}

const CustomPayCard: React.FC<CustomPayCardProps> = ({ emission }) => {
  const [error, setError] = React.useState<string | null>(null)
  const [blur, setBlur] = React.useState<boolean>(false)
  const [amount, setAmount] = React.useState<string | null>(null)
  const navigate = useNavigate()
  const percentage = () => {
    const tonsReduced: number = parseFloat(amount ?? '0') / CONSTANTS.PRICE_PER_MT
    return ((tonsReduced / emission ?? 1) * 100).toFixed(2)
  }

  useEffect(() => {
    if (amount) {
      const numericValue = parseFloat(amount)
      if (numericValue < 20
      ) {
        setError('Amount must be greater than 20')
      } else if (numericValue > 900) {
        setError('Amount must be less than 900')
      } else if (numericValue < 0) {
        setError('Amount must be greater than 0')
      }
      else {
        setError(null)
      }
    } else {
      setError(null)
    }
  }, [amount])

  return (
    <div
      className={' flex-1 md:max-w-[472px] w-full rounded-2xl border-[1px] border-[#B1B1B1] flex-col justify-start items-start inline-flex'}>
      <div
        className='self-stretch px-6 py-8 border-b border-stone-200 flex-col justify-start items-start gap-8 flex'>
        <div className='self-stretch  flex-col justify-start items-start gap-4 flex'>
          <div className='self-stretch justify-start items-start gap-4 inline-flex'>
            <div
              className='grow shrink basis-0 text-start text-neutral-500 text-xl font-semibold font-Inter leading-7'>Custom
              plan
            </div>
            <div />
          </div>
          <div className='self-stretch justify-start flex flex-col items-end gap-2 '>
            <div
              className='w-full h-12 px-3.5 py-2.5 bg-white rounded-lg shadow justify-start items-center inline-flex'>
              <input
                id='name'
                type='text'
                placeholder='Enter custom amount'
                onChange={(e) => {
                  ReactGA.event({
                    category: 'RP_MENU_PRICING',
                    action: 'RP_MENU_PRICING',
                    label: 'RP_MENU_PRICING',
                  })

                  const value = e.target.value
                  ReactGA.event({
                    category: 'RP_PRICING_ONE_TIME_CHANGE_CUSTOM_FIELD',
                    action: 'RP_PRICING_ONE_TIME_CHANGE_CUSTOM_FIELD' + value,
                    label: 'RP_PRICING_ONE_TIME_CHANGE_CUSTOM_FIELD',
                  })
                  const sanitizedValue = value.replace(/[^\d.]/g, '')
                  if (sanitizedValue) {
                    setAmount(sanitizedValue ?? '0')
                  } else {
                    setAmount(null)
                  }
                }}
                onBlur={() => {
                  setBlur(true)
                }}
                value={amount ?? ''}
                className={'w-full h-full text-zinc-500 text-[17px] font-normal font-Inter leading-normal outline-none '}
              />
            </div>
            {
              blur && error && <div className='text-red-500 w-full text-start text-sm pl-1'>{error}</div>
            }
          </div>
        </div>
        <button onClick={() => {
          ReactGA.event({
            category: 'RP_MENU_PRICING',
            action: 'RP_MENU_PRICING',
            label: 'RP_MENU_PRICING',
          })
          ReactGA.event({
            category: 'RP_PRICING_ONE_TIME_CUSTOM_AMOUNT',
            action: 'RP_PRICING_ONE_TIME_CUSTOM_AMOUNT' + amount,
            label: 'RP_PRICING_ONE_TIME_CUSTOM_AMOUNT',
          })
          navigate('/checkout', { state: { amount: (parseFloat(amount || '10') ?? 10), emission } },
          )
        }}
                disabled={amount !== '0001' ? (error !== null || amount === null || parseFloat(amount ?? '0') < 20 || parseFloat(amount ?? '0') > 900) : false}
                className='self-stretch px-6 cursor-pointer py-3.5 bg-stone-950 rounded-xl justify-center items-center gap-2 inline-flex'>
          <div className='text-center text-white text-[17px] font-semibold font-Inter leading-tight'>Make an impact
          </div>
        </button>
      </div>
      <div className='self-stretch px-6 pt-8 pb-10 flex-col justify-start items-start gap-6 flex'>
        <div className='self-stretch flex-col justify-start items-start gap-[11px] flex'>
          <div className='self-stretch justify-start items-start gap-2 inline-flex'>
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8.1665 12L11.1665 15L17.1665 9M22.6665 12C22.6665 17.5228 18.1894 22 12.6665 22C7.14366 22 2.6665 17.5228 2.6665 12C2.6665 6.47715 7.14366 2 12.6665 2C18.1894 2 22.6665 6.47715 22.6665 12Z'
                stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <div className='grow shrink basis-0 text-start'><span
              className='text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>You are offsetting </span><span
              className='text-neutral-600 text-[17px] font-semibold font-Inter leading-normal'>{percentage()}%</span><span
              className='text-neutral-600 text-[17px] font-normal font-Inter leading-normal'> of your future carbon emissions.</span>
            </div>
          </div>
          <div className='self-stretch justify-start items-start gap-2 inline-flex'>
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8.1665 12L11.1665 15L17.1665 9M22.6665 12C22.6665 17.5228 18.1894 22 12.6665 22C7.14366 22 2.6665 17.5228 2.6665 12C2.6665 6.47715 7.14366 2 12.6665 2C18.1894 2 22.6665 6.47715 22.6665 12Z'
                stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <div
              className='grow shrink basis-0 text-start text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>You
              fund a portfolio of climate projects.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPayCard