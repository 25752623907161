import React from 'react'
import Header from '../LandingV3/components/Header'

const EmptyScreen: React.FC = () => {
  return (
    <div className='w-full relative bg-stone-200 '>
      <Header />
      <div className={'flex flex-col items-center justify-center'}>
      <div className='w-full rounded-lg flex flex-col items-center justify-center py-16  '>
        <svg width='176' height='123' viewBox='0 0 176 123' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1 9C1 4.58172 4.58172 1 9 1H167C171.418 1 175 4.58172 175 9V12.5C175 12.7761 174.776 13 174.5 13H1.5C1.22385 13 1 12.7761 1 12.5V9Z'
            fill='#AFAFAF' stroke='#AFAFAF' strokeWidth='1.2' strokeLinecap='round' />
          <rect x='7' y='5' width='4' height='4' rx='2' fill='#E3E3DD' />
          <rect x='13' y='5' width='4' height='4' rx='2' fill='#E3E3DD' />
          <rect x='19' y='5' width='4' height='4' rx='2' fill='#E3E3DD' />
          <rect x='23' y='32' width='131' height='42' rx='4' fill='#AFAFAF' fillOpacity='0.25' stroke='#AFAFAF'
                strokeWidth='1.2' strokeLinecap='round' strokeDasharray='3 5' />
          <rect x='48' y='86' width='81' height='15' rx='4' fill='#AFAFAF' fillOpacity='0.25' stroke='#AFAFAF'
                strokeWidth='1.2' strokeLinecap='round' strokeDasharray='3 5' />
          <rect x='1' y='1' width='174' height='121' rx='8' stroke='#AFAFAF' strokeWidth='1.2' strokeLinecap='round'
                strokeDasharray='4 4' />
        </svg>
      </div>
      <div className='flex-col justify-start items-start gap-5 inline-flex px-4'>
        <div className='flex-col justify-start items-start gap-4 flex'>
          <div className="w-full text-stone-950 text-[28px] font-semibold leading-9 text-start"
                style={{
                  fontFamily: 'IBM Plex Serif',
                }}
          >Coming
            Soon
          </div>
          <div className="w-full text-neutral-600 text-[17px] font-normal leading-normal text-start">We are
            currently in the process of developing this page to bring you the best possible experience.<br />In the
            meantime, if you have any questions or suggestions, please feel free to reach out to us via the chat option
            in the corner. We would love to hear from you and assist you in any way we can.
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default EmptyScreen