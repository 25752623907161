import React, { useState } from 'react'
import { Calculator, Coins, CreditCard, FileCheck, Hand, Sun } from '../../assets/svg/icons'
import GrayListCard from './GrayListCard'
import ArrowDown from '../../assets/svg/arrow-down.svg'

const GrayCardsList = [
  {
    icon: Calculator,
    title: '1. Find out your carbon footprint',
    description: 'Use our intuitive carbon calculator to find out how much CO₂ you emit. Trust us, it’s more than you think.',
  },
  {
    icon: CreditCard,
    title: '2. Choose the project you want to support',
    description: 'You can make a one-time payment or select a suitable amount for a monthly contribution of the climate project of your choice',
  },
  {
    icon: Coins,
    title: '3. Your Money Funds Climate Companies',
    description: 'Your contribution is now at work with the climate innovators and the real impact begins. Each month, Pyrpose distribute funds to climate innovator companies, by transferring the collected funds.',
  },
  {
    icon: Hand,
    title: '4. Fuelling Growth and Transition',
    description: 'Your funds are used to operate and help as customers, families, facilities and companies, transition from using fossil fuels to adopting clean energy.',
  },
  {
    icon: Sun,
    title: '5. Climate Companies Reduce Carbon Emissions',
    description: 'By shifting to clean energy, the climate innovators offset future carbon emissions. Each offset is calculated separately for each project. (by third party institutions)',
  },
  {
    icon: FileCheck,
    title: '6. Track Your Impact',
    description: 'You receive regular updates on where your money is and how are it’s driving the positive change in the fight against climate change.',
  },
  // {
  //   icon: Refresh,
  //   title: '7. Regenerate Your Impact',
  //   description: 'You have the power to reinvest your funds and amplify your impacts by fostering a continuous cycle of positive change.',
  // },
  // {
  //   icon: CreditCardUpload,
  //   title: '8. Flexible Withdrawal',
  //   description: 'At any point of your impactful journey, you can decide to withdraw your funds.  Through your Pyrpose profile, you can submit an application for withdrawal and your funds will be returned as soon as the climate innovators pay their loans to Pyrpose.',
  // },
]

const GrayList: React.FC = () => {
  const [isViewMore, setIsViewMore] = useState(false)

  const displayedCards = isViewMore ? GrayCardsList : GrayCardsList.slice(0, 3)

  return (
    <div className='max-w-[608px] max-sm:px-4 relative py-16 flex-col justify-start items-start gap-2 inline-flex'>
      <div className='self-stretch text-[#37992E] text-xs font-bold font-Inter uppercase text-start tracking-wide'>make
        an
        impact
      </div>
      <div className='self-stretch text-stone-950 text-[28px] font-semibold font-IBM text-start'>Here’s how it works
      </div>
      <div className={'flex flex-col gap-4 pt-8'}>
        {
          displayedCards?.map((item) =>
            <GrayListCard
              key={item.title}
              icon={item.icon}
              title={item.title}
              description={item.description}
            />,
          )
        }
      </div>
      <div
        className={`w-full p-8 rounded-xl flex-col justify-center items-center gap-4 inline-flex  ${isViewMore ? 'hidden' : 'flex'}`}
        style={{
          background: 'linear-gradient(180deg, #E3E3DD 0%, #E3E3DD00 100%)',
        }}
      >
        <div className='self-stretch px-5 py-3 bg-stone-950 rounded-lg justify-center items-center gap-2 inline-flex'
              onClick={() => setIsViewMore(!isViewMore)}
        >
          <div className="text-center text-white text-[17px] font-medium font-['Inter'] leading-tight">Discover all
          </div>
          <img src={ArrowDown} alt={'ArrowDown'} className='w-5 h-5 relative' />
        </div>
      </div>

    </div>
  )
}
export default GrayList