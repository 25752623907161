export function getMachineId(): string {
  let machineId = localStorage.getItem('machineId')

  if (!machineId) {
    machineId = crypto.randomUUID()
    localStorage.setItem('machineId', machineId)
  }

  return machineId
}
