import React from 'react'
import CoverEmissionButton from '../../CoverEmissionButton'
import ReactGA from 'react-ga4'
import SphereInfoFooterV2 from '../../../../../assets/svg/sphere-info-footer-v2.svg'
import TranslationText from '../../../../../components/TranslationText'

const InfoBlock: React.FC = () => {
  return (
    <div className={'w-full mt-[40px] gap-[33px] flex flex-col items-center justify-center px-4'}>
      <TranslationText translationKey={'result-page.info-block.title'} className="w-full text-center text-stone-900 text-[32px] font-bold font-['Inter'] leading-[35px]"/>
      <img src={SphereInfoFooterV2} alt={'SphereInfoFooterV2'} className={'w-10/12 sm:w-1/2'} />
      <TranslationText translationKey={'result-page.info-block.text'} className="w-full text-center text-stone-900 text-base font-normal font-['Inter'] leading-snug"/>
      <a href={'#reduce-block'} className='no-underline w-full'>
        <CoverEmissionButton
          text={'Make an impact'}
          big
          onClick={() => {
            ReactGA.event({
              category: 'Reduce Button',
              action: 'Reduce button pressed',
              label: 'Reduce button pressed',
            })
          }}
        />
      </a>
    </div>
  )
}

export default InfoBlock
