import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import config from '../config'

const protocol = config.apiEndpoint?.includes('localhost') ? 'http' : 'https'

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${protocol}://${config.apiEndpoint}/translations/{{lng}}`,
    },
  })

export default i18n
