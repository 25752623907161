import React from 'react'
import { Slider } from '@mui/material'

interface QuizSliderProps {
  // eslint-disable-next-line
  formik: any
  // eslint-disable-next-line
  question: any
}

const QuizSlider: React.FC<QuizSliderProps> = ({ formik, question }) => {
  return (
    <Slider
      style={styles.slider}
      aria-label='ios slider'
      step={question.step}
      min={question.min}
      max={question.max}
      marks={question.max / question.step < 10}
      value={formik.values[question.id].value}
      onChange={(e, val) => {
        formik.setFieldValue(question.id, { ...formik.values[question.id], value: val })
      }}
      sx={{
        '& .MuiSlider-thumb': {
          height: 40,
          width: 40,
          marginRight: '-25px',
          marginLeft:
            formik.values[question.id].value === question.min
              ? '-6px'
              : window.innerWidth > 768
              ? '0.7%'
              : '0px',
          borderRadius: '25px',
          backgroundColor: '#78FD85',
          boxShadow: '0px 0px 0px 0px rgba(120,253,133,0.3)',
          border: '0px solid #78FD85',
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
          overflow: 'hidden',
          '&:focus, &:hover, &$active': {
            boxShadow: '0px 0px 0px 0px #000 !important',
          },
        },
        '& .MuiSlider-thumb:hover': {
          boxShadow: '0px 0px 0px 0px #000 !important',
        },
        '& .MuiSlider-thumb:after': {
          content: '""',
          position: 'absolute',
          width: 40,
          height: 40,
          borderRadius: '50%',
          borderWidth: '4px',
          borderStyle: 'solid',
          borderColor: '#78FD85',
          backgroundColor: '#fff',
        },
        '& .MuiSlider-thumb:before': {
          boxShadow: '0px 0px 0px 0px #000 !important',
        },
        '& .MuiSlider-track': {
          height: 40,
          borderTopLeftRadius: 25,
          borderBottomLeftRadius: 25,
          border: 'none',
          left: '-5.6% !important',
          paddingRight: 20 + '!important',
          width: `${
            ((formik.values[question.id].value - question.min) / (question.max - question.min)) *
              100 +
            6
          }%  !important`,
          backgroundColor: '#78FD85',
        },
        '& .MuiSlider-rail': {
          border: 'none',
          backgroundColor: '#000',
          borderRadius: 25,
          left: '-5%',
          width: '108%',
          opacity: 1,
        },
        '& .MuiSlider-mark': {
          backgroundColor: '#fff',
          height: 8,
          width: 8,
          borderRadius: '50%',
          '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: '#78FD85',
          },
        },
      }}
    />
  )
}

const styles = {
  slider: {
    paddingHorizontal: 20,
    borderRadius: 4,
    height: 40,
  },
}

export default QuizSlider
