import React, { memo, useState } from 'react'
import Modal from '../../shared/Modal'
import { TextField, Typography } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
} from 'react-share'
import Or from '../../assets/svg/or_separator.svg'
import Tooltip from '@mui/material/Tooltip'
import SecondaryButton from '../../components/SecondaryButton'
import { IModalProps } from '../../shared/Inrefaces'
import ReactGA from 'react-ga4'
import { getShareLink, postUser } from '../../utils/api'
import { useQuery } from 'react-query'

const ShareLinkModal = (props: IModalProps) => {
  const { show } = props
  const [skip, setSkip] = useState(false)
  const [link, setLink] = useState('')
  const [open, setOpen] = React.useState(false)
  const title = 'Check out my Pyrpose results!'
  useQuery(['shareLink'], getShareLink, {
    onSuccess: (data) => {
      setLink(`${window.location.protocol}//${window.location.hostname}${data}`)
    },
    refetchOnMount: true,
  })

  const handleSkip = () => {
    setSkip(true)
    props.onSkip(skip)
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Skip ShareLinkModal',
      label: 'Skip Share Link',
    })
  }

  const registerGA = (social: string) => {
    ReactGA.event({
      category: 'ShareLinkSocial',
      action: `Share social ${social}`,
      label: link,
    })
    return true
  }

  const copyShareLink = async () => {
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Copy share link',
      label: link,
    })
    navigator.clipboard.writeText(link)
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 3000)

    // Analytics count users who copied the link
    postUser({ shareCopied: true }).then()
  }

  return (
    <Modal show={show}>
      <div className={'flex flex-col md:min-w-[416px] max-w-[416px]  items-start justify-between'}>
        <div className={'flex items-center justify-between w-full'}>
          <div className={'share-title'}>📬 Share</div>
          <div className={'share-skip'} aria-label='close' onClick={handleSkip}>
            close
          </div>
        </div>

        <div className={'share-message mb-[56px]'}>
          Share your results using services or just copy the link
        </div>

        <div className={'flex items-center justify-evenly w-full'}>
          <a
            href={`https://wa.me/?text=${title}%20${link}`}
            target='_blank'
            rel='noreferrer'
            className={'cursor-pointer'}
            onClick={() => registerGA('whatsapp')}
          >
            <WhatsappIcon size={48} round />
          </a>
          <FacebookShareButton url={link} quote={title} onClick={() => registerGA('facebook')}>
            <FacebookIcon size={48} round />
          </FacebookShareButton>
          <TwitterShareButton url={link} onClick={() => registerGA('twitter')}>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <TelegramShareButton url={link} onClick={() => registerGA('telegram')}>
            <TelegramIcon size={48} round />
          </TelegramShareButton>
          <LinkedinShareButton url={link} onClick={() => registerGA('linkedin')}>
            <LinkedinIcon size={48} round />
          </LinkedinShareButton>
        </div>

        <div
          className='w-full'
          style={{
            backgroundImage: `url(${Or})`,
            height: '22px',
            backgroundSize: 'contain',
            marginTop: '22px',
            marginBottom: '23px',
          }}
        ></div>

        <TextField
          variant='outlined'
          color={'primary'}
          placeholder={'Link'}
          className={'w-full px-4 '}
          value={`🖇️ ${link}`}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '15px',
              backgroundColor: '#FFFFFF',
              marginBottom: '17px',
            },
          }}
        />
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title='Link copied to clipboard!'
        >
          <SecondaryButton
            variant='outlined'
            onClick={copyShareLink}
            className={'w-full px-4 h-[61px]'}
          >
            <Typography
              style={{ color: '#000000', fontSize: 22, fontWeight: '700', textTransform: 'none' }}
            >
              Copy Link
            </Typography>
          </SecondaryButton>
        </Tooltip>
      </div>
    </Modal>
  )
}

export default memo(ShareLinkModal)
