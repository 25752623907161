import React from 'react'
import MainBackgroundResults from '../../assets/img/co2.webp'
import AnimatedNumber from '../AnimatedNumber'

interface MainBlockProps {
  emission: number;

}

const MainBlock: React.FC<MainBlockProps> = ({
  emission,
}) => {

  return (
    <div className={'w-full flex items-center justify-center max-sm: mt-10 sm:px-12'}>
      <div
        className={'w-full max-w-[1400px] bg-[#0C4725]  flex-row relative rounded-[12px] min-h-[390px] justify-start flex-grow items-center inline-flex container '}>
        <div
          className='flex-col justify-start  w-1/2  max-xl:w-full max-xl:items-center max-sm:py-[95px] items-center pl-10 pr-[90px] max-xl:pr-10 gap-6 flex  '>
          <div
            className=' text-[#83F279] text-[56px] text-start max-xl:text-center font-extrabold font-Inter leading-[67px]'>Your
            carbon footprint is <AnimatedNumber to={emission} /> <br/> tons of CO₂
          </div>
        </div>
        <div
          className='relative h-full w-1/2 max-xl:hidden min-h-[390px] flex flex-grow rounded-r-lg overflow-hidden items-center'>
          <div className='absolute inset-0 flex items-center top-0 bottom-0 justify-center'>
            <img
              src={MainBackgroundResults}
              alt='Kevin'
              className='w-full h-full min-h-full object-cover'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBlock