import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { Box, Typography } from '@mui/material'
import Next from '../assets/svg/next.svg'

interface IPrimaryButton extends ButtonProps {
  children: React.ReactNode
  next?: boolean
  background?: string
  nextLabel?: string
  nextColor?: string
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
}

const PrimaryButton: React.FC<IPrimaryButton> = ({
  children,
  next,
  nextLabel,
  background,
  type,
  nextColor,
  disabled,
  ...props
}) => {
  return (
    <Button
      variant='contained'
      className='rounded-full'
      type={type}
      size='large'
      disabled={disabled}
      style={{
        width: '100%',
        height: 64,
        backgroundColor: background || '#000',
        opacity: disabled ? 0.5 : 1,
        borderRadius: '16px',
        ...props.style,
      }}
      sx={{
        ':hover p': {
          color: disabled ? '' : '#78FD85 !important',
        },
        ...props.sx,
      }}
      {...props}
    >
      {next ? (
        <Box
          className={'w-full items-center justify-center flex'}
          sx={{
            ':hover h4': {
              color: disabled ? '' : '#78FD85 !important',
            },
            h4: {
              color: nextColor ? '' : '#fff !important',
            },
            path: {
              stroke: nextColor || '#fff !important',
            },
            circle: {
              stroke: nextColor || '#fff !important',
            },
            ':hover path': {
              stroke: disabled ? '' : '#78FD85 !important',
            },
            ':hover circle': {
              stroke: disabled ? '' : '#78FD85 !important',
            },
          }}
        >
          <Typography
            variant={'h4'}
            style={{ fontSize: 24, marginRight: 12, fontWeight: 'bold', textTransform: 'none' }}
          >
            {nextLabel ? nextLabel : 'Next'}
          </Typography>
          <img
            src={Next}
            alt={'Next'}
            style={{ width: '20px', height: 'auto', color: nextColor || '#fff' }}
          />
        </Box>
      ) : (
        <Box
          className={'w-full items-center justify-center flex'}
          sx={{
            ':hover h4': {
              color: disabled ? '' : '#78FD85 !important',
            },
            ':hover path': {
              stroke: disabled ? '' : '#78FD85 !important',
            },
            ':hover circle': {
              stroke: disabled ? '' : '#78FD85 !important',
            },
          }}
        >
          {' '}
          {children}{' '}
        </Box>
      )}
    </Button>
  )
}

export default PrimaryButton
