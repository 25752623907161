import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../shared/Modal';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import PrimaryButton from './PrimaryButton';
import { updateTranslations } from '../utils/api';
import { Markup } from 'interweave';

export interface replaceArray {
  key: string;
  value: string;
}

interface TranslationTextProps extends React.HTMLAttributes<HTMLDivElement> {
  translationKey: string;
  className?: string;
  replace?: replaceArray[];
}

const TranslationText: React.FC<TranslationTextProps> = ({
  translationKey,
  className,
  replace,
  ...props
}) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const translationValue = t(translationKey);

  let replacedValue = translationValue;

  if (replace) {
    replace.forEach((item) => {
      replacedValue = replacedValue.replace(item.key, item.value);
    });
  }

  const isAdmin =  localStorage.getItem('isAdmin') === 'true'

  const formik = useFormik({
    initialValues: {
      key: translationKey,
      lang: 'en',
      value: translationValue,
    },
    onSubmit: (values) => {
      updateTranslations(values).then(() => {
        setIsOpened(false);
      }).catch((err) => {
        console.log(err);
      });
    },
  });

  return (
    <>
      <div
        className={`${className} ${isAdmin ? 'underline decoration-active-green relative' : ''}`}
        {...props}
      >
        <Markup content={replacedValue} />
        {isAdmin && (
          <div
            className="absolute top-[-8px] right-0 text-[10px] cursor-pointer hover:text-active-green z-[100]"
            onClick={() => {
              setIsOpened(true);
            }}
          >
            +
          </div>
        )}
      </div>
      {isAdmin && isOpened && (
        <Modal show={isOpened}>
          <div className={'py-8 px-2 flex-col flex'}>
            <div className={'flex flex-row justify-between items-center '}>
              <div className={'text-center text-[20px] font-bold'}>Translation</div>
              <div className={'text-center text-[20px] font-bold'} onClick={
                () => {
                  setIsOpened(false)
                }
              }>Close
              </div>
            </div>
            <div className={'flex flex-col gap-4 justify-between mt-8'}>
              <TextField
                id='value'
                label='Multiline'
                multiline
                rows={4}
                value={formik.values.value}
                onChange={formik.handleChange}
                defaultValue='Default Value'
              />
              <PrimaryButton
                className={'mt-8 mb-4 mx-auto !bg-active-green'}
                onClick={
                  () => {
                    formik.handleSubmit()
                  }
                }
              >
                <div className={'font-bold text-[24px] normal-case '}>
                  Save
                </div>
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TranslationText;
