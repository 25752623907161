import React from 'react'
import LinkedIn from '../../assets/images/linked-in.svg'

interface TeamCardProps {
  image: string
  name: string
  description?: string
  role?: string
  url: string
}

const TeamCard: React.FC<TeamCardProps> = ({
  image,
  name,
  description,
  role,
  url,
}) => {
  const [isActive, setIsActive] = React.useState(false)
  return (
    <div className={'sm:w-[48%] w-full '}>
      <div
        className=' w-full bg-white rounded-xl flex-col justify-start items-center inline-flex overflow-hidden'>
        <div className='relative w-[100%] pt-[100%] sm:h-[306px]'>
          <img alt={name} src={image} className='absolute top-0 left-0 w-full h-full object-cover' />
        </div>
        <div className='self-stretch px-4 py-6 flex-col justify-start items-start gap-3 flex relative'>
          {description && <div
            className=' left-0 right-0 flex items-center justify-center top-[-14px] absolute '>
            <div onClick={() => setIsActive(!isActive)}
                 className='text-green-600  hover:bg-green-200 px-2 py-1.5 cursor-pointer  text-xs font-bold font-Inter uppercase bg-white rounded-[40px] shadow justify-start items-center gap-2 inline-flex leading-[17px] tracking-tight'>{
              isActive ? 'Collapse Bio' : 'Read Bio'
            } <div className={`${isActive && 'rotate-180'}`}>
              <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1 1L5 5L9 1' stroke='#37992E' strokeWidth='1.6' strokeLinecap='round'
                      strokeLinejoin='round' />
              </svg>
            </div>
            </div>

          </div>}
          <div className='self-stretch justify-between items-center inline-flex'>
            <div className='grow shrink basis-0 h-[46px] flex-col justify-center items-start gap-0.5 inline-flex'>
              <div
                className='self-stretch text-stone-950 text-[17px] font-semibold font-Inter'>{name}</div>
              {role && <div
                className='self-stretch text-neutral-500 text-[15px] font-normal font-Inter'>{role}</div>}
            </div>
            <a href={url} className='w-6 h-6 relative'>
              <img src={LinkedIn} alt={'link-in'} />
            </a>
          </div>
          {isActive && (
            <>
              <div className='w-full h-[1px] bg-neutral-200' />
              <div className='w-full text-neutral-500 text-[17px] font-normal font-Inter'>{description}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamCard