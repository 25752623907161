import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import { Transition } from '@headlessui/react'
import KevinHorizontal from '../../../LandingV3/assets/images/kevin_horiz.webp'
import KevinVertical from '../../../LandingV3/assets/images/kevin_vert.webp'
import Arrow from '../../../LandingV3/icons/Arrow'
import Button from '../../../LandingV3/components/Button'


const MobileVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  /*
  const handlePlayClick = () => {
    if (window.location.href.includes('results')) {
      ReactGA.event({
        category: 'RP_VIDEO_PLAY',
        action: 'RP_VIDEO_PLAY',
        label: 'RP_VIDEO_PLAY',
      })
    } else {
      ReactGA.event({
        category: 'HP_VIDEO_PLAY',
        action: 'HP_VIDEO_PLAY',
        label: 'HP_VIDEO_PLAY',
      })
    }
    const verticalVideo = document.getElementById('responsiveVideoVerticalMobile') as HTMLVideoElement | null

    if (verticalVideo) {
      const playPromise = verticalVideo.play()

      if (playPromise !== undefined) {
        playPromise.then(() => {
          setIsPlaying(true)
        }).catch(error => {
          console.error('Error attempting to play the video:', error)
        })
      }
    }
  }
  */

  return (
    <div
      className='w-full px-4 py-16 flex-col justify-start items-start gap-8 inline-flex md:max-w-[640px] md:self-center'>
      <div className='flex-col justify-start items-start gap-6 flex'>
        <div className='flex-col justify-start items-start gap-2 flex'>
          <div
            className='text-[#37992E] text-xs font-bold uppercase tracking-wide'>Shape your tomorrow
          </div>
          <div className='text-[28px] text-start font-semibold font-Inter leading-9'
          >{/* Everyone should have the opportunity to сommit to a sustainable future */}
            Everyone should have access to investments that matter for our planet
          </div>
        </div>
        <div className='text-start  text-[#525252] text-[17px] font-normal font-Inter leading-normal'>Gain access to exclusive, vetted opportunities in the climate sector, poised for climate impact and returns of 6 to 12%. By investing with Pyrpose, you&apos;re not just diversifying your portfolio; you&apos;re actively participating in the global shift towards sustainable energy.
        </div>
      </div>
      <div className='relative w-full h-full rounded-lg overflow-hidden '>
        <video
          id='responsiveVideoVerticalMobile'
          className='w-full h-full rounded-lg md:hidden '
          controls={isPlaying}
          playsInline
          onPlay={() => setIsPlaying(true)}
        >
          <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_vertical_1108.MP4'} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <Transition
          show={!isPlaying}
          enter='transition-opacity duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className=' absolute bottom-4 flex items-start justify-start left-4 flex-col z-[10] w-full  '>
              <div className="text-start text-white text-[17px] font-semibold font-['Inter'] leading-normal">Kevin
                Kyer
              </div>
              <div className=" text-white text-[15px] font-normal font-['Inter'] leading-tight">CEO, Founder of
                Pyrpose
              </div>
            </div>
            <picture className='w-full h-full object-cover'>
              <source srcSet={KevinVertical} media='(max-width: 728px)' />
              <source srcSet={KevinHorizontal} media='(min-width: 729px)' />
              <img
                src={KevinVertical}
                alt='Kevin'
                className='w-full h-full object-cover rounded-lg'
              />
            </picture>
            {/* <button
              className='absolute text-white rounded-lg p-2'
              onClick={handlePlayClick}
            >
              <svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect width='72' height='72' rx='36' fill='white' />
                <path d='M48 36L30 46.3923L30 25.6077L48 36Z' fill='#37992E' />
              </svg>
            </button> */}
          </div>
        </Transition>
      </div>

      <Button text={<div className={'flex flex-row items-center justify-center gap-2'}>
        <div className='text-center text-[16px] font-medium '>Our mission</div>
        <Arrow />
      </div>} onClick={
        () => {
          if (window.location.href.includes('results')) {
            ReactGA.event({
              category: 'RP_BTN_VIDEO_MISSION',
              action: 'RP_BTN_VIDEO_MISSION',
              label: 'RP_BTN_VIDEO_MISSION',
            })
          } else {
            ReactGA.event({
              category: 'HP_BTN_VIDEO_MISSION',
              action: 'HP_BTN_VIDEO_MISSION',
              label: 'HP_BTN_VIDEO_MISSION',
            })
          }
          window.open('/mission', '_self')
        }
      } disabled={false} type={'M'} />
    </div>
  )
}

export default MobileVideo