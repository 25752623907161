import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/svg/Logo'
import { useNavigate } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'
import ReactGA from 'react-ga4'
import { useAuth } from '../../../../utils/AuthContext/AuthProvider'

interface HeaderProps {
  name?: string
  email?: string
}

const Header: React.FC<HeaderProps> = ({
  name,
}) => {
  const [isSideMenuOpen, ] = useState(false)
  const [, setIsScrolled] = useState(false)
  const [showMenuBlock, setShowMenuBlock] = useState(false)
  const navigate = useNavigate()
  const { signOut, user } = useAuth()

  const [headerTop, setHeaderTop] = useSpring(() => ({
    top: 0,
  }))


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 76) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [showHeader, setShowHeader] = useState(true)
  const lastScrollY = useRef(0)

  useEffect(() => {
    if (showHeader)
      setHeaderTop({
        top: 0,
      })
    else
      setHeaderTop({
        top: -76,
      })
  }, [showHeader])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isResponsiveMode = window.innerWidth < 768

      if (isResponsiveMode && currentScrollY < 76) {
        setShowHeader(currentScrollY < 76)
      } else {
        if (currentScrollY < lastScrollY.current) {
          setShowHeader(true)
        } else if (currentScrollY > lastScrollY.current) {
          setShowHeader(false)
        }
      }

      lastScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleMenuIconClick = () => {
    setShowMenuBlock(!showMenuBlock)
  }
  const getInitials = (name: string): string => {
    if (!name.trim()) {
      return 'US'
    }
    const parts = name.split(' ')
    if (parts.length === 1) {
      return parts[0].substring(0, 2).toUpperCase()
    } else {
      return (parts[0][0] + parts[1][0]).toUpperCase()
    }
  }

  const onLogOut = () => {
    signOut()
    localStorage.removeItem('token')
    navigate('/login')
  }

  const onProfileClick = () => {
    navigate('/profile')
  }

  return (
    <>
      {!isSideMenuOpen && (
        <animated.div
          style={headerTop}
          className={'w-full flex sm:h-[76px] transition duration-700 h-[65px] items-center justify-center sm:px-12 px-4 fixed z-[1000] bg-white '}>
          <div className={'container flex flex-row flex-nowrap justify-between w-full max-w-[1400px] '}>
            <div className={'w-[130px]'}>
              <Logo className={'w-full h-[51px] cursor-pointer self-center '} onClick={() => {
                navigate('/')
                ReactGA.event({
                  category: 'RP_MENU_HEADER',
                  action: 'RP_MENU_HEADER',
                  label: 'RP_MENU_HEADER',
                })
                ReactGA.event({
                  category: 'RP_MENU_HEADER_HOME',
                  action: 'RP_MENU_HEADER_HOME',
                  label: 'RP_MENU_HEADER_HOME',
                })

              }} />
            </div>
            {user &&
              <div className='relative inline-flex justify-center self-center flex-col items-end'>
                <div
                  className={'w-10 h-10  bg-green-200 hover:bg-[#83F279] cursor-pointer rounded-[40px] flex items-center justify-center'}
                  onClick={handleMenuIconClick}>
                  {showMenuBlock ?
                    <div
                      className='w-11 h-11 left-[-2px] top-[-2px] absolute rounded-[60px] border border-green-200' /> :
                    <div />}
                  <div
                    className=' text-center text-green-800 text-[17px] font-medium font-Inter leading-normal'>{getInitials(name || '')}</div>
                </div>

                {
                  showMenuBlock && (
                    <div
                      className='w-[221px] max-sm:hidden p-2 bg-white rounded-lg shadow justify-start items-start gap-2 inline-flex absolute top-full right-0 mt-1 z-[1000] '>
                      <div className='flex-col justify-start items-start inline-flex w-full'>
                        <div
                          className='px-3 py-2 hover:bg-black hover:bg-opacity-10 w-full cursor-pointer rounded justify-start items-center inline-flex'
                          onClick={onProfileClick}>
                          <div
                            className="text-stone-950 text-[15px] text-start font-normal font-['Inter'] leading-normal">My
                            Profile
                          </div>
                        </div>
                        <div
                          className='px-3 py-2 hover:bg-black hover:bg-opacity-10 w-full cursor-pointer rounded justify-start items-center gap-2.5 inline-flex'
                          onClick={onLogOut}>
                          <div className="text-stone-950 text-[15px] font-normal font-['Inter'] leading-normal">Log out
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            }
          </div>
        </animated.div>
      )}
    </>
  )
}

export default Header
