import React, { type FC, memo } from 'react'
import Field0 from '../../../../assets/img/FootballField/fullField.webp'
import Shadow from '../../../../assets/svg/Shadow.svg'
import { getFieldFromImage, getTreesFromImage } from '../../utils/fieldImages'
import 'animate.css'
interface IFootballField {
  field?: string
  trees?: string
}
const FootballField: FC<IFootballField> = ({ field = '0', trees = '0' }) => {
  const file = getFieldFromImage(field)
  const treesFile = getTreesFromImage(trees)

  return (
    <div
      className='w-full relative flex items-center animate__animated animate__fadeIn animate__slow '
      style={{
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <img
        src={Field0}
        alt={'field-0'}
        className='w-full h-full'
        style={{
          visibility: 'hidden',
        }}
      />
      <img
        src={file}
        alt={`field-${field}`}
        className='w-full h-full absolute top-[10%] z-[10] left-0 bottom-0 right-0 object-contain '
        style={{ objectFit: 'contain', transition: 'all 0.5s ease-in-out' }}
      />
      {trees !== '0' && (
        <img
          src={treesFile}
          alt={`trees-${field}`}
          className='absolute w-[90%] top-[5%] bottom-0 z-[10] right-[5%] left-[5%]  object-contain '
          style={{
            transition: 'all 0.5s ease-in-out',
          }}
        />
      )}
      <img
        src={Shadow}
        className={
          'bottom-[-100px] mt-100 pt-[17%] left-0 right-0 absolute top-0 z-[5] object-bottom object-fill'
        }
      />
    </div>
  )
}

export default memo(FootballField)
