import React, { useEffect, useRef, useState } from 'react'
import Header from '../LandingV4/components/Header'
import TextBlock from './components/TextBlock'
import { MobileVideo, Video } from '../LandingV4/components/Video'
import Projects from '../LandingV4/components/Projects'
import Testimonials from '../LandingV4/components/Testimonial'
import Footer from '../LandingV4/components/Footer'
import { Footer as MobileFooter } from '../LandingV3/components'
import HowItWorks from './components/HowItWorks'
import OfferBlock from './components/OfferBlock'
import MainBlock from './components/MainBlock'
import { useQuery } from 'react-query'
import { getSurveyFootprint, postFunnel } from '../../utils/api'
import { useLocation } from 'react-router-dom'
import LoaderPulse from '../../components/LoaderPulse'
import ResultsBlock from './components/ResultsBlock'
import PayBlock from './components/PayBlock'
import SignUp from '../LandingV4/components/SignUp'
import ReactGA from 'react-ga4'

export interface IFootprint {
  emissions?: IEmission[];
  housing?: number;
  food?: number;
  transportation?: number;
  shopping?: number;
  other?: number;
  average: number;
  myEmission: string;
  ratio: number;
  refs?: IFootprint[] | null;
  name?: string;
  surname?: string;
  email?: string;
  footballFields?: number;
  trees?: number;
  fields?: Array<string>;
  prices?: IFootprintPricing | null;
  returnPercentage?: number;
}

export interface IEmission {
  label?: string;
  category?: string;
  color?: string;
  value?: number;
}

export interface IFootprintPricing {
  title: string;
  id: string;
  min: number;
  max: number;
  labelMin: string;
  labelMax: string;
  values: Array<IFootprintPricingValue>;
}

export interface IFootprintPricingValue {
  title: string;
  value: number;
  money: number;
}

const ResultsV2: React.FC = () => {
  const [result, setResult] = useState<IFootprint | null>(null)
  const { state } = useLocation()

  const { isLoading } = useQuery(
    ['getFootprint'],
    async () => {
      getSurveyFootprint(state?.ref).then((res) => {
        if (res) {
          postFunnel({
            co2: res?.myEmission,
          })
          setResult(res)
        }
      })
    },
    {
      refetchOnMount: true,
    },
  )

  const blocksRef = useRef({})

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        const blockName = entry.target.getAttribute('data-blockname')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (blocksRef.current[blockName!]) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blocksRef.current[blockName!] = true

        ReactGA.event({
          category: 'Scrolling ResultV2',
          action: `Scrolled to block ResultV2 ${blockName}`,
          label: `Scrolled to block ResultV2 ${blockName}`,
        })

        if (Object.keys(blocksRef.current).length === 13) {
          ReactGA.event({
            category: 'Scrolling ResultV2',
            action: 'Scrolled through entire page ResultV2',
          })
        }
      }
    })
  }

  useEffect(() => {
    const hash = window.location.hash
    if (hash && hash === '#signup') {
      const teamSection = document.getElementById('signup')
      if (teamSection) {
        window.scrollTo({
          top: teamSection.offsetTop ,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  })

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-blockname]')
    blocks.forEach((block) => observer.observe(block))
    return () => {
      blocks.forEach((block) => observer.unobserve(block))
    }
  }, [observer])

  if (isLoading) {
    return <LoaderPulse />
  }

  return (
    <div className={'min-w-full w-full flex flex-col bg-white'}>
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div data-blockname='MainBlock'><MainBlock emission={parseFloat(result?.myEmission ?? '0')} /></div>
      <div data-blockname='ResultsBlock'><ResultsBlock emission={parseFloat(result?.myEmission ?? '0')} /></div>
      <div data-blockname='PayBlock'><PayBlock emission={parseFloat(result?.myEmission ?? '0')} /></div>
      <div data-blockname='OfferBlock'><OfferBlock /></div>
      <div data-blockname='TextBlock'><TextBlock /></div>
      <div data-blockname='Projects'><Projects /></div>
      <div data-blockname='Video'><Video /></div>
      <div className={'h-[120px] max-sm:h-[80px] lg:h-[160px]'} />
      <div className={'sm:hidden bg-[#E5E5DF]'}>
        <div data-blockname='MobileVideo'><MobileVideo /></div>
      </div>
      <div data-blockname='HowItWorks'><HowItWorks /></div>
      <div data-blockname='Testimonials'><Testimonials /></div>
      <div data-blockname='SignUp' id={'signup'}><SignUp /></div>
      <div data-blockname='Footer'><Footer /></div>
      <div className={'md:hidden'}>
        <div data-blockname='MobileFooter'><MobileFooter /></div>
      </div>
    </div>
  )
}

export default ResultsV2