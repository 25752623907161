import React, { useState } from 'react'
import TextAnimation from './TextAnimation'
import { Parallax } from 'react-scroll-parallax'
import { useSpring } from '@react-spring/web'

const TextBlock: React.FC = () => {
  const [colorIndex, setColorIndex] = useState(0)
  const text1 = '73% of climate emissions are caused by non-renewable energy and are driven by fossil fuels'
  const text2 = 'We cannot rely on governments and the finance elite anymore, so it’s time for you to act'
  const text3 = 'We need to accelerate the transition from fossil fuels and be part of existing solutions'
  const text1Length = text1.split('').length
  const text2Length = text2.split('').length
  const text3Length = text3.split('').length

  const [opacityBlock1, setOpacityBlock1] = useState(0)
  const [opacityBlock2, setOpacityBlock2] = useState(0)
  const [opacityBlock3, setOpacityBlock3] = useState(0)

  const calculateOpacity = (progress: number, start: number, end: number) => {
    if (progress < start) return 0
    if (progress > end) return 1
    return (progress - start) / (end - start)
  }

  const totalLength = text1Length + text2Length + text3Length
  const [, setIsExited] = useState(false)

  const colorIndexList2 = Math.max(0, Math.min(colorIndex - text1.length, text2.length))
  const colorIndexList3 = Math.max(0, colorIndex - text1.length - text2.length)

  const [, setBlockStyle] = useSpring(() => ({
    top: '200px',
  }))


  const updateBlockPosition = (progress: number) => {
    const block1Start = 0, block1End = 0.50
    const block2Start = 0.50, block2End = 0.6
    const block3Start = 0.6, block3End = 1

    if (progress >= block1Start && progress < block1End) {
      setBlockStyle({ top: '80px' })
    } else if (progress >= block2Start && progress < block2End) {
      setBlockStyle({ top: '-50px' })
    } else if (progress >= block3Start && progress < block3End) {
      setBlockStyle({ top: '-200px' })
    }
  }

  const onProgressChange = (progress: number) => {
    const normalizedProgress = Math.min(Math.max(0, progress - 0.4), 0.9)

    setOpacityBlock1(calculateOpacity(progress, 0.1, 0.2))
    setOpacityBlock2(calculateOpacity(progress, 0.2, 0.3))
    setOpacityBlock3(calculateOpacity(progress, 0.3, 0.4))

    const scaledProgress = normalizedProgress / 0.3

    const visiblePercentage = scaledProgress * totalLength

    updateBlockPosition(progress)

    setColorIndex(visiblePercentage)
  }


  return (
    <div className={'w-full bg-white flex items-center justify-center sm:px-12 px-4'}>
      <Parallax
        className={'container w-full max-w-[1400px] flex flex-row gap-4 justify-between items-start rounded-lg py-[120px] max-lg:py-[80px] lg:py-[160px]'}
        onProgressChange={onProgressChange}
        onExit={() => {
          setIsExited(true)
        }}
      >
        <div className={'flex flex-row max-lg:flex-col max-lg:gap-8'}>
          <div className={'flex-1 flex-row flex  flex-nowrap justify-start '} style={{ opacity: opacityBlock1 }}>
            <div className='bg-black w-[1px] ml-[44px] flex  max-lg:hidden  mr-4 '
                 style={{ height: `${opacityBlock1 * 100}%` }} />
            <TextAnimation text={text1} colorIndex={colorIndex} />
          </div>
          <div className={'flex-1 flex-row flex  flex-nowrap justify-start '} style={{ opacity: opacityBlock2 }}>
            <div className='bg-black w-[1px] ml-[44px] flex max-lg:hidden  mr-4 '
                 style={{ height: `${opacityBlock2 * 100}%` }} />
            <TextAnimation text={text2} colorIndex={colorIndexList2} />
          </div>
          <div className={'flex-1 flex-row flex  flex-nowrap '} style={{ opacity: opacityBlock3 }}>
            <div className='bg-black w-[1px] ml-[44px] flex max-lg:hidden  mr-4'
                 style={{ height: `${opacityBlock3 * 100}%` }} />
            <TextAnimation text={text3} colorIndex={colorIndexList3} />
          </div>
        </div>
      </Parallax>
    </div>
  )
}

export default TextBlock