import React, { useEffect, useRef } from 'react'
import { InfoBlock, InfoCards, ReduceBlock, PhotoCarousel, FeedbackCarousel } from './components'
import ReactGA from 'react-ga4'

interface V2FooterProps {
  setMoney: (money: number) => void
  co2: number
}

const V2Footer: React.FC<V2FooterProps> = ({ setMoney, co2 }) => {
  const blocksRef = useRef({})
  const handleIntersection = (entries:IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        const blockName = entry.target.getAttribute('data-blockname')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (blocksRef.current[blockName]) return // if the block was already tracked, ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        blocksRef.current[blockName] = true

        ReactGA.event({
          category: 'Scrolling',
          action: 'Scrolled to block',
          label: blockName || '',
        })
        if (Object.keys(blocksRef.current).length === 5) {
          ReactGA.event({
            category: 'Scrolling',
            action: 'Scrolled through entire page',
          })
        }
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  })

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-blockname]')
    blocks.forEach((block) => observer.observe(block))
    return () => {
      blocks.forEach((block) => observer.unobserve(block))
    }
  }, [observer])

  return (
    <div
      className={'w-full flex-col flex gap-8 mt-[43px]'}
      style={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
      }}
    >
      <div data-blockname='InfoBlock'>
        <InfoBlock />
      </div>
      <div data-blockname={'InfoCards'}>
        <InfoCards />
      </div>
      <div data-blockname={'ReduceBlock'}>
        <ReduceBlock setMoney={(money) => setMoney(money)} co2={co2} />
      </div>
      <div data-blockname={'PhotoCarousel'}>
        <PhotoCarousel />
      </div>
      <div data-blockname={'FeedbackCarousel'}>
        <FeedbackCarousel />
      </div>
    </div>
  )
}

export default V2Footer
