export const formatDate = (date: Date, half?: boolean) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
  const year = date.getFullYear()

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')
  return half ? `${day}/${month}/${year}` : `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}


export function computePercentageReduction(initialEmissions: number, ratePerMT: number, donationAmount: number) {
  const reduction = donationAmount / ratePerMT
  return (reduction / initialEmissions) * 100
}