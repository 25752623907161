import React from 'react'
import { useNavigate } from 'react-router-dom'
import PyrposeCard from '../../assets/svg/pyrpose-card.svg'
import { CONSTANTS } from '../../../../constants/values'
import { Lightbulb, Plane, Tree } from '../../assets/svg'

interface CheckoutDetailsProps {
  amount: number;
  emission: number;
}

export const CheckoutDetails: React.FC<CheckoutDetailsProps> = ({
  amount,
  emission,
}) => {
  const navigate = useNavigate()

  const tonsReducedInitial: number = (amount ?? 0) / CONSTANTS.PRICE_PER_MT
  const tonsReduced = tonsReducedInitial > 0 ? tonsReducedInitial : 0
  const reducedPercentage = ((tonsReduced / (emission ?? 0)) * 100).toFixed(2)
  const cardsInfo = [
    {
      imgSrc: Lightbulb,
      name: `${(tonsReduced / 0.11).toFixed(2)} bulbs lit for year`,
    },
    {
      imgSrc: Tree,
      name: `${(tonsReduced / 0.01).toFixed(2)} trees planted`,
    },
    {
      imgSrc: Plane,
      name: `${(tonsReduced / 1.05).toFixed(2)} flights Paris – NY`,
    },
  ]

  return (
    <div className={'gap-4 flex max-w-[420px] flex-col  w-full '}>
      <div onClick={() => {
        navigate(-1)
      }}
           className={' text-[15px] w-full sm:hidden cursor-pointer items-center justify-start  flex flex-row gap-2 leading-[20px] text-start text-[#737373] font-Inter'}>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M12.6665 7.99999H3.33317M3.33317 7.99999L7.99984 3.33333M3.33317 7.99999L7.99984 12.6667'
                stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
        Back
      </div>
      <div className={'font-semibold font-Inter text-[20px] pb-1 leading-[28px] text-[#0F0F0F] text-start'}>
        Order summary
      </div>
      <div className={'flex flex-row gap-4 justify-between py-4 sm:border-b-[1px] sm:border-b-[#0000001F]'}>
        <img className={'w-[48px] h-[48px]'} src={PyrposeCard} alt={'pyrpose-card'} />
        <div>
          <div className={'font-[500] text-[17px] text-start font-Inter text-[#0F0F0F]'}>Pyrpose Climate Contribution</div>
          <div className={'text-[13px] text-start text-[#737373]'}>Pyrpose pays for the transfers fees</div>
        </div>
        <div>
          <div className={'font-[500] text-[17px] text-end font-Inter text-[#0F0F0F] '}>{amount.toFixed(2)}€</div>
          {/* <div className={'text-[13px] text-end  text-[#737373]'}>Bills monthly</div> */}
        </div>
      </div>
      <div className={'flex flex-row gap-4 justify-end py-4 items-center max-sm:hidden '}>
        <div>
          <div className={'text-[13px] text-end  text-[#737373]'}>Total</div>
          <div
            className={'font-[500] text-[20px] leading-[28px] text-end font-Inter text-[#0F0F0F] '}>{amount.toFixed(2)}€
          </div>
        </div>
      </div>
      <div className={'pb-4 max-sm:hidden'}>
        <div className={'text-[17px] leading-[24px] font-semibold pb-1 text-start'}>
          Offset {reducedPercentage}% of your carbon footprint
        </div>
        <div className={'text-[13px] leading-[20px] text-start'}>
          Your purchase is offsetting green house gas emissions in amount that equivalent of:
        </div>
      </div>
      <div className={'flex flex-nowrap flex-row gap-4 max-sm:hidden '}>
        {
          cardsInfo.map((card, index) => (
            <div key={index} className={'flex flex-col flex-1  bg-white rounded-[8px] p-4 items-start'}>
              <img className={'w-[32px] h-[32px]'} src={card.imgSrc} alt={card.name} />
              <div>
                <div
                  className={'text-[15px] font-semibold mt-4 text-[#525252] font-Inter text-start'}>{card.name}</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}