import styled from 'styled-components'

export const ContainerCard = styled.div`
  width: 100%;
  padding: 16px 16px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  max-width: 640px;
`