import { ClockCheck, Cloud, CreditCard, FaceSmile, Transparency } from '../../assets/svg'
import React, { useEffect, useRef, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import ReactGA from 'react-ga4'
import { Markup } from 'interweave'

interface OfferProps {
  imgSrc: string;
  name: string;
  Offer: string;
}

const OpportunitiesData = [
  {
    imgSrc: ClockCheck,
    name: 'Offset future carbon emissions',
    Offer: 'By funding climate innovators, you support the removal of greenhouse gases from the atmosphere and offset your carbon footprint. This allows you to effectively live carbon neutral.',
  },
  {
    imgSrc: Cloud,
    name: 'Take part in energy transition',
    Offer: 'Your funds support climate innovators who are working on the transition from fossil fuels to clean energy.',
  },
  {
    imgSrc: Transparency,
    name: 'Track Your Impact',
    Offer: 'Regular updates about how your funds are being allocated and used to support the climate projects.',
  },
  {
    imgSrc: CreditCard,
    name: 'Manage Your <br/> Contributions',
    Offer: 'You can easily stop or make changes to your contribution whenever you need to.',
  },
  {
    imgSrc: FaceSmile,
    name: 'Your Influence',
    Offer: 'You can share your achievements from your Pyrpose Profile /impact journey with your friends and network, expanding our collective impact!',
  },
]


const OfferCard: React.FC<OfferProps> = ({ imgSrc, name, Offer }) => {
  const cardRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          }
        })
      },
      {
        threshold: 0.01,
      },
    )

    if (cardRef.current) {
      observer.observe(cardRef.current)
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current)
      }
    }
  }, [])

  const animationClass = isVisible ? 'animate__fadeInUp animate__animated animate__fadeIn ' : ''

  return (
    <div
      ref={cardRef}
      className={`w-full lg:w-[24%] sm:w-[48%] py-6   rounded-xl flex-col justify-start items-start gap-4 inline-flex ${animationClass}`}
    >
      <img className='w-[48px] h-[48px] rounded-full object-cover' src={imgSrc} alt={name} />
      <Markup className='text-white text-[20px] font-semibold font-Inter text-start leading-normal' content={name} />
      <div
        className='text-[#FFFFFFB2] max-sm:w-[300px] text-[17px] font-normal font-Inter leading-normal text-start'>{Offer}</div>
    </div>
  )
}

const OfferBlock = () => {

  return (
    <Parallax className={'w-full bg-white flex items-center justify-center sm:px-12 px-4 overflow-hidden'}
              style={{
                backgroundImage: 'linear-gradient(0deg, #053214, #053214), radial-gradient(65.45% 60.32% at 50% 0%, rgba(15, 102, 30, 0.5) 0%, rgba(5, 50, 9, 0.5) 100%)',
              }}
    >
      <div
        className={'container w-full max-w-[1400px] py-[120px] max-sm:py-[80px] lg:py-[160px]  flex flex-row gap-4 justify-between items-center rounded-md '}>
        <div className='flex-col justify-start items-start gap-6 flex sm:mx-auto'>
          <div className='flex-col justify-start items-start gap-2 flex w-full'>
            <div className={'w-full flex flex-row justify-between items-center pr-2'}>
              <div
                className=' text-green-600 text-xs font-bold uppercase '
              >What do we offer
              </div>
            </div>
            <div
              className='text-start text-white xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px] font-semibold font-Inter'
            >What you get with Pyrpose
            </div>
          </div>
          <div
            className='w-full flex sm:flex-row flex-col max-lg:gap-4 gap-[1%] flex-wrap lg:flex-nowrap justify-between  '>
            {OpportunitiesData.map((Offer, index) => (
              <OfferCard key={index} {...Offer} />
            ))}
          </div>
          <a href={'/how-it-works'}
             onClick={() => {
               ReactGA.event({
                 category: 'RP_BTN_OFFER_LEARN_MORE',
                 action: 'RP_BTN_OFFER_LEARN_MORE',
                 label: 'RP_BTN_OFFER_LEARN_MORE',
               })
             }
             }
             className=' cursor-pointer px-5 py-3 bg-[#83F279] animate__fadeInUp animate__animated animate__fadeIn rounded-lg justify-start items-center gap-2 inline-flex'>
            <div className='text-center text-stone-950 text-[17px] font-medium font-Inter'>Learn
              more
            </div>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M4.16669 10H15.8334M15.8334 10L10 4.16667M15.8334 10L10 15.8333' stroke='#0F0F0F'
                    strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </a>
        </div>
      </div>
    </Parallax>
  )
}

export default OfferBlock