import React, { useEffect } from 'react'
import Modal from '../../../shared/Modal'
import { Typography } from '@mui/material'
import PrimaryButton from '../../../components/PrimaryButton'
import NumberSwitcher from './NumberSwitcher'

interface IModalProps {
  show: boolean
  onCancel: () => void
  // eslint-disable-next-line
  formik: any
  // eslint-disable-next-line
  question: any
  // eslint-disable-next-line
  foundValue: any
}

const InputModal: React.FC<IModalProps> = ({ show, onCancel, formik, question, foundValue }) => {
  const isObject =
    formik.values[question.id].value && typeof formik.values[question.id].value === 'object'
  const [intermediateValue, setIntermediateValue] = React.useState<{ [index: number]: number }>(
    {},
  )

  useEffect(() => {
    setIntermediateValue(isObject ? formik.values[question.id].value : {})
  }, [])

  // eslint-disable-next-line
  const text = question.values.find((v: any) => v?.type === 'input')
  const onPlus = (index: number) => {
    setIntermediateValue({
      ...intermediateValue,
      [index]: (intermediateValue[index] || 0) + 1,
    })
  }

  const onMinus = (index: number) => {
    if (intermediateValue[index] > 0) {
      setIntermediateValue({
        ...intermediateValue,
        [index]: intermediateValue[index] - 1,
      })
    }
  }

  const onSave = () => {
    formik.setFieldValue(question.id, {
      ...formik.values[question.id],
      value: intermediateValue,
      type: 'input',
    })
    onCancel()
  }
  return (
    <Modal show={show}>
      <form
        className={'flex flex-col items-start justify-between'}
        style={{ maxWidth: '417px' }}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div
          className={'flex items-center justify-between w-full'}
          style={{ marginBottom: '20px' }}
        >
          <div className={'share-title'}>✍️</div>
          <div
            className={'share-skip'}
            aria-label='close'
            onClick={() => {
              onCancel()
              setIntermediateValue(isObject ? formik.values[question.id].value : {})
            }}
          >
            cancel
          </div>
        </div>

        <div className={'share-title max-sm:!text-[20px] max-sm:!leading-[24px] text-center'}>
          {text?.title}
        </div>
        <div className={'share-message mb-4 max-sm:!text-[16px] max-sm:!leading-[20px] '}>
          {text?.subtitle}
        </div>
        {foundValue &&
          Array.isArray(foundValue.value) &&
          // eslint-disable-next-line
          foundValue.value.map((value: any, index: number) => (
            <NumberSwitcher
              value={Number(intermediateValue[index]) || 0}
              key={index + value.label}
              onPlus={() => onPlus(index)}
              onMinus={() => onMinus(index)}
              title={value.label}
              description={value.description}
            />
          ))}
        <PrimaryButton onClick={onSave} disabled={!intermediateValue}>
          <Typography
            style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
          >
            Save
          </Typography>
        </PrimaryButton>
      </form>
    </Modal>
  )
}

export default InputModal
