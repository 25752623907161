import React from 'react'
import Header from '../LandingV4/components/Header'
import Footer from '../LandingV4/components/Footer'
import { Footer as MobileFooter } from '../LandingV3/components'
import MainBackground from '../LandingV4/assets/img/main-background.webp'
import PrimaryButton from '../../components/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const IntroSurvey: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className={'min-w-full w-full max-w-full flex flex-col  bg-white'}>
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div className={'w-full h-full sm:px-4 sm:my-[36px] flex justify-center items-center'}>
        <div
          style={{
            backgroundImage: `url(${MainBackground})`,
            backgroundRepeat: 'none',
            backgroundSize: 'cover',
          }}
          className={'container w-full  h-full min-h-[80vh] sm:shadow-md   max-w-[1400px]  flex justify-center items-center sm:rounded-[12px] '}>
          <div className={'w-full h-full flex items-center flex-col gap-6 justify-center max-w-[640px]'}>
            <div
              className={'text-[24px] sm:text-[32px] sm:leading-[38px] px-4 leading-[28px] text-white font-semibold font-Inter  '}>
              Discover your carbon footprint and take actions to reduce it.
            </div>
            <svg width='48px' height='48px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M12 4L12 20M12 20L18 14M12 20L6 14' stroke='#FFFFFF' strokeWidth='1.5' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
            <PrimaryButton
              onClick={() => {
                ReactGA.event({
                  category: 'BTN_SURVEY_START',
                  action: 'BTN_SURVEY_START',
                  label: 'BTN_SURVEY_START',
                })
                navigate('/survey')
              }
              }
              className=' !bg-[#E3E3DD] !w-[80%] rounded-xl justify-center items-center gap-2 inline-flex'
            >
              <div
                className='text-center text-black sm:text-[20px] text-[17px] font-semibold font-Inter normal-case '>GET
                STARTED
              </div>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <Footer isMargin={false} />
      <div className={'md:hidden'}>
        <MobileFooter />
      </div>
    </div>
  )
}

export default IntroSurvey