import React from 'react'

interface SuccessEmissionLineProps {
  reduceValue: number
}

const SuccessEmissionLine: React.FC<SuccessEmissionLineProps> = ({ reduceValue }) => {
  const reduceValuePercent = Math.round(reduceValue * 100)

  const leftText = reduceValuePercent === 100 ? '100%' : `${reduceValuePercent}%`
  const rightText = reduceValuePercent < 100 ? `${100 - reduceValuePercent}%` : ''

  const TextLine = ({ text, color }: { text: string; color: string }) => {
    return (
      <div className={'flex flex-row gap-2 items-center'}>
        <div
          className={'w-[12px] rounded-full h-[12px]'}
          style={{
            backgroundColor: color,
          }}
        />

        <span className=' text-[14px]'>{text}</span>
      </div>
    )
  }

  return (
    <div className='w-full min-w-full py-8'>
      <div className='w-full min-w-full flex flex-row justify-center mb-2 items-center'>
        <span
          className='text-[#989898] text-[14px] font-bold text-center'
          style={{
            width: `${reduceValuePercent}%`,
          }}
        >
          {leftText}
        </span>
        {rightText && (
          <span
            className='text-[#989898] text-[14px] font-bold text-center'
            style={{
              width: `${100 - reduceValuePercent}%`,
            }}
          >
            {rightText}
          </span>
        )}
      </div>

      <div
        className={'w-full min-w-full rounded-[4px] h-[24px] relative'}
        style={{
          backgroundColor: reduceValue > 1 ? '#B0ECCF' : '#F1F8FA',
        }}
      >
        {reduceValue <= 1 && (
          <div
            className='h-[24px] absolute top-0 left-0 bg-[#96DBBA] rounded-[4px]'
            style={{
              width: `${reduceValuePercent}%`,
            }}
          />
        )}
        {reduceValue > 1 && (
          <div
            className='h-[24px] absolute top-0 left-0 bg-[#96DBBA] rounded-[4px]'
            style={{
              width: `${200 - reduceValuePercent}%`,
            }}
          />
        )}
      </div>
      <div className='w-full min-w-full flex flex-row justify-evenly mt-2'>
        <TextLine
          text={reduceValue > 1 ? 'Your annual emissions covered' : 'Your impact'}
          color='#96DBBA'
        />
        {reduceValue > 1 && <TextLine text='Extra impact ' color='#B0ECCF' />}
        {reduceValue < 1 && <TextLine text='Uncovered annual emission' color='#F1F8FA' />}
      </div>
    </div>
  )
}

export default SuccessEmissionLine
