export const treesToField = 206
export const averageCo2 ={
  netherlands: 8.59,
  world: 4.8,
}

export const CONSTANTS = {
  TREES_PER_FOOTBALL_FIELD: 206,
  EMISSIONS_PER_FOOTBALL_FIELD: 2.8,
  PRICE_PER_MT:  17.3859,
}