import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import PropTypes from 'prop-types'

interface AnimatedNumberProps {
  to: number;
  duration?: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ to, duration = 1600 }) => {
  const props = useSpring({
    from: { number: 0 },
    to: { number: to },
    config: { duration },
  });

  // eslint-disable-next-line react/prop-types
  return <animated.span>{props.number.to(n => n.toFixed(2))}</animated.span>;
};

AnimatedNumber.propTypes = {
  to: PropTypes.number.isRequired,
  duration: PropTypes.number,
};

export default AnimatedNumber;