import React from 'react'
import { CONSTANTS } from '../../../../../constants/values'
import ReactGA from 'react-ga4'

interface PayCardProps {
  title: string;
  price: string;
  value: number;
  popular: boolean;
  emission: number;
  setAmount: (value: number) => void;
  isOneTime: boolean;
  event: string;
}

const PayCard: React.FC<PayCardProps> = ({
  title,
  value,
  setAmount,
  popular,
  emission,
  isOneTime,
  event,
}) => {
  const percentage = () => {
    const tonsReduced: number = value / CONSTANTS.PRICE_PER_MT
    return ((tonsReduced / emission) * 100).toFixed(2)
  }

  return (
    <div
      className={` flex-1 bg-white rounded-2xl border-2  ${popular ? 'border-neutral-600' : 'border-white'} flex-col justify-start items-start inline-flex`}>
      <div
        className='self-stretch px-6 py-8 border-b border-stone-200 flex-col justify-start items-start gap-8 flex'>
        <div className='self-stretch  flex-col justify-start items-start gap-4 flex'>
          <div className='self-stretch justify-start items-start gap-4 inline-flex'>
            <div
              className='grow shrink basis-0 text-start text-neutral-500 text-xl font-semibold font-Inter leading-7'>{title}
            </div>
            {popular ?
              <div className='px-3 py-1 bg-[#CEF7C6] rounded-2xl justify-start items-center flex'>
                <div className='text-center text-[#1F6631] text-[15px] font-medium font-Inter leading-tight'>Popular
                </div>
              </div> : <div />
            }
          </div>
          <div className='self-stretch justify-start items-end gap-2 inline-flex'>
            <div><span
              className='text-stone-950 text-[56px] font-semibold font-Inter leading-[67.20px]'>{value}</span><span
              className='text-stone-950 text-[40px] font-semibold font-Inter leading-[48px]'>€</span></div>

            <div className='pb-2 justify-start items-start flex'>
              <div
                className='text-neutral-500 text-base font-medium font-Inter leading-normal'>{!isOneTime ? 'per month' : 'one time'}</div>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setAmount(value)
            ReactGA.event({
              category: 'RP_MENU_PRICING',
              action: 'RP_MENU_PRICING',
              label: 'RP_MENU_PRICING',
            })
            ReactGA.event({
              category: event,
              action: event,
              label: event,
            })
          }}
          className='self-stretch px-6 cursor-pointer py-3.5 bg-stone-950 rounded-xl justify-center items-center gap-2 inline-flex'>
          <div className='text-center text-white text-[17px] font-semibold font-Inter leading-tight'>Make an impact
          </div>
        </div>
      </div>
      <div className='self-stretch px-6 pt-8 pb-10 flex-col justify-start items-start gap-6 flex'>
        <div className='self-stretch flex-col justify-start items-start gap-[11px] flex'>
          <div className='self-stretch justify-start items-start gap-2 inline-flex'>
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8.1665 12L11.1665 15L17.1665 9M22.6665 12C22.6665 17.5228 18.1894 22 12.6665 22C7.14366 22 2.6665 17.5228 2.6665 12C2.6665 6.47715 7.14366 2 12.6665 2C18.1894 2 22.6665 6.47715 22.6665 12Z'
                stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <div className='grow shrink basis-0 text-start'><span
              className='text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>You are offsetting </span><span
              className='text-neutral-600 text-[17px] font-semibold font-Inter leading-normal'>{percentage()}%</span><span
              className='text-neutral-600 text-[17px] font-normal font-Inter leading-normal'> of your future carbon emissions.</span>
            </div>
          </div>
          <div className='self-stretch justify-start items-start gap-2 inline-flex'>
            <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8.1665 12L11.1665 15L17.1665 9M22.6665 12C22.6665 17.5228 18.1894 22 12.6665 22C7.14366 22 2.6665 17.5228 2.6665 12C2.6665 6.47715 7.14366 2 12.6665 2C18.1894 2 22.6665 6.47715 22.6665 12Z'
                stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <div
              className='grow shrink basis-0 text-start text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>You
              fund a portfolio of climate projects.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayCard