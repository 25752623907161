import React from 'react'
import DocumentsImage from '../../assets/svg/documents-background.svg'
import PyrposeLogo from '../../assets/svg/pyrpose-logo.svg'

const TermsPage: React.FC = () => {
  return (
    <div className={'w-full h-full flex '}
         style={{
           backgroundImage: `url(${DocumentsImage})`,
           backgroundRepeat: 'repeat',
           backgroundSize: 'cover',
         }}
    >
      <div className={'container mx-auto max-sm:px-4 flex flex-col items-center '}>
        <div
          className={'flex items-center justify-center ml-[-16px] sm:w-3/12 w-10/12 py-4 sm:py-8 max-w-[512px]'}>
          <img src={PyrposeLogo} alt={'logo'} />
        </div>
        <section className='section-5'>
          <p className='paragraph-6'>
            <span className='text-span-3'>
              <strong className={'font-bold text-[56px] leading-[60px] text-black'}>Terms of Use</strong><br/>
            </span>
            Effective as of April 20th, 2024.<br />
            <br />

            <ol className='text-left' type='A' style={{listStyleType: 'upper-alpha'}}>
              <li>
                GENERAL CONDITIONS
                <ol className='list-decimal' type='1'>
                  <li>These terms and conditions (hereinafter referred to as the “Terms and Conditions”) of Purpose
                    Group SA, Sentier de Sales 9, Geneve 1205, Switzerland (hereinafter referred to as “Pyrpose”) govern
                    the conditions of use and the rights and obligations of users (hereinafter referred as the
                    “User(s)”) of the website pyrpose.io and consequently all the subdomains of pyrpose.io (hereinafter
                    referred to as the “Website”).
                  </li>
                  <li>If the User does not agree to be bound by the Terms and Conditions set forth below, it shall
                    refrain from accessing the Website or any of its pages.
                  </li>
                </ol>
              </li>
              <li>
                WEBSITE
                <ol className='list-decimal' type='1'>
                  <li>The Website is a platform allowing Sponsors (as later defined) to enter into contact with potential acquirers or lenders interested by their projects.</li>
                  <li>By accessing and browsing the Website, the User acknowledges that it has read, understood and agreed to be bound by the Terms and Conditions. Any special agreements with respect to individual services or products of Pyrpose shall be complementary to these Terms and Conditions. In the event of any conflict, the provisions contained in any such special agreements shall prevail.</li>
                  <li>Access to the Website or to its services may be restricted at any time, including when necessary for security reasons or for implementation of technical measures. Users may not derive any claim from such restrictions.</li>
                </ol>
              </li>
              <li>
                USER ACCOUNT
                <ol className='list-decimal' type='1'>
                  <li>The use of the Website is subject to acceptance of the present Terms and Conditions and certain parts of the Website are subject to the prior registration of a user account (hereinafter referred to as “User Account”). Each User may register for one account only, which is personal and not transferable.</li>
                  <li>Registration is open to (i) natural persons of minimum 18 years of age with unrestricted capacity to act (pleine capacité civile) and (ii) duly incorporated legal entities. If a User Account is registered on behalf of a legal entity, the natural person creating such User Account guarantees that he/she has the power to represent the legal entity on behalf of which he/she is acting. Notwithstanding anything else in these Terms and Conditions, however, any natural or legal person who grants loans or credit professionally – as such activity is defined under the Swiss Federal Act on Consumer Credit of March 23, 2001 – is prohibited from using the Website, unless expressly authorized in writing by Pyrpose.</li>
                  <li>Access information of each User Account (User ID and password) is strictly confidential and shall be kept secret under the sole responsibility and at the sole risk of the User. Pyrpose may not be held liable in case of undue access to the User Account by a third party. The User shall immediately inform Pyrpose when a third party has unduly accessed its User Account or email, or if the User Account’s or email’s security has been compromised in any other way.</li>
                  <li>The User guarantees that any information provided by the User to Pyrpose via the Website is complete, accurate and truthful. The User is responsible of keeping this information up to date and to notify Pyrpose should a change occur.</li>
                  <li>The User shall keep any information or document made available to him/her/it by Pyrpose, including regarding a specific project, strictly confidential and shall not communicate such information or document to any third party except to his/her/its financial, legal or tax advisors. In case of a communication of information or document to his/her/its financial, legal or tax advisors, the User undertakes to inform said advisor about this confidentiality clause and to impose on such advisors the obligation to strictly comply with it. The User is liable in case of a breach of this confidentiality clause.</li>
                  <li>Once the account is created, the User must pass the accreditation process, in accordance with the requirements of Pyrpose’s partners, in order to access the offerings published on the Website. Accreditation may be rejected without indication of grounds. Consequently, if accreditation is rejected, the User will be blocked and will not have access to its User Account.</li>
                </ol>
              </li>
              <li>
                SPONSOR
                <ol className='list-decimal' type='1'>
                  <li>Sponsors are Users who register a User Account as a sponsor and who intend to submit climate projects on the Website (hereinafter referred to as a “Sponsor”).</li>
                  <li>After the registration, a Sponsor may submit a climate project for which it wishes to fundraise on the Website by providing a complete and detailed description of the climate project (hereinafter referred to as the “Project”).</li>
                  <li>Pyrpose does not act as the Sponsor of any Project. The Sponsor is always a third party that submitted a Project to Pyrpose. Once the Project is accepted by Pyrpose and activated, a fundraising campaign is launched on the Website (hereinafter referred to as the “Campaign”). Pyrpose may reject any Project without indication of grounds.</li>
                  <li>Sponsors are charged with a fee due to Pyrpose as specified in a specific agreement between the Sponsor and Pyrpose.</li>
                </ol>
              </li>
              <li>
                SUBSCRIPTION
                <ol className='list-decimal' type='1'>
                  <li>Subject to registration of a User Account and accreditation, the User may browse the approved Projects on the Website for a possible subscription and may request further information on the Website.</li>
                  <li>When proposing to subscribe to a Project, the User confirms having reviewed and understood the Project’s fundraising modalities and that it is aware of the risks related to such subscription. The User confirms having read and understood the risk factors mentioned in the Risk Factors Page and being aware that the list of risk factors is not exhaustive. In case Pyrpose considers that the User is eligible for a final subscription, Pyrpose will provide a subscription form and other documents in relation to the Project (the “Documents”) to the User.</li>
                  <li>If the User’s final subscription is approved by Pyrpose and its partners relevant to the Project (such as the depository bank and the financing bank), the investment is concluded between the User and the Sponsor. Pyrpose is not a party to such contractual relationship. As between the User and Pyrpose, the User shall assume solely all risks in relation to his/her/its subscription to any Project. Pyrpose provides no warranties regarding the success of the Campaign, neither towards the Sponsor nor towards any other User, nor regarding the allocation made by the Sponsor of the proposed subscription made by the User. In case the final allocation is below the subscription made (for instance due to a high demand on the Project), Pyrpose will inform the User accordingly before validating and accepting the subscription.</li>
                  <li>In case of an offline payment, the User (other than the Sponsor) must wire in time the contracted amount on the bank account provided according to the terms as agreed with the Sponsor. If such User fails to wire such amount within the defined period, he/she/it shall be liable to pay an indemnity to Pyrpose representing 5.00% of the sum due to the Sponsor. Information on the Risk Factors Page are expressly reserved.</li>
                  <li>Pyrpose is free to refuse a proposed subscription without indication of grounds and without any liability.</li>
                  <li>The User may only invest in Projects (i) for its own private investment or (ii) on behalf of a third party for its private investment based on a power of attorney or (iii) on behalf of a legal person for the purpose of the investment of the legal person concerned, based on a power of attorney. In any circumstance, the name of the ultimate beneficial owner and/or the controlling person (in case of a legal entity) within the meaning of Article 2a of the Swiss Money Laundering Act dated October 10, 1997 (AML) shall be disclosed in writing before the transfer of any amount by the User, upon demand from Pyrpose. The User is not authorized to subscribe to a Project for the purpose to subscribe for another User, nor for the intermediation to third parties without the prior written consent of Pyrpose.</li>
                  <li>If the minimal amount as mentioned in the Project has not been reached within the Campaign period, the subscribed amount by the User to the Project shall be redeemed to the User by the relevant entity (i.e. notary in the model of direct acquisition).</li>
                </ol>
              </li>
              <li>
                PROJECT
                <ol className='list-decimal' type='1'>
                  <li>Pyrpose may refuse, suspend or cancel a Project or Campaign, without prior notice and without indication of grounds and without liability whatsoever. Once the Project is approved by Pyrpose, the Project is published on the Website and Users may subscribe. The Project is published exclusively with the information provided by the Sponsor and third parties. Pyrpose does not guarantee the completeness or the accuracy of the information provided by the Sponsor and/or any other third-party data provider involved.</li>
                  <li>The User has to indicate the amount at which it is willing to subscribe, which has to be at least the minimum amount mentioned on the Website in relation to the Project.</li>
                  <li>The subscription made by a User constitutes an undertaking to enter into a relationship with the Sponsor, provided that the other conditions precedent (including success of the Campaign) are met and the User’s subscription is accepted.</li>
                  <li>A Campaign runs until the given end of the fundraising period chosen by Pyrpose.</li>
                  <li>Pyrpose gives no guarantee on the viability or the success of a Campaign and/or the Project.</li>
                  <li>Upon successful completion of the Campaign, the Sponsor and the User enter into a relationship according to the business model chosen by the Sponsor for its Project.</li>
                  <li>Upon successful completion of the Campaign, the User receives the payment information on the Website and all the necessary information regarding its subscription. The Sponsor receives the relevant information directly on the Website or by email. No amount will be transiting on Pyrpose’s bank account. Pyrpose shall not receive any deposit, nor shall emit any shares or any securities, and is not acting as a financial intermediary.</li>
                  <li>Pyrpose’s implication with the Project ends with the completion of the Campaign and the transfer of information to the Sponsor and to the investing User respectively. Pyrpose shall not be held liable of any loss or damages resulting from the relationship between the Sponsor and the User. The Sponsor can become insolvent, making it incapable of payment of the nominal (i.e. amount invested) and/or the interests. The investment is not guaranteed by any entity or collateral.</li>
                </ol>
              </li>
              <li>
                LIABILITY
                <ol className='list-decimal' type='1'>
                  <li>In no event, subject to mandatory applicable law, Pyrpose, its directors, officers, employees and auxiliary persons shall be liable, including in the case of negligence, for any damages, losses or liabilities including without limitation, direct or indirect, special, incidental, consequential damages, losses or liabilities, in connection with the use of the Website, account hacking, User identity spoofing or the User’s reliance on or use or inability to use the information and services on the Website, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if the User advises Pyrpose of the possibility of such damages, losses or expenses. Furthermore, Pyrpose disclaims all liability for any tampering with the User’s computer system by unauthorized parties.</li>
                  <li>Pyrpose does not warrant the accuracy, adequacy, completeness or timeliness of the information and services or the error-free use of the Website.</li>
                  <li>Pyrpose does not represent or warrant that the Website will be available or that it will meet the User’s requirements, that access will be uninterrupted, that there will be no delays, failures, errors or omissions or loss of transmitted information, that no viruses or other contaminating or destructive properties will be transmitted or that no damage will occur to the User’s computer system. The User shall be solely responsible for his/her/its adequate protection and back up of data and/or equipment and for undertaking reasonable and appropriate precautions to scan for computer viruses or other destructive properties.</li>
                </ol>
              </li>
              <li>
                NO ADVICE, NO RECOMMANDATION
                <ol className='list-decimal' type='1'>
                  <li>Neither any information nor any opinion expressed in this Website constitutes a solicitation or recommendation to enter into any transactions, or to conclude any legal act of any kind whatsoever.</li>
                  <li>The information contained on the Website cannot be interpreted as creating a mandate within the meaning of Article 394 of the Swiss Code of obligations, between Pyrpose and the User, or as a guarantee of a return on investment.</li>
                  <li>The content of information, graphics, reports and all the data contained on the Website is supplied for information only and is subject to change. Certain information on the Website has been obtained from sources outside of Pyrpose. While such information is believed to be reliable for the purposes used herein, no representations are made as to the accuracy or completeness thereof and Pyrpose takes no responsibility for such information.</li>
                  <li>Pyrpose is not responsible for the User’s investment decisions. Any investment is speculative in nature and can involve the risk of loss of one’s entire investment. Nothing contained on the Website should be construed as an investment recommendation. User should carefully evaluate his own financial position before making any investments. Pyrpose does not perform any verification of the suitability or appropriateness of the subscription made by the User with the experience, knowledge, financial situation and investment objectives of the User. Pyrpose recommends to the User to independently analyze the proposed Projects before considering a potential investment, and should consult with independent qualified sources of investment advice and other legal and tax professionals prior to considering a potential investment. Information on the Risk Factors Page are expressly reserved.</li>
                  <li>Pyrpose does not provide financial, tax or legal advice to the User. The User shall remain liable for its tax obligations (or any other legal obligations) associated with the investment he/she/it makes. Pyrpose shall not accept any liability in this respect. Pyrpose hereby recommends that the User consults a legal, financial and/or tax advisor within its tax jurisdiction.</li>
                  <li>The User shall proceed with its own verifications on the Project and associated risks and request advice from experts selected by him (such as notary, real estate expert, financial or tax advisor) at its own costs and liability. Information on the Risk Factors Page are expressly reserved.</li>
                </ol>
              </li>
              <li>
                LINKED SITES
                <ol className='list-decimal' type='1'>
                  <li>Links on the Website may be directed to third-party websites, which are not owned nor controlled by Pyrpose. Access to these third-party websites is at the User’s own risk and sole responsibility, subject to the terms and conditions of the relevant third-party providers.</li>
                </ol>
              </li>
              <li>
                INTELLECTUAL PROPERTY
                <ol className='list-decimal' type='1'>
                  <li>Some information available on the Website (including the logo, design, brand, software and others) are protected by copyright and are the property of Pyrpose. No information contained on this Website shall be construed as granting any license or right to use any image, trademark, service mark, or logo of Pyrpose. In no event shall the User acquire title to any software or material by downloading it, or otherwise copying it, from the Website. The User may save or print out a hard copy of individual pages and/or sections of the Website, provided that the User does not remove any copyright or other proprietary notices. All property rights belonging to Pyrpose shall remain with Pyrpose who is the sole owner. Property rights of third parties, including the Sponsor or the User, are expressly reserved.</li>
                </ol>
              </li>
              <li>
                MEANS OF COMMUNICATION
                <ol className='list-decimal' type='1'>
                  <li>All communication between the User and Pyrpose may be made by email to the email address associated with the User Account (or by registered mail, if necessary or required by law).</li>
                  <li>The User’s attention is hereby drawn to the considerable risks caused by using emails (hacking, viruses, message modification or transmission problems etc.) and the confidentiality problems these may bring about. The User alone shall bear the risks inherent to the use of email. Pyrpose may not be held liable for any loss caused as a result thereof. Moreover, Pyrpose shall not be liable for any damage resulting from the use of emails or any other means of transmission, particularly in the case of loss, delay or alteration.</li>
                </ol>
              </li>
              <li>
                CHANGES TO THE TERMS AND CONDITIONS
                <ol className='list-decimal' type='1'>
                  <li>Pyrpose reserves the right to amend the Terms and Conditions at any time.</li>
                  <li>The User will be notified by email to the email address associated with the User Account of any amendment made to the Terms and Conditions, and amendments shall only apply to Users’ the access to and use of the Website and operations conducted on the Website after such notification.</li>
                  <li>The most current version, published on the Website, applies between the User and Pyrpose.</li>
                </ol>
              </li>
              <li>
                PLACE OF JURISDICTION AND APPLICABLE LAW
                <ol className='list-decimal' type='1'>
                  <li>Use of the Website shall be subject to Swiss substantive law, which shall exclusively govern the interpretation, application, and effect of all the conditions of use set out above, without regard to its conflict of laws provision. The courts of Geneva, Switzerland shall have exclusive jurisdiction over all claims or disputes arising out of or in connection with the Site and its use.</li>
                </ol>
              </li>
            </ol>
          </p>
        </section>
      </div>
    </div>
  )
}

export default TermsPage;