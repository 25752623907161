import React from 'react'
import Modal from '../../shared/Modal'
import { useFormik } from 'formik'
import { Slider, TextField, Typography } from '@mui/material'
import PrimaryButton from '../PrimaryButton'
import Box from '@mui/material/Box'
import { postFeedback } from '../../utils/api'

interface IFeedbackSuccessModal {
  show: boolean
  onCancel: () => void
}

const FeedbackSliderQuestion = {
  min: 0,
  max: 10,
  step: 1,
  labelMin: 'Nope',
  labelMax: 'Yes',
  values: [
    {
      value: 0,
      label: 'Nope',
      description: '',
    },
    {
      value: 1,
      label: '1',
      description: '',
    },
    {
      value: 2,
      label: '2',
      description: '',
    },
    {
      value: 3,
      label: '3',
      description: '',
    },
    {
      value: 4,
      label: '4',
      description: '',
    },
    {
      value: 5,
      label: '5',
      description: '',
    },
    {
      value: 6,
      label: '6',
      description: '',
    },
    {
      value: 7,
      label: ' 7',
      description: '',
    },
    {
      value: 8,
      label: '8',
      description: '',
    },
    {
      value: 9,
      label: '9',
      description: '',
    },
  ],
}

const FeedbackSuccessModal: React.FC<IFeedbackSuccessModal> = ({ show, onCancel }) => {
  const deviceId = localStorage.getItem('machineId')
  const formik = useFormik({
    initialValues: {
      deviceId: deviceId,
      positive: '',
      negative: '',
      share: 0,
      feedback: '',
    },
    onSubmit: async (values) => {
      const data = {
        deviceId: values.deviceId,
        positive: values.positive,
        negative: values.negative,
        share: values.share.toString(),
        feedback: values.feedback,
      }
      try {
        await postFeedback(data)
        onCancel()
      } catch (e) {
        console.log(e)
        onCancel()
      }
    },
  })
  return (
    <Modal show={show}>
      <form onSubmit={formik.handleSubmit}>
        <div className={'modal-container md:min-w-[416px] max-h-[80vh] md:max-w-[416px] '}>
          <div className={'w-full modal-header mb-[24px]'}>
            <div className={'share-title'}>🔮</div>
            <div />
          </div>
          <div className={'modal-content'}>
            <div className={'share-title'} style={{ marginBottom: '48px' }}>
              How was your experience?
            </div>
            <div className={'w-full feedback-question mb-[12px]'}>
              What part of your experience did you enjoy?
            </div>
            <TextField
              variant='outlined'
              color={'primary'}
              placeholder={'Your thoughts'}
              className={'w-full px-4'}
              onChange={(e) => formik.setFieldValue('positive', e.target.value)}
              value={formik.values.positive}
              multiline
              rows={4}
              sx={{
                marginBottom: '16px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  backgroundColor: '#FFFFFF',
                },
              }}
            />
            <div className={'w-full feedback-question mb-[12px]'}>
              Are there any areas we should improve upon?
            </div>
            <TextField
              variant='outlined'
              color={'primary'}
              placeholder={'Your thoughts'}
              className={'w-full px-4'}
              onChange={(e) => formik.setFieldValue('negative', e.target.value)}
              value={formik.values.negative}
              multiline
              rows={4}
              sx={{
                marginBottom: '16px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  backgroundColor: '#FFFFFF',
                },
              }}
            />
            <div className={'w-full feedback-question mb-[12px]'}>
              Would you share with friends and family your experience on pyrpose?
            </div>
            <Box className='w-full flex flex-row justify-between items-center sm:pt-6 pt-2 pb-2 '>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
              >
                {FeedbackSliderQuestion.labelMin}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
              >
                {FeedbackSliderQuestion.labelMax}
              </Typography>
            </Box>
            <div className={'w-[90%] flex items-center justify-center ml-[6%]'}>
              <Slider
                style={{
                  padding: '0px 20px',
                  borderRadius: 4,
                  height: 40,
                }}
                className={'!w-full !h-[40px] !rounded-[4px] !'}
                aria-label='ios slider'
                step={FeedbackSliderQuestion.step}
                min={FeedbackSliderQuestion.min}
                max={FeedbackSliderQuestion.max}
                marks={FeedbackSliderQuestion.max / FeedbackSliderQuestion.step < 10}
                value={formik.values.share}
                onChange={(e, val) => {
                  formik.setFieldValue('share', val as number)
                }}
                sx={{
                  '& .MuiSlider-thumb': {
                    height: 40,
                    width: 40,
                    marginRight: '-25px',
                    marginLeft:
                      formik.values.share === FeedbackSliderQuestion.min
                        ? '-6px'
                        : window.innerWidth > 768
                        ? '0.7%'
                        : '0px',
                    borderRadius: '25px',
                    backgroundColor: '#78FD85',
                    boxShadow: '0px 0px 0px 0px rgba(120,253,133,0.3)',
                    border: '0px solid #78FD85',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                    overflow: 'hidden',
                    '&:focus, &:hover, &$active': {
                      boxShadow: '0px 0px 0px 0px #000 !important',
                    },
                  },
                  '& .MuiSlider-thumb:hover': {
                    boxShadow: '0px 0px 0px 0px #000 !important',
                  },
                  '& .MuiSlider-thumb:after': {
                    content: '""',
                    position: 'absolute',
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    borderWidth: '4px',
                    borderStyle: 'solid',
                    borderColor: '#78FD85',
                    backgroundColor: '#fff',
                  },
                  '& .MuiSlider-thumb:before': {
                    boxShadow: '0px 0px 0px 0px #000 !important',
                  },
                  '& .MuiSlider-track': {
                    height: 40,
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 25,
                    border: 'none',
                    left: '-5.6% !important',
                    paddingRight: 20 + '!important',
                    width: `${
                      ((formik.values.share - FeedbackSliderQuestion.min) /
                        (FeedbackSliderQuestion.max - FeedbackSliderQuestion.min)) *
                        100 +
                      6
                    }%  !important`,
                    backgroundColor: '#78FD85',
                  },
                  '& .MuiSlider-rail': {
                    border: 'none',
                    backgroundColor: '#000',
                    borderRadius: 25,
                    left: '-5%',
                    width: '108%',
                    opacity: 1,
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#fff',
                    height: 8,
                    width: 8,
                    borderRadius: '50%',
                    '&.MuiSlider-markActive': {
                      opacity: 1,
                      backgroundColor: '#78FD85',
                    },
                  },
                }}
              />
            </div>
            <div className={'w-full feedback-question mb-[12px]'}>
              Anything else you would like to share with us?
            </div>
            <TextField
              variant='outlined'
              color={'primary'}
              placeholder={'Your thoughts'}
              className={'w-full px-4'}
              onChange={(e) => formik.setFieldValue('feedback', e.target.value)}
              value={formik.values.feedback}
              multiline
              rows={4}
              sx={{
                marginBottom: '16px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  backgroundColor: '#FFFFFF',
                },
              }}
            />
            <PrimaryButton
              type={'submit'}
              className={'w-full px-4 mt-6 h-[61px]'}
              disabled={formik.isSubmitting}
            >
              <Typography
                style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
              >
                Submit
              </Typography>
            </PrimaryButton>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default FeedbackSuccessModal
