import React from 'react'
import HeroCarouselV2 from '../../assets/images/HeroCarouselV2.webp'
import ffNews from '../../assets/icons/ffnews.svg'
import finExtra from '../../assets/icons/finextra.svg'
import fintechGlobal from '../../assets/icons/fintechglobal@2x.png'
import startUpTicker from '../../assets/icons/startup-ticker.svg'
import tfounders from '../../assets/icons/tfounders@2x.png'
import ReactGA from 'react-ga4'
import DesktopBackground from '../../assets/images/HeroBackground.webp'
import SolarMain from '../../assets/images/SolarMain.webp'
import Trees from '../../assets/images/Trees.webp'
import Windmill from '../../assets/images/Windmill.webp'

const SocialMedia = [
  {
    name: 'ffNews',
    icon: ffNews,
    link: 'https://ffnews.com/newsarticle/sustainability/climate-finance-startup-pyrpose-opens-public-beta-enabling-consumers-to-directly-support-climate-innovators/',
  },
  {
    name: 'finExtra',
    icon: finExtra,
    link: 'https://www.finextra.com/pressarticle/98276/climate-finance-startup-pyrpose-launches-beta-platform',
  },
  {
    name: 'fintechGlobal',
    icon: fintechGlobal,
    link: 'https://fintech.global/2023/05/30/pyrpose-secures-1-1m-to-fuel-climate-fintech-innovation/',
  },
  {
    name: 'startUpTicker',
    icon: startUpTicker,
    link: 'https://www.startupticker.ch/en/news/climate-finance-platform-raises-usd-1-1-million',
  },
  {
    name: 'tfounders',
    icon: tfounders,
    link: 'https://www.founderstoday.news/pyrpose-secures-1-million-in-funding/',
  },
]
const HeroBlock = () => {
  return (
    <>
      <div className={'w-full md:bg-[#E3E3DD] md:hidden'}>
        <div className='w-full sm:w-[640px] sm:mx-auto  h-[554px] relative overflow-hidden'>
          <div className='w-full  h-[554px] left-0 top-0 absolute animate-scroll'>
            <div className='w-[3962px] h-[554px] left-0 top-0 absolute overflow-hidden'>
              <img alt='images' className='w-[1981px] h-[554px] left-0 top-0 absolute object-cover' src={HeroCarouselV2} />
              <img alt='images' className='w-[1981px] h-[554px] left-[2764px] top-0 absolute object-cover' src={HeroCarouselV2} />
            </div>
          </div>
          <div className='w-full h-[554px] left-0 top-0 absolute opacity-40 bg-black' />
          <div
            className={'left-[16px] right-[16px]  absolute flex-col md:container md:mx-auto  justify-between py-[44px] items-start gap-6 inline-flex h-full '}>
            <div className={'w-full flex flex-col gap-[42px]'}>
              <div className='w-full text-white text-start text-[34px] font-medium leading-[38px]'
                   style={{
                     fontFamily: 'IBM Plex Serif',
                   }}
              >Craft your sustainable tomorrow with pyrposeful finance
              </div>
              <div className='w-full text-white text-start text-[17px] font-normal leading-normal'>Imagine a world where
                climate change is controlled by ordinary people like us. Sounds Impossible?
              </div>
            </div>
            <div className={'w-full sm:w-8/12 sm:mx-auto flex flex-col  gap-[42px]'}>
              <div className=' justify-between items-center flex w-full md:max-w-[512px] md:self-center'>
                {SocialMedia.map((item, index) => (
                  <a href={item.link} target={'_blank'} key={`hero-carousel:${item.name}:${index}`}
                     className='w-[52px] h-[52px] relative rounded-lg' rel='noreferrer'>
                    <img alt={item.name} src={item.icon} className={'bg-cover'} />
                  </a>
                ))}
              </div>
              <div onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
                  action: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
                  label: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
                })
                window.open('/survey', '_self')
              }}
                className='h-14 w-full px-6 py-4 bg-black cursor-pointer bg-opacity-70 rounded-xl justify-center items-center gap-2 inline-flex'>
                <div
                  className="text-center text-white text-[17px] font-semibold font-Inter leading-tight"
                  >Calculate your carbon footprint
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'max-md:hidden w-full'}
           style={{
             backgroundImage: `url(${DesktopBackground})`,
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'cover',
             backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)',

           }}
      >
        <div className={'flex flex-row justify-between max-w-[1100px] gap-[16px] mx-auto flex-grow py-[40px] mx-4'}>
          <div
            className={' flex flex-col gap-[42px] justify-between items-start bg-black bg-opacity-20 rounded-xl backdrop-blur-md p-[44px] w-full max-w-[600px] min-w-[480px] '}>
            <div className=' text-white text-[48px] font-medium text-start leading-[52.80px]'
                 style={{
                   fontFamily: 'IBM Plex Serif',
                 }}
            >Craft your sustainable tomorrow with pyrposeful finance
            </div>
            <div className='w-full text-white text-start text-[17px] font-normal leading-normal'>Imagine a world where
              climate change is controlled by ordinary people like us. Sounds Impossible?
            </div>
            <div className=' justify-between items-center flex w-full md:max-w-[512px] md:self-center'>
              {SocialMedia.map((item, index) => (
                <a href={item.link} target={'_blank'} key={`hero-carousel:${item.name}:${index}`}
                   className='w-[52px] h-[52px] relative rounded-lg hover:bg-[#00000066]' rel='noreferrer'>
                  <img alt={item.name} src={item.icon} className={'bg-cover'} />
                </a>
              ))}
            </div>
            <div onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
                action: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
                label: 'HOME_BTN_PROJECTS_CLICKED_TOP_LINK',
              })
              window.open('/survey', '_self')
            }}
                 className='h-14 w-full cursor-pointer px-6 py-4 bg-black bg-opacity-70 rounded-xl justify-center items-center gap-2 inline-flex'>
              <div
                className="text-center text-white text-[17px] cursor-pointer font-semibold font-['Inter'] leading-tight"
              >Get Started
              </div>
            </div>
          </div>
          <div className={'flex flex-wrap flex-row justify-between w-[53%] gap-[4%] gap-y-4'}>
            <img src={SolarMain} alt='AfroMother' className={'w-full rounded-xl max-h-[405px] object-fill'} />
            <img src={Windmill} alt='SolarPanels' className={'w-[48%] rounded-xl max-h-[188px] object-fill'} />
            <img src={Trees} alt='AfroAmerican' className={'w-[48%] rounded-xl max-h-[188px] object-fill'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroBlock