import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import YouTubeIcon from '../../../Profile/shared/icons/YouTubeIcon'
import Modal from '../../../../shared/Modal'
import { Switch } from '@mui/material'
import Logo from '../../../LandingV3/icons/Logo'
import SocialIg from '../../../LandingV3/assets/icons/social-ig.svg'
import SocialLinkedIn from '../../../LandingV3/assets/icons/social-linkedin.svg'

interface FooterProps {
  isMargin?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  isMargin = true,
}) => {
  const initialCheckedValue = JSON.parse(localStorage.getItem('switchState') || 'false') || false
  const [tapCount, setTapCount] = useState(0)
  const [isModalVisible, setModalVisible] = useState(false)
  const [checked, setChecked] = useState(initialCheckedValue)

  const handleLogoTap = () => {
    const newTapCount = tapCount + 1
    setTapCount(newTapCount)

    if (newTapCount === 15) {
      setModalVisible(true)
      setTapCount(0)
    }
  }

  const switchHandler = () => {
    setChecked(!checked)
  }

  useEffect(() => {
    localStorage.setItem('switchState', JSON.stringify(checked))
  }, [checked])

  const closeModal = () => {
    setModalVisible(false)
  }
  return (
    <div
      className={`w-full bg-stone-950 flex-col  max-md:hidden backdrop-blur-[100px] flex sm:px-20  ${isMargin && 'sm:mt-40  mt-[40px]'} items-center justify-center `}>
      {isModalVisible &&
        <Modal
          show={isModalVisible}>
          <div className={'flex items-center justify-between w-full mb-[24px]'}>
            <div className={'share-title'}>🤲</div>
            <div className={'share-skip cursor-pointer'} aria-label='close' onClick={closeModal}>
              Close
            </div>
          </div>
          <div className={'flex items-center flex-row justify-between w-full mb-[24px]'}>
            <Switch checked={checked} onChange={switchHandler} />
            Activate login password
          </div>
        </Modal>
      }
      <div
        className={'flex flex-row justify-between items-start relative max-sm:px-4 pt-16 pb-4 max-w-[1400px] container  '}>
        {/* LOGO, ADDRESS */}
        <div className={'w-3/12 flex flex-col justify-between items-start pb-[40px]'}>
          <div className={'ml-[-16px]'} onClick={handleLogoTap}>
            <Logo />
          </div>
          <span className='text-white text-opacity-50 text-[13px] text-start font-semibold'>Purpose Group SA,  </span>
          <span className='text-white text-opacity-50 text-[13px] text-start font-normal'>Sentier de Sales 9, Geneve 1205, Switzerland</span>
          <span className='text-white text-opacity-50 text-[13px] text-start font-normal'>Registration No. CHE-189.742.405</span><br />
          <a href={'mailto:support@pyrpose.io'} className='text-white text-opacity-50 text-[13px] cursor-pointer text-start font-normal font-Inter leading-tight'>support@pyrpose.io</a>
        </div>
        {/* ABOUT */}
        <div
          className='flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div
            className='text-white text-opacity-30 text-[13px] font-semibold font-Inter uppercase leading-normal tracking-wide'>About
          </div>
          <div className='flex-col justify-start items-start gap-3 flex'>
            {/* <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                  action: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                  label: 'HOME_BTN_FOOTER_PROJECTS_CLICKED',
                })
                window.open('/projects', '_self')
              }}
              className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Projects</a> */}
            <a
                onClick={() => {
                  ReactGA.event({
                    category: 'HOME_BTN_FOOTER_MISSION_CLICKED',
                    action: 'HOME_BTN_FOOTER_MISSION_CLICKED',
                    label: 'HOME_BTN_FOOTER_MISSION_CLICKED',
                  })
                  window.open('/mission', '_self')
                }}
                className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Our
              mission</a>
            {/* <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_HOW_IT_WORKS_CLICKED',
                  action: 'HOME_BTN_FOOTER_HOW_IT_WORKS_CLICKED',
                  label: 'HOME_BTN_FOOTER_HOW_IT_WORKS_CLICKED',
                })
                window.open('/how-it-works', '_self')
              }}
              className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>How it works</a> */}
            <a href='/mission#team'
               onClick={(e) => {
                 e.preventDefault()
                 ReactGA.event({
                   category: 'HOME_BTN_FOOTER_TEAM_CLICKED',
                   action: 'HOME_BTN_FOOTER_TEAM_CLICKED',
                   label: 'HOME_BTN_FOOTER_TEAM_CLICKED',
                 })
                 window.location.href = '/mission#team'
               }}
               className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Team</a>
            <a
                onClick={() => {
                  ReactGA.event({
                    category: 'HOME_BTN_FOOTER_BLOG_CLICKED',
                    action: 'HOME_BTN_FOOTER_BLOG_CLICKED',
                    label: 'HOME_BTN_FOOTER_BLOG_CLICKED',
                  })
                  window.open('https://news.pyrpose.io/')
                }
                }
                className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>Blog</a>
            <a
                onClick={() => {
                  ReactGA.event({
                    category: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                    action: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                    label: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                  })
                  window.open('https://docs.pyrpose.io/', '_self')
                }}
                className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>FAQ</a>
          </div>
        </div>
        {/* Learn more */}
        {/* <div
          className='flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div
            className='text-white text-opacity-30 text-[13px]  font-semibold font-Inter uppercase leading-normal tracking-wide'>Learn more
          </div>
          <div className='flex-col justify-start items-start gap-3 flex'>
             <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                  action: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                  label: 'HOME_BTN_FOOTER_FAQ_CLICKED',
                })
                window.open('https://docs.pyrpose.io/', '_self')
              }}
              className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>FAQ</a>
             <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_CAREERS_CLICKED',
                  action: 'HOME_BTN_FOOTER_CAREERS_CLICKED',
                  label: 'HOME_BTN_FOOTER_CAREERS_CLICKED',
                })
                window.open('https://www.linkedin.com/company/81787480/admin/feed/posts/')
              }}
              className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>Careers</a>
             <a
              onClick={() => {
                ReactGA.event({
                  category: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                  action: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                  label: 'HOME_BTN_FOOTER_INVESTORS_CLICKED',
                })
                window.open('/investors')
              }}
              className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>For investors</a>
          </div>
        </div>*/}
        {/* Legal */}
        <div
          className='flex-col justify-start mt-4 items-start gap-3 inline-flex'>
          <div className='flex-col justify-start items-start gap-3 flex'>
            <div
              className='text-white text-opacity-30 text-[13px] font-semibold font-Inter uppercase leading-normal tracking-wide'>Legal
            </div>
            <div className='flex-col justify-start items-start gap-3 flex'>
              <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                      action: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                      label: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                    })
                    window.open('/risk-disclosure', '_blank')
                  }}
                  className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>
                Risk disclosure</a>
              <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                      action: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                      label: 'HOME_BTN_FOOTER_TERMS_OF_USE_CLICKED',
                    })
                    window.open('/terms-of-use', '_blank')
                  }}
                  className='text-white text-opacity-80 cursor-pointer text-[17px] font-normal font-Inter leading-normal'>
                Terms of use</a>
              <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                      action: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                      label: 'HOME_BTN_FOOTER_PRIVACY_POLICY_CLICKED',
                    })
                    window.open('/privacy-policy', '_blank')
                  }}
                  className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Privacy
                policy</a>
            </div>
          </div>
        </div>
        {/* ... */}
        <div
            className='flex-col justify-start mt-4 items-start gap-3 inline-flex '>
          <div
              className='text-black text-opacity-30 text-[13px] font-semibold font-Inter uppercase leading-normal tracking-wide'>Legal
          </div>
          {/* <a
            onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_LOG_IN_CLICKED',
                action: 'HOME_BTN_FOOTER_LOG_IN_CLICKED',
                label: 'HOME_BTN_FOOTER_LOG_IN_CLICKED',
              })
              window.open('/login', '_self')
            }}
            className='text-white text-opacity-80 text-[17px] cursor-pointer font-normal font-Inter leading-normal'>Log
            In</a>*/}
          <a href='mailto:support@pyrpose.io' onClick={() => {
            ReactGA.event({
              category: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
              action: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
              label: 'HOME_BTN_FOOTER_CONTACT_US_CLICKED',
            })
          }}
             className='text-white text-opacity-80 text-[17px] font-normal cursor-pointer font-Inter leading-normal'>Contact
            Us</a>
          <br />
          <div className='justify-start items-start gap-3 flex'>
            <div className={'cursor-pointer'} onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
                action: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
                label: 'HOME_BTN_FOOTER_INSTAGRAM_CLICKED',
              })
              window.open('https://www.instagram.com/pyrpose.io/', '_blank')
            }}>
              <img
                alt='ig'
                className='w-6 h-6'
                src={SocialIg}
              />
            </div>
            <a className={'cursor-pointer'} onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_LINKED_CLICKED',
                action: 'HOME_BTN_FOOTER_LINKED_CLICKED',
                label: 'HOME_BTN_FOOTER_LINKED_CLICKED',
              })
              window.open('https://www.linkedin.com/company/pyrpose/', '_blank')
            }}>
              <img
                alt='linkedin'
                className='w-6 h-6'
                src={SocialLinkedIn}
              />
            </a>
            <a onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
                action: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
                label: 'HOME_BTN_FOOTER_YOUTUBE_CLICKED',
              })
              window.open('https://www.youtube.com/@Pyrpose', '_blank')

            }} className={'cursor-pointer'} target={'_blank'} rel='noreferrer'>
              <YouTubeIcon fill={'#FFFFFF80'} />
            </a>
          </div>
        </div>
        <div />
      </div>
      {/* DISCLAIMERS */}
      <div
        className={'flex sm:flex-row justify-between w-full gap-10 sm:flex-nowrap flex-col  max-sm:px-4 pt-4 pb-16 max-w-[1400px]'}>
        <div
          className={'text-start flex-1 w-full container text-[14px] text-white text-opacity-80 max-sm:text-[10px] font-Inter  items-start'}>
          The Website is a platform allowing investors to enter into contact with potential lenders interested by such projects. Pyrpose does not provide financial, tax or legal advice. Neither information nor any opinion expressed in this Website constitutes a solicitation or recommendation to enter into any transactions, or to conclude any legal act of any kind whatsoever.
        </div>
        <div
          className={'text-start flex-1 w-full container text-[14px] text-white text-opacity-80 max-sm:text-[10px] font-Inter  items-start'}>
          By accessing and browsing the Website and its pages, the User acknowledges that it has read, understood and agreed to be bound by the Terms and Conditions and other legal provisions relating to the Website and its constituent elements in all respects.
        </div>
      </div>
    </div>
  )
}

export default Footer
