import React from 'react'
import Ed from '../assets/Ed.webp';
import Fleur from '../assets/Fleur.webp';
import Florian from '../assets/Florian.webp';
import Glisten from '../assets/Glisten.webp';
import Kevin from '../assets/Kevin.webp';
import Victoria from '../assets/Victoria.webp';
import Vivek from '../assets/Vivek.webp';
import ProfileIcon from '../icons/ProfileIcon'

interface ProfilePhotoProps {
  email: string
}

const emailToImage:Record<string, { src: string, alt: string }> = {
  'ed.stevens@scoot.app': { src:Ed, alt: 'Ed' },
  'fleur.heyns@gmail.com': { src:Fleur, alt: 'Fleur' },
  'florian@ryan-kay.com': { src:Florian, alt: 'Florian' },
  'glisten@assembly-7.com': { src:Glisten, alt: 'Glisten' },
  'kevin@pyrpose.io': { src:Kevin, alt: 'Kevin' },
  'yatsenko220@gmail.com': { src:Victoria, alt: 'Victoria' },
  'vivek@xmultiplied.com': { src:Vivek, alt: 'Vivek' },
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ email }) => {
  if (emailToImage.hasOwnProperty(email)) {
    return (
        <div className='w-16 h-16 sm:w-[96px] sm:h-[96px] border-[1px] border-white rounded-[40px] sm:rounded-full flex items-center justify-center'>
          <img src={emailToImage[email].src} alt={emailToImage[email].alt} className=' rounded-full object-cover' />
        </div>
    )
  } else {
    return (
      <div
        className='w-16 h-16 sm:w-[96px] sm:h-[96px] border-[1px] border-white bg-black rounded-[40px] sm:rounded-full flex items-center justify-center'>
        <ProfileIcon className={'w-[40px] h-[40px]'} />
      </div>
    )
  }
}

export default ProfilePhoto