import React from 'react'
import { Markup } from 'interweave'
import Graph from '../../../shared/assets/graph.png'

const MainChart = () => {

  return (
    <div className=' flex items-center bg-white justify-center gap-6 pt-[64px]  pb-[100px] w-full max-w-[100vw]'>
      <div className={'flex-col justify-start items-start px-4  flex max-w-[1200px]'}>
        <div className='flex-col justify-center items-center gap-2 flex w-full max-w-[100%]'>
          <div className={'w-full flex flex-row justify-center items-center'}>
            <div
              className=' text-green-600 text-[12px] font-bold uppercase'
            >my profile
            </div>
          </div>
          <div className={' flex-col flex items-center justify-center pb-[16px] w-full'}>
            <div className='max-sm:w-full text-start text-[28px] font-semibold font-Inter'>You are making a massive
              action!
            </div>
            <Markup className={'text-[17px] leading-[24px] max-w-[70%] mt-2 '}
                    content={'You are now part of an elite group of forward-thinking individuals whose commitment to a sustainable future is unmatched. By investing in Pyrpose, you\'ve demonstrated just how much you care about leaving a healthy world for generations to come.'} />
          </div>
        </div>
        <div className={'flex flex-row gap-4 mt-10 '}>
          <img src={Graph} alt={'graph'} width={'60%'} />
          <div className={'w-[40%] bg-[#F2F2EF] text-start py-8 px-10 flex flex-col justify-between rounded-[12px]'}>
            <div className={'flex flex-col'}>
              <div className={'text-[20px] font-Inter font-semibold pb-2 leading-[28px]'}>
                Investment Summary
              </div>
              <div className={'text-[15px] font-Inter leading-[20px] text-[#525252]'}>
                Your total investment on Pyrpose to date
              </div>
              <div className={'text-[36px] font-Inter font-semibold py-6 leading-[44px]'}>
                10,000 CHF
              </div>
              <div className={'h-[1px] w-full bg-[#00000008]'} />
              <div className={'flex flex-col w-full py-4 gap-2'}>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Interest rate
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    7,00%
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Interest income
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    700 CHF
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Future balance
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    12,100 CHF
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Next interest payout
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    18.03.2025
                  </div>
                </div>
              </div>
            </div>
            <a
              className={'bg-black rounded-[12px] w-full py-3 text-center text-[17px] font-semibold font-Inter text-white '}
              href={'#'}>
              Invest more
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainChart