import React, { memo, useState } from 'react'
import Modal from '../../shared/Modal'
import { TextField, Typography } from '@mui/material'
import PrimaryButton from '../../components/PrimaryButton'
import { IModalProps } from '../../shared/Inrefaces'
import ReactGA from 'react-ga4'
import { useFormik } from 'formik'
import { postUser } from '../../utils/api'

const CompareResultsModal = (props: IModalProps) => {
  const { show } = props
  const [skip, setSkip] = useState(false)

  const handleSkip = () => {
    ReactGA.event({
      category: 'CompareResultModal',
      action: 'Skip email CompareResultModal',
      label: 'Skip Email',
    })
    setSkip(true)
    props.onSkip(skip)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      ReactGA.event({
        category: 'CompareResultModal',
        action: 'Save email CompareResultModal',
        label: 'Save Email',
      })
      localStorage.setItem('email', values.email)
      postUser({
        email: values.email,
      }).then()
      handleSkip()
    },
  })

  return (
    <Modal show={show}>
      <div className={'flex flex-col max-w-[416px] items-start justify-between'}>
        <div className={'flex items-center justify-between w-full mb-[24px]'}>
          <div className={'share-title'}>🤲</div>
          <div className={'share-skip'} aria-label='close' onClick={handleSkip}>
            skip
          </div>
        </div>

        <div className={'share-title'}>Compare results with friends</div>
        <div className={'share-message mb-[46px]'}>
          To enable us to notify you of your friends&apos; results, please leave your email address
        </div>
        <form onSubmit={formik.handleSubmit} className={'w-full'}>
          <TextField
            variant='outlined'
            color={'primary'}
            placeholder={'Your email address'}
            className={'w-full px-4 '}
            value={formik.values.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
                backgroundColor: '#FFFFFF',
              },
            }}
          />
          <PrimaryButton type='submit' disabled={!formik.values.email} className={'!mt-[17.5px]'}>
            <Typography
              style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
            >
              Done
            </Typography>
          </PrimaryButton>
        </form>
      </div>
    </Modal>
  )
}

export default memo(CompareResultsModal)
