import React from 'react'

interface BlueListCardProps {
  title: string
  description: string
  icon: string
}
const BlueListCard: React.FC<BlueListCardProps> = ({
  title,
  description,
  icon
}) => {
  return (
    <div className='w-full px-4 max-sm:min-w-[90vw] h-full py-6 rounded-xl flex-1 flex-col justify-start bg-[#BCF1FB] items-start gap-4 inline-flex'>
      <div className='self-stretch flex-col justify-start items-start gap-5 flex'>
        <img src={icon} alt={title} className='w-12 h-12 relative' />
        <div className='self-stretch flex-col justify-start items-start gap-1 flex'>
          <div className="self-stretch text-cyan-700 text-xl font-semibold font-Inter text-start">{title}
          </div>
          <div
            className="self-stretch text-stone-950 text-opacity-70 text-[17px] font-normal font-Inter text-start">{description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlueListCard