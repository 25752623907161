import React, { useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import StripeCheckoutForm from '../../components/StripeCheckoutForm'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { Typography } from '@mui/material'
import config from '../../config'
import ReactGA from 'react-ga4'
import styled from 'styled-components'
import { computePercentageReduction } from '../../utils/helpers'
import { CONSTANTS } from '../../constants/values'

interface PaymentScreenProps {
  stripeOptions: StripeElementsOptions
  isOpened: boolean
  setIsOpened: (value: boolean) => void
  amount: number
  currency: string
  co2?: number
  offset?: boolean
}

interface ModalContainerProps {
  show: boolean
}

export const ModalPaymentContainer = styled.div<ModalContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.2);
  backdrop-filter: blur(14px);
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;
  display: flex;
  z-index: ${(props) => (props.show ? '9999' : '-1')};
  opacity: ${(props) => (props.show ? '1' : '0')};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: 'fadeIn 0.4s';
`

export const ModalPaymentContent = styled.div`
  padding: 32px;
  background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.07),
    0px -12px 20px rgba(0, 0, 0, 0.05), inset 0px 1px 0px #ffffff;
  border-radius: 32px;
  max-height: 95vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`


const PaymentScreen: React.FC<PaymentScreenProps> = ({
  stripeOptions,
  isOpened,
  setIsOpened,
  amount,
  currency,
  co2,
  offset = true,
}) => {

  if (!stripeOptions) {
    return <div>Error: Missing payment details</div>
  }
  const stripePromise = useMemo(() => loadStripe(`${config.stripePKPublic}`), [])
  const footprint = localStorage.getItem('footprint')
  const co2Emission = co2 ? co2 : footprint ? JSON.parse(footprint).myEmission : 0

  useEffect(() => {
    if (isOpened) {
      ReactGA.event({
        category: 'Payment',
        action: 'Payment Modal Opened',
        label: 'Payment Modal Opened',
      })
    } else {
      ReactGA.event({
        category: 'Payment',
        action: 'Payment Modal Closed',
        label: 'Payment Modal Closed',
      })
    }
  }, [isOpened])

  const reducePercentage = computePercentageReduction(co2Emission, CONSTANTS.PRICE_PER_MT, amount)
  const [isSubmitting, setIsSubmitting] = useState(false)


  return (
    <ModalPaymentContainer show={isOpened}>
      <ModalPaymentContent>
        <div className={'flex w-full align-center justify-between gap-16 pb-4'}>
          <Typography variant={'h4'} className={'!text-[20px] !font-bold'}>
            🛒 Your purchase
          </Typography>
          {isSubmitting ? <div /> :
            <div className={' cursor-pointer'} onClick={() => {
              setIsOpened(false)
            }}>
              <Typography variant={'h4'} className={'!text-[20px] !font-bold'}>
                Close
              </Typography>
            </div>
          }
        </div>
        {offset &&
          <Typography variant={'h4'}
                      className={'!text-[18px] !font-bold pb-4 items-center  flex justify-start text-start'}>
            <span className={'text-center'}>Offset {reducePercentage.toFixed(2)}% of your annual footprint</span>
          </Typography>
        }
        <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeCheckoutForm amount={amount} currency={currency} isSubmitting={isSubmitting}
                              setIsSubmitting={setIsSubmitting} isOpened={isOpened} />
        </Elements>
      </ModalPaymentContent>
    </ModalPaymentContainer>
  )
}

export default PaymentScreen
