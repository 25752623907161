import React from 'react'
import PyrposeLogo from '../../assets/svg/pyrpose-logo-big.svg'
import DocumentsImage from '../../assets/svg/documents-background.svg'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className={'w-full h-full flex '}
         style={{
           backgroundImage: `url(${DocumentsImage})`,
           backgroundRepeat: 'repeat',
           backgroundSize: 'cover',

         }}
    >
      <div className={'container mx-auto max-sm:px-4 flex flex-col items-center '}>
        <div className={'flex items-center justify-center ml-[-16px] sm:w-3/12 w-10/12 py-4 sm:py-8 max-w-[512px]'}>
          <img src={PyrposeLogo} alt={'logo'} />
        </div>
        <div className='section-5'>
          <p className='paragraph-5'>
            <span className='text-span-3'>
              <strong className={'font-bold text-[56px] leading-[60px] text-black'}>Privacy Policy</strong>
            </span>
            <br /><br />
            <p className='text-left'>Data Privacy & Protection</p>
            <ol type="1" className='text-left list-decimal'>
              <li>In order to provide the services it offers, Pyrpose processes the User’s personal data, which are provided by the User during the registration or from the use of the services offered by Pyrpose. The information that Pyrpose collects are the User’s personal information such as its name, physical address, email address, telephone number, bank details, identity documents (“Personal Data”).</li>
              <li>The User expressly authorizes that Pyrpose discloses his Personal Data, including his bank information details such as the IBAN and/or account number, to companies within the group to which Pyrpose belongs, partner companies or third parties’ data processing services in Switzerland and abroad in order for Pyrpose to be able to perform the services it offers. These service providers process the User’s personal data only at Pyrpose’s request based on our instructions and in compliance with Pyrpose’s privacy policy and for the purpose of the Website. These service providers are contractually obligated to maintain their confidentiality.</li>
              <li>Pyrpose undertakes to comply with the Swiss Federal Act on Data Protection namely for the processing of the User’s Personal Data.</li>
              <li>The User hereby expressly authorizes Pyrpose to delegate certain tasks (such as financial intermediation, IT services & infrastructure, server location & maintenance and other services) to companies outside Switzerland. This implies that data regarding the User may be communicated to such companies outside Switzerland. In the context of the delegation of certain tasks as mentioned above, the User is hereby informed that certain jurisdictions do not have legislation providing an appropriate level of protection within the meaning of the Swiss Federal Act on Data Protection. If the User’s Personal Data has to be shared with such companies, Pyrpose will request for the User’s consent.</li>
              <li>In order to optimize the User’s experience on the Website, cookies are used. These are small files that are saved on the web browser and that stores certain configurations and data in the web browser to exchange them with Pyrpose’s system. It is not used under any circumstances to store the User’s personal information.</li>
              <li>The User may refuse the use of cookies in the settings of the User’s web browser. The User’s attention is drawn to the fact that by disabling the use of cookies, the User may not have access to the Website’s functions in full.</li>
              <li>The Website uses Google Analytics, which is a service provided by Google Inc. located in the United States of America. Google Analytics is a tool which analyses the use of the Website. This tool may use cookies to collect anonymous information and generate reports in usage statistics of the Website without the User being personally identifiable by Google. The information produced by these cookies (including the User’s IP address) can be sent to, and stored on, Google’s servers in the United States of America. Google observes the data protection requirements of the Swiss-US Privacy Shield Frameworks and is registered with the US Department of Commerce’s Privacy Shield program. If the User does not wish Google Analytics to record the User’s visit data, the User can install a browser add-on to disable Google Analytics.</li>
              <li>The Website uses also other third parties like the above mentioned Google Analytics such as Mouseflow and others.</li>
              <li>The Website uses social plugins for various social network websites (hereinafter referred to as “Social network”), such as facebook.com (operated by Facebook, Inc.), and plus.google.com (operated by Google, Inc.) (hereinafter referred to as the “Plugin(s)”). When the User visits a websites that contain Plugins, the User’s browser will directly connect to the servers of the respective Social network. The content of the Plugin is directly transmitted to the User’s browser from each Social network and is integrated into the Website. By integrating the Plugin, the Social network receives the information that the User has visited the Website. If the User is logged on a Social network, it can associate the User’s visit to the account of the respective Social network. If the User interacts with the Plugin, the corresponding information form the User’s browser is directly sent to the respective Social network and stored there. For more information on the purpose and scope of data collection and further processing and use of data by the respective Social network as well as the User’s rights and ways to protect the User’s privacy, please see the privacy policy of the respective Social network.</li>
              <li>By using the Website, the User declares that he/she consents to the data processing by Google of the data collected regarding the User, in accordance with the aforementioned and Google’s privacy policy. The User acknowledge that Pyrpose does not assume any responsibility whatsoever for this process.</li>
              <li>The User may have access to its Personal Data any time by accessing its account on the Website and may proceed with any modification.</li>
              <li>These Privacy Policy may change from time to time. Pyrpose will notify the User of such modification.</li>
              <li>The User may contact Pyrpose at support@pyrpose.io shall it have any questions or concerns about Pyrpose’s services.</li>
            </ol>
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
