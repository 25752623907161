import React, { useEffect, useRef, useState } from 'react'
import { Copy, GlobeInHand, Smile, Lightning } from '../../assets/svg'
import { Parallax } from 'react-scroll-parallax'
import ReactGA from 'react-ga4'

interface OpportunityProps {
  imgSrc: string;
  name: string;
  Opportunity: string;
}

const OpportunitiesData = [
  {
    imgSrc: Lightning,
    name: 'Exclusive access',
    Opportunity: 'Unlock access to our curated selection of climate projects.',
  },
  {
    imgSrc: GlobeInHand,
    name: 'Diversify your Portfolio',
    Opportunity: 'Enhance your investment strategy by diversifying your portfolio with  sustainable opportunities.',
  },
  {
    imgSrc: Copy,
    name: 'Transparent Impact',
    Opportunity: 'Receive detailed reports showing your climate progress.',
  },
  {
    imgSrc: Smile,
    name: 'Accessibility',
    Opportunity: 'Join a community where individuals  can make a meaningful difference.',
  },
]


const OpportunityCard: React.FC<OpportunityProps> = ({ imgSrc, name, Opportunity }) => {
  const cardRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          }
        })
      },
      {
        threshold: 0.01,
      },
    )

    if (cardRef.current) {
      observer.observe(cardRef.current)
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current)
      }
    }
  }, [])

  const animationClass = isVisible ? 'animate__fadeInUp animate__animated animate__fadeIn ' : ''

  return (
    <div
      ref={cardRef}
      className={`w-full lg:w-[24%] sm:w-[48%] px-4 py-6 bg-white border-[1px] border-[#D9D9D2] rounded-xl flex-col justify-start items-start gap-4 inline-flex ${animationClass}`}
    >
      <img className='w-[48px] h-[48px] rounded-full object-cover' src={imgSrc} alt={name} />
      <div className='text-stone-950 text-[20px] font-semibold font-Inter text-start leading-normal'>{name}</div>
      <div
        className='text-neutral-600 max-sm:w-[300px] text-[17px] font-normal font-Inter leading-normal text-start'>{Opportunity}</div>
    </div>
  )
}

const Opportunities = () => {

  return (
    <Parallax className={'w-full bg-white flex items-center justify-center sm:px-12 px-4 overflow-hidden'}>
      <div
        className={'container w-full max-w-[1400px] py-[120px] max-sm:py-[80px] lg:py-[160px]  flex flex-row gap-4 justify-between items-center rounded-md '}>
        <div className='flex-col justify-start items-start gap-6 flex sm:mx-auto'>
          <div className='flex-col justify-start items-start gap-2 flex w-full'>
            <div className={'w-full flex flex-row justify-between items-center pr-2'}>
              <div
                className=' text-green-600 text-xs font-bold uppercase '
              >What do we offer
              </div>
            </div>
            <div
              className='text-start text-stone-950 xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px] font-semibold font-Inter'
            >Unlocking opportunities with Pyrpose
            </div>
          </div>
          <div
            className='w-full flex sm:flex-row flex-col max-lg:gap-4 gap-[1%] flex-wrap lg:flex-nowrap lg:justify-between  '>
            {OpportunitiesData.map((opportunity, index) => (
              <OpportunityCard key={index} {...opportunity} />
            ))}
          </div>
          <a href={'/how-it-works'} onClick={() => {
            ReactGA.event({
              category: 'HP_BTN_OFFER_LEARN',
              action: 'HP_BTN_OFFER_LEARN',
              label: 'HP_BTN_OFFER_LEARN',
            })
          }}
             className=' cursor-pointer px-5 py-3 bg-[#E5E5DF] animate__fadeInUp animate__animated animate__fadeIn rounded-lg justify-start items-center gap-2 inline-flex'>
            <div className='text-center text-stone-950 text-[17px] font-medium font-Inter'>Learn
              more
            </div>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M4.16669 10H15.8334M15.8334 10L10 4.16667M15.8334 10L10 15.8333' stroke='#0F0F0F'
                    strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </a>
        </div>
      </div>
    </Parallax>
  )
}

export default Opportunities
