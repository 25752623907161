import React from 'react'
import { mockProjects } from '../../../ProjectTemplate/helpers/mock'
import ProjectCard from '../ProjectCard'

const ProjectList = () => {
  return (
    <div className='max-w-[640px] px-4 w-full pt-6 pb-20 bg-white flex-col justify-start items-start gap-8 inline-flex'>
      <div className='self-stretch flex-col justify-start items-start gap-6 flex'>
        <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
          <div
            className="self-stretch text-start text-green-600 text-xs font-bold font-Inter uppercase leading-tight tracking-wide">Our
            projects
          </div>
          <div
            className="self-stretch text-start text-stone-950 text-[28px] font-semibold font-IBM leading-9">Support
            causes you care about
          </div>
        </div>
      </div>
      <div className='self-stretch flex-col  w-full justify-start items-start gap-8 flex'>
        {mockProjects.map((project, index) => (
            <ProjectCard
              key={index}
              title={project.data.title}
              description={project.data.description}
              image={project.data.mainPhoto}
              logo={project.data.logo}
              url={project.url}
            />
          ),
        )}
      </div>
    </div>
  )
}

export default ProjectList