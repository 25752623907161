import React from 'react'
import ArrowIcon from '../../../Profile/shared/icons/ArrowIcon'
import NewsCard from '../NewsCard'
import { Meet, Present, School, Teacher, Yellow } from '../../assets'

const NewsList = () => {
  const [activeNews, setActiveNews] = React.useState(0)
  const handleArrowClick = (direction: string) => {
    if (direction === 'left' && activeNews !== 0) {
      setActiveNews(activeNews - 1)
    } else if (direction === 'right' && activeNews !== newsList.length - 1) {
      setActiveNews(activeNews + 1)
    }
  }

  const newsList = [
    {
      image: School,
      description: 'Sam Duby, leader of the Super Africa Fund during the launch of the first  Smart Schools project.',
    },
    {
      image: Meet,
      description: 'Givewatts COO Joseph with the founder of Mama Norah Education Center women group located in Kenya.',
    },
    {
      image: Teacher,
      description: 'Jesper, leader of project “Solar Energy in Homes” giving children solar lamps for home use.',
    },
    {
      image: Yellow,
      description: 'Kenya, Homabay County transitioning from using the small kerosene lamps to solar energy.',
    },
    {
      image: Present,
      description: 'Jesper the leader of project “Solar Energy in homes” presents solar lamps to school teachers.',
    },
  ]
  return (
    <div className='flex-col justify-start items-start gap-6 flex w-full max-w-[100vw] py-[80px] sm:max-w-[608px] sm:mx-auto px-4'>
      <div className='flex-row justify-between items-end gap-2 flex w-full max-w-[100%]'>
        <div className={'flex flex-col justify-start'}>
        <div className={'w-full flex flex-row justify-between items-center'}>
          <div
            className=' text-green-600 text-xs font-bold uppercase'
          >Delivering result
          </div>
          <svg width='36' height='20' viewBox='0 0 36 20' fill='none' className={'sm:hidden'}
               xmlns='http://www.w3.org/2000/svg'>
            <path d='M3 9.99996H32.5M32.5 9.99996L26.6667 4.16663M32.5 9.99996L26.6667 15.8333' stroke='#37992E'
                  strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
        <div className={'sm:flex-row flex-col flex items-center justify-between w-full'}>
          <div className='max-sm:w-full text-start text-[28px] font-semibold sm:max-w-[80%]' style={{
            fontFamily: 'IBM Plex Serif',
          }}>How our Climate Projects making a change
          </div>
        </div>
        </div>
        <div className={'flex flex-row gap-4 justify-between items-center  max-sm:hidden'}>
          <div
            className={` rounded-full flex items-center p-4 border-[1px] border-black cursor-pointer ${activeNews === 0 && 'opacity-30'} `}
            onClick={() => handleArrowClick('left')}>
            <ArrowIcon className={'rotate-180'} />
          </div>
          <div
            className={` rounded-full flex items-center p-4 border-[1px]  border-black cursor-pointer  ${activeNews === (newsList.length-1) && 'opacity-30'} `}
            onClick={() => handleArrowClick('right')}>
            <ArrowIcon />
          </div>
        </div>
      </div>

      <div className={'flex flex-row gap-4 justify-between max-sm:hidden'}>
        <NewsCard imgSrc={newsList[activeNews].image}  description={newsList[activeNews].description} />
      </div>
      <div className='w-full max-w-[95vw] sm:hidden overflow-x-scroll'>
        <div className={'flex flex-row gap-4'}>
          {newsList.map((news, index) => (
            <NewsCard key={index} imgSrc={news.image} description={news.description} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewsList