import React, { FormEvent, useEffect, useState } from 'react'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { createPaymentCustomer, createPaymentIntent } from '../../../../../utils/api'
import ReactGA from 'react-ga4'
import PrimaryButton from '../../../../../components/PrimaryButton'
import { CircularProgress, Typography } from '@mui/material'

interface StripeCheckoutFormProps {
  amount: number
  currency: string
  isSubmitting: boolean
  setIsSubmitting: (value: boolean) => void
  isOpened?: boolean
  email: string
  name: string
}

const StripePayment: React.FC<StripeCheckoutFormProps> = ({
  amount,
  currency,
  isSubmitting,
  setIsSubmitting,
  isOpened,
  email,
  name,
}) => {
  const stripe: Stripe | null = useStripe()
  const elements: StripeElements | null = useElements()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const referenceId = localStorage.getItem('referenceId')

  const deviceId = localStorage.getItem('machineId') || ''
  useEffect(() => {
    if (isOpened) {
      setErrorMessage(null)
    }
  }, [isOpened])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!elements) {
      return
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setErrorMessage(submitError?.message || 'Error submitting payment')
      return
    }

    try {
      setIsSubmitting(true)
      const customer = await createPaymentCustomer({
        name,
        email,
        referenceId: referenceId || '',
      }).then()

      ReactGA.event({
        category: 'Payment',
        action: 'Payment Customer Created',
        label: 'Payment Customer Created',
      })

      const res = await createPaymentIntent({
        amount: amount,
        currency: currency,
        customerId: customer.id,
        deviceId: deviceId,
      })

      ReactGA.event({
        category: 'Payment',
        action: 'Payment Intent Created',
        label: 'Payment Intent Created',
      })

      const data: { clientSecret?: string, client_secret?: string } = await res
      const clientSecret: string = data?.client_secret || data?.clientSecret || ''

      if (!stripe) {
        console.log('Stripe hasn\'t been initialized yet.')
        setIsSubmitting(false)
        throw new Error('Stripe hasn\'t been initialized yet.')
      }

      const baseUrl = window.location.origin
      const { error } = await stripe?.confirmPayment({
        elements,
        clientSecret,
        redirect: 'always',
        confirmParams: {
          // eslint-disable-next-line camelcase
          return_url: baseUrl + '/payment-check',
          // eslint-disable-next-line camelcase
          payment_method_data: {
            // eslint-disable-next-line camelcase
            billing_details: {
              name,
              email,
            },
          },
        },
      })


      ReactGA.event({
        category: 'Payment',
        action: 'Payment Confirmed',
        label: 'Payment Confirmed',
      })

      if (error) {
        setIsSubmitting(false)
        ReactGA.event({
          category: 'Payment',
          action: 'Payment Error',
          label: 'Payment Error',
        })
        console.log('error', error)
        setErrorMessage(error.message || 'Error confirming payment')
      } else {
        setIsSubmitting(false)
        ReactGA.event({
          category: 'Payment',
          action: 'Payment Success',
          label: 'Payment Success',
        })
        console.log('success')
      }
    } catch (err) {
      setIsSubmitting(false)
      console.log('err', err)
      setErrorMessage('Error confirming payment')
    }
  }

  return (
    <form onSubmit={handleSubmit} className={''}>
      <PaymentElement />
      <div className={'w-full py-2'}>
        <div className={'gap-4 flex-col '}>
          {errorMessage &&
            <div className={'w-full flex flex-row justify-start align-center py-4'}>
              <div className={'text-red-500 text-[14px] font-[500] '}>Error:</div>
              <div className={'text-red-500 text-[14px] font-[500] '}>{errorMessage}</div>
            </div>
          }
        </div>
        <div className={'text-[#737373] text-start text-[14px] py-4 pb-4'}>
          By providing your card information, you allow Purpose Group SA to charge your card for future payments in
          accordance with their terms.
        </div>
        <PrimaryButton type='submit' className={'!bg-[#37992E]'} disabled={!stripe || !elements}>
          {isSubmitting ? <CircularProgress size={24} /> :
            <Typography variant={'body2'} className={'text-[#FFFFFF] capitalize !text-[20px]'}>
              Complete
            </Typography>}
        </PrimaryButton>
      </div>
    </form>
  )
}

export default StripePayment