import * as React from 'react'
import { SVGProps } from 'react'

const YouTubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    {...props}
  >
    <path
      fill={props.fill || '#525252'}
      d='M23.76 6.2s-.233-1.655-.955-2.381c-.914-.956-1.936-.961-2.405-1.017-3.356-.244-8.395-.244-8.395-.244h-.01s-5.039 0-8.395.244c-.469.056-1.49.06-2.405 1.017C.473 4.545.244 6.2.244 6.2S0 8.145 0 10.086v3.819c0 1.94.24 3.886.24 3.886s.233 1.654.95 2.38c.915.957 2.115.924 2.65 1.027 1.92.183 8.16.24 8.16.24s5.044-.01 8.4-.249c.469-.056 1.49-.06 2.405-1.017.722-.727.956-2.381.956-2.381S24 15.85 24 13.905v-3.819c0-1.94-.24-3.886-.24-3.886ZM9.52 15.112V8.367l6.483 3.385-6.483 3.36Z'
    />
  </svg>
)
export default YouTubeIcon
