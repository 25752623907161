  import React from 'react'
import { Header, HeroFooter, PayBlock } from '../LandingV3/components'
import ProjectList from './components/ProjectList'
import Footer from '../LandingV3/components/Footer'
import NewsList from './components/NewsList'
import ProjectsBg from './assets/projects-bg.webp'
import Rectangle from './assets/rectangle.svg'
import LogoIntro from '../../assets/svg/logo_intro.svg'

const Projects = () => {
  return (
    <div className={'w-full flex-col h-full bg-white flex-1 flex '}>
      <div data-blockname='Header'><Header /></div>
      <div className={'w-full relative h-full bg-white'} style={{
        backgroundImage: `url(${ProjectsBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className={' flex-col sm:gap-[64px] gap-[40px] sm:pt-[110px] pt-16 sm:pb-[130px] pb-[80px]  flex items-center justify-center '}>
          <div
            className=" text-center text-white sm:pt-[20px] max-w-[900px] max-sm:px-[40px] sm:text-[64px] text-[40px] font-medium font-IBM sm:leading-[70px]">Planet-saving,<br/>
            life-changing projects
          </div>
          <img src={LogoIntro} alt={'LogoIntro'} className={'w-[73px] sm:w-[120px] sm:h-[170px] h-[102px]'}/>
        </div>
        <img src={Rectangle} alt='Rectangle' className={'absolute bottom-[-2px] w-full object-cover'} />
      </div>
      <div data-blockname='ProjectList'>
        <ProjectList />
      </div>
      <div data-blockname='PayBlock' className={'bg-[#E3E3DD] py-[48px]'}><PayBlock project={true} /></div>
      <div data-blockname='NewsList'><NewsList /></div>
      <div data-blockname='HeroFooter'><HeroFooter
        description={'Commit to a sustainable future by directing your funds towards climate companies, which not only reduce future pollution but also have a positive impact on the lives of those most in need.'} />
      </div>
      <div data-blockname='Footer'><Footer /></div>
    </div>
  )
}

export default Projects