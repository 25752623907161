import React, { useEffect, useMemo, useState } from 'react'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ReactGA from 'react-ga4'
import config from '../../../../config'
import StripePayment from './components/StripePayment'

interface PaymentFormProps {
  stripeOptions: StripeElementsOptions
  amount: number
  currency: string
  co2?: number
  offset?: boolean
  email: string
  name: string
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  stripeOptions,
  amount,
  currency,
  email,
  name,
}) => {

  if (!stripeOptions) {
    return <div>Error: Missing payment details</div>
  }

  const stripePromise = useMemo(() => loadStripe(`${config.stripePKPublic}`), [])

  useEffect(() => {
    ReactGA.event({
      category: 'Payment',
      action: 'Payment Form Opened',
      label: 'Payment Form Opened',
    })
  }, [])

  const [isSubmitting, setIsSubmitting] = useState(false)


  return (
    <div className={'sm:max-w-[374px]  w-full flex  flex-col'}>
      <div className={'font-semibold font-Inter text-[20px] pb-5 leading-[28px] text-[#0F0F0F] text-start'}>
        Payment
      </div>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <StripePayment email={email} name={name} amount={amount} currency={currency} isSubmitting={isSubmitting}
                       setIsSubmitting={setIsSubmitting} isOpened={true} />
      </Elements>
      <div className={' justify-center w-full flex-row flex items-center gap-2 pt-4'}>
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fillRule='evenodd' clipRule='evenodd'
                d='M8.30949 0.72562C8.43596 0.707345 8.5644 0.707345 8.69087 0.72562C8.83662 0.746682 8.97224 0.797918 9.07993 0.838601L9.10879 0.849477L12.7704 2.22249C13.1823 2.37626 13.544 2.5113 13.8225 2.75678C14.0662 2.97152 14.2539 3.24239 14.3694 3.54595C14.5015 3.89296 14.5009 4.27904 14.5003 4.71866L14.5002 7.99991C14.5002 9.88433 13.4753 11.4554 12.3675 12.6095C11.2522 13.7714 9.97618 14.592 9.30144 14.9857L9.27412 15.0017C9.15099 15.074 8.99117 15.1678 8.77975 15.2132C8.60678 15.2503 8.39357 15.2503 8.2206 15.2132C8.00919 15.1678 7.84937 15.074 7.72624 15.0017L7.69891 14.9857C7.02417 14.592 5.74815 13.7714 4.63288 12.6095C3.52504 11.4554 2.50018 9.88433 2.50018 7.99991L2.50009 4.71867C2.49945 4.27904 2.49889 3.89296 2.63094 3.54595C2.74645 3.24239 2.93416 2.97152 3.17783 2.75678C3.45638 2.5113 3.81808 2.37626 4.22994 2.22249L7.89156 0.849477L7.92042 0.838602C8.02811 0.797918 8.16373 0.746682 8.30949 0.72562ZM11.3049 6.4714C11.5653 6.21105 11.5653 5.78894 11.3049 5.5286C11.0446 5.26825 10.6224 5.26825 10.3621 5.5286L7.8335 8.05719L6.97157 7.19526C6.71122 6.93491 6.28911 6.93491 6.02876 7.19526C5.76841 7.45561 5.76841 7.87772 6.02876 8.13807L7.36209 9.4714C7.62244 9.73175 8.04455 9.73175 8.3049 9.4714L11.3049 6.4714Z'
                fill='#999999' />
        </svg>
        <div className={'text-[#737373] text-[14px]  text-center'}>
          Payments processed securely by Stripe
        </div>
      </div>
    </div>
  )
}

export default PaymentForm