import { SVGProps, memo } from 'react'

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={40}
    height={41}
    fill='none'
    {...props}
  >
    <path
      stroke={props?.stroke || '#fff'}
      strokeLinecap='round'
      strokeWidth={2}
      d='M32.5 20.5H16.25M32.5 28H11.25M32.5 13h-25'
    />
  </svg>
)
const Memo = memo(MenuIcon)
export default Memo
