import React from 'react'
import { NumberType } from '../../helpers/types'

interface NumbersBlockProps {
  numbers: NumberType[]
}




const NumberCard:React.FC<NumberType> = ({
  value,
  label,
  metrics
}) => {
  return (
    <div className="w-full sm:w-[48%] flex-col justify-start items-center inline-flex">
      <div className="justify-center items-baseline gap-0.5 inline-flex pb-[12px]">
        <div className="text-center text-green-600 text-[48px] font-semibold font-['Inter'] leading-[48px]">{value}</div>
        <div className="text-center text-green-600 text-[28px] font-bold font-['Inter'] leading-7">{metrics}</div>
      </div>
      <div className=" text-center text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">{label}</div>
      <div className="w-16 h-[1px] sm:hidden mt-[32px] bg-[#36992D] bg-opacity-50" />
    </div>
  )
}

const NumbersBlock:React.FC<NumbersBlockProps> = ({
  numbers
}) => {
  return (
    <div className={'bg-[#E6F3E5] p-16 sm:p-0 sm:py-[80px] flex items-center justify-center'}>
      <div className={'flex flex-col sm:flex-row sm:flex-wrap gap-y-[32px] sm:gap-y-[40px] items-center justify-between max-w-[608px]'}>
        {numbers?.map((number, index) => (
          <NumberCard key={index} {...number} />
        ))}
      </div>
    </div>
  )
}

export default NumbersBlock