import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import HowItWorksImage from '../../../LandingV4/assets/img/how-it-works.webp'
import EuroLine from '../../assets/svg/swipe-mobile.svg'
import LogoIntro from '../../../../assets/svg/logo_intro.svg'
import LogoTextWhite from '../../../../assets/svg/logo-text-white.svg'
import EuroLineVertical from '../../assets/svg/swipe.svg'
import ReactGA from 'react-ga4'


const HeaderList: React.FC = () => {
  return (<div
    className='max-sm:max-w-[608px] bg-zinc-900 sm:h-[244px] mx-auto self-center bg-opacity-20 rounded-xl border border-white backdrop-blur-[80px] flex-col sm:flex-row justify-start items-center inline-flex'>
    <div className='self-stretch pl-8 sm:pr-4 pr-8 py-8 rounded-xl flex-col justify-start items-center gap-4 flex'>
      <div className='self-stretch sm:text-start text-white text-2xl sm:h-[64px] font-semibold font-Inter'>Climate
        Backers
      </div>
      <div
        className='self-stretch sm:text-start text-white text-opacity-70 text-[17px] font-normal font-Inter'>You back
        climate innovators, providing them with capital to supply clean, affordable and stable electricity where it is
        needed the most.
      </div>
    </div>
    <img src={EuroLine} alt={'euroLine'} className={'w-full sm:hidden object-center object-contain'} />
    <img src={EuroLineVertical} alt={'euroLineVertical'}
         className={' h-full max-sm:hidden w-[58px] object-center object-contain'} />
    <div className='self-stretch p-8 rounded-xl flex-col justify-start items-center gap-4 flex'>
      <div className={'sm:flex-row flex-col flex items-center sm:justify-start justify-center gap-2 w-full '}>
        <img src={LogoIntro} alt={'LogoIntro'} className={'sm:w-[42px] w-[52px]'} />
        <img src={LogoTextWhite} alt={'LogoText'} className={'w-[110px]'} />
      </div>
      <div
        className='self-stretch sm:text-start text-white text-opacity-70 text-[17px] font-normal font-Inter '>You
        receive
        updates on your contribution and can follow the impact you make through you Pyrpose Profile.
      </div>
    </div>
    <img src={EuroLine} alt={'euroLine'} className={'w-full sm:hidden object-center object-contain'} />
    <img src={EuroLineVertical} alt={'euroLineVertical'}
         className={' h-full max-sm:hidden w-[58px] object-center object-contain'} />
    <div className='self-stretch sm:pl-4 pl-8 pr-8 py-8 rounded-xl flex-col justify-start items-center gap-6 flex'>
      <div className='self-stretch sm:text-start text-white text-2xl sm:h-[64px] font-semibold font-Inter'>Climate
        Innovators
      </div>
      <div
        className='self-stretch sm:text-start text-white text-opacity-70 text-[17px] font-normal font-Inter '>Climate
        companies use the capital to fund their business and provide products and services to customers — different
        facilities and families.
      </div>
    </div>
  </div>)
}

const HowItWorks: React.FC = () => {

  return (
    <ParallaxBanner
      layers={[
        { image: HowItWorksImage, expanded: false, speed: -40 },
      ]}
    >
      <div className={'flex justify-center items-center w-full sm:px-12'}>
        <div
          className={'container w-full flex flex-row gap-4 max-w-[1400px] justify-between items-center rounded-md py-[120px] max-sm:py-[80px] lg:py-[160px] max-md:px-4'}>
          <div className='flex-col justify-start items-start gap-12 flex sm:mx-auto'>
            <div className='flex-col justify-start items-start gap-2 flex w-full z-10'>
              <div className={'w-full flex flex-row justify-between items-center pr-2'}>
                <div
                  className=' text-green-600 text-xs font-bold uppercase '
                >How it works
                </div>
              </div>
              <div
                className='text-start text-white xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px]   font-semibold  font-Inter'
              >A simple and efficient way to tackle climate change
              </div>
              <div className='text-white text-[17px] leading-[23px] mt-4 text-start font-Inter sm:w-1/2  w-full'>You can
                now contribute to the massive energy transition from fossil fuels to clean and renewable energy by
                actively supporting climate innovators.
              </div>
              <a href={'/how-it-works'}
                 onClick={() => {
                   ReactGA.event({
                     category: 'RP_BTN_HIW_LEARN',
                     action: 'RP_BTN_HIW_LEARN',
                     label: 'RP_BTN_HIW_LEARN',
                   })
                 }
                 }
                 className=' cursor-pointer rounded-sm justify-start mt-3 flex-row items-center gap-1.5 inline-flex'>
                <div className='text-white text-[17px] font-medium font-Inter underline'>Learn more</div>
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.16675 10H15.8334M15.8334 10L10.0001 4.16669M15.8334 10L10.0001 15.8334' stroke='white'
                        strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </a>
            </div>
            <div
              className='w-full flex flex-col max-lg:gap-4 lg:flex-row gap-[1%] flex-wrap lg:flex-nowrap  lg:justify-between '>
              <HeaderList />
            </div>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  )
}

export default HowItWorks