import React, { memo } from 'react'
import { useQuery } from 'react-query'
import { getReferenceTransaction } from '../../../utils/api'
import LoaderPulse from '../../../components/LoaderPulse'
import { useNavigate } from 'react-router-dom'
import MainBlock from './components/MainBlock'
import LogoWhite from '../../../assets/svg/logo-white.svg'
import HeroFooterBackground from '../../LandingV3/assets/images/HeroBackground.webp'
import { HowWeWork, PayBlock, ProjectList } from '../../LandingV3/components'
import ImpactSection from '../../LandingV3/components/ImpactBlock'
import Footer from '../../LandingV3/components/Footer'
import SurveyBlock from './components/SurveyBlock'
import ProfilePhoto from '../shared/components/ProfilePhoto'

const PublicProfile: React.FC = () => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()

  const referenceId = queryParams.get('referenceId') || localStorage.getItem('referenceId') || ''


  const {
    data,
    isError,
    isLoading,
  } = useQuery(['referenceProfile', referenceId], () => getReferenceTransaction(referenceId || ''), {
    enabled: !!referenceId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false,
  })

  if (isLoading) {
    return <LoaderPulse />
  }

  if (isError) {
    navigate('/')
  }


  return (
    <div className={'w-full h-full flex-col bg-[#E3E3DD] min-h-screen flex justify-center'}>
      <div
        className='w-full h-[65px] sm:h-[80px] sm:hidden max-sm:px-4 py-3 bg-black backdrop-blur-[100px] flex-col justify-start sm:justify-center items-center sm:items-start sm:pl-[68px] gap-2 inline-flex'>
        <img src={LogoWhite} alt='logo' className={'max-sm:pb-[-20px]'} onClick={() => {
          navigate('/')
        }} />
      </div>
      <div
        className=' w-full bg-center sm:min-h-[220px] relative bg-cover flex flex-row justify-start px-4 items-center text-white '
        style={{
          backgroundImage: `url(${HeroFooterBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)',
        }}>
        <div
          className='w-full h-[65px] sm:h-[80px] z-[2] max-sm:hidden sm:absolute sm:top-0 sm:left-0 sm:right-0 max-sm:px-4 py-3 bg-[#000000CC] max-sm:backdrop-blur-[100px] flex-col justify-start sm:justify-center items-center sm:items-start sm:pl-[68px] gap-2 inline-flex'>
          <img src={LogoWhite} alt='logo' className={'max-sm:pb-[-20px]'} onClick={() => {
            navigate('/')
          }} />
        </div>
        <div
          className={'flex flex-row z-[10] sm:max-w-[640px] sm:w-full sm:mx-auto  sm:justify-center items-center gap-4 sm:pt-[66px] pt-[32px] sm:pb-[24px] pb-[32px]'}>
          <div
            className={'flex flex-row items-center gap-4 sm:absolute sm:flex-col sm:bottom-[-89px] max-sm:pl-2 sm:mx-auto sm:self-center'}>
            <ProfilePhoto email={data?.email} />
            <div
              className={'text-white sm:text-black max-sm:hidden text-start text-[34px] sm:font-semibold sm:text-[20px] sm:font-["Inter"] font-[IBM] font-medium max-sm:leading-[38px]'}
            >
              {data?.name}
            </div>
            <div className={'flex flex-col text-start sm:hidden '}><span
              className="text-white text-xl font-semibold font-['Inter'] leading-snug"> {data?.name.split(' ')[0]} </span><span
              className="text-white text-[17px] font-normal font-['Inter'] leading-snug">{data?.name.split(' ')[1]}</span></div>
          </div>
        </div>
        <div className='overlay' style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1,
        }}></div>
      </div>
      <div
        className={'w-full sm:max-w-[640px] sm:self-center px-4 text-stone-950 pt-8 sm:pt-[124px] text-start font-Inter sm:font-IBM text-xl sm:text-[28px] font-semibold sm:leading-[38px] leading-[28px]'}
      >Join {data?.name}&#39;s Snowball today. <br/>
        Make an impact on people and the planet.
      </div>
      <div className={'flex flex-col pt-4 sm:pt-0'}>
        <div className={'flex mx-4 pb-8 items-center justify-center'}>
          <div className={'sm:max-w-[608px] sm:mt-[16px] sm:self-center'}>
            <MainBlock totalCo2Reduced={data?.totalCo2Reduced} totalContribution={data?.totalContribution}
                       referenceTransactions={data?.referenceTransactions} name={data?.name} />
          </div>
        </div>
        <div className={'pb-8 flex items-center justify-center'}>
          <PayBlock profileBlock={true} />
        </div>
        <div className={'flex bg-white items-center justify-center'}>
          <HowWeWork />
        </div>
        <SurveyBlock />
        <ProjectList />
        <ImpactSection />
      </div>
      <Footer />
    </div>
  )
}

export default memo(PublicProfile)