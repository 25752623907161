import React from 'react'

interface ProjectCardProps {
  imgSrc: string;
  description: string;
}

const NewsCard: React.FC<ProjectCardProps> = ({ imgSrc, description }) => (
  <div
    className='bg-white rounded-xl w-[95%] sm:w-full max-sm:shadow flex-col justify-start max-sm:mb-2 items-start inline-flex sm:gap-4 flex-shrink-0'>
    <img className={'w-full sm:h-[400px] h-[228px] sm:rounded-[8px] rounded-t-[12px] '}
         src={imgSrc} alt={description} />
    <div className="w-full text-neutral-600 text-[17px] max-sm:p-4 max-sm:pb-12 font-normal font-Inter leading-normal text-start">{description}</div>
  </div>
)

export default NewsCard