import React from 'react'
import PayCard from './components/PayCard'
import CustomPayCard from './components/CustomPayCard'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

const PayData = [
  {
    title: 'Planet Protector',
    price: '49€',
    value: 49,
    event: 'RP_PRICING_ONE_TIME_SMALL_AMOUNT',
    popular: false,
  },
  {
    title: 'Climate Champion',
    price: '99€',
    value: 99,
    event: 'RP_PRICING_ONE_TIME_MEDIUM_AMOUNT',
    popular: true,
  },
  {
    title: 'Sustainability Hero',
    price: '199€',
    value: 199,
    event: 'RP_PRICING_ONE_TIME_LARGE_AMOUNT',
    popular: false,
  },
]

interface PayBlockProps {
  emission: number;
}

const PayBlock: React.FC<PayBlockProps> = ({
  emission,
}) => {
  const [isOneTime] = React.useState(true)

  const navigate = useNavigate()

  return (
    <div className={'w-full flex items-center justify-center max-sm: mt-10 sm:px-12 px-4 bg-[#E5E5DF]'}>
      <div
        className={'w-full max-w-[1400px] flex-col relative rounded-[12px] py-[100px] justify-start flex-grow items-center inline-flex container '}>
        <div className={'w-full flex flex-row justify-center  items-center '}>
          <div
            className=' text-green-600 text-xs text-center font-bold uppercase '
          >Make a lasting change
          </div>
        </div>
        <div
          className='text-black mb-10 mt-2 xl:w-10/12 xl:text-[42px] text-center xl:leading-[48px] text-[32px] leading-[38px] font-semibold font-Inter'
        >It can be hard to change your lifestyle! Offset your carbon footprint by backing climate innovators.
        </div>
        {/* <Switcher isOn={isOneTime} handleToggle={() => setIsOneTime(!isOneTime)} /> */}
        <div className={'w-full flex xl:flex-row flex-col flex-nowrap gap-4 mt-12'}>
          {
            PayData.map((item, index) => (
              <PayCard key={index} setAmount={(amount) => {
                navigate('/checkout', { state: { amount: amount ?? 10, emission } },
                )
              }} isOneTime={isOneTime}
                       emission={emission} {...item} />
            ))
          }
        </div>
        <div className='text-center text-neutral-600 text-[17px] font-medium font-Inter leading-normal py-12'>Or, choose
          a custom plan
        </div>
        <div className={'flex md:flex-row flex-col max-md:gap-10 justify-center gap-4'}>
          <div className={'md:w-1/2 w-full flex-1'}>
            <CustomPayCard emission={emission} />
          </div>
          <div className={'md:w-1/2 w-full flex-1'}>
            <div className=' flex-col justify-start items-start gap-10 inline-flex'>
              <div className='self-stretch  flex-col justify-start items-start gap-6 flex'>
                <div
                  className='self-stretch text-stone-950 text-2xl font-semibold font-Inter text-start leading-[31.20px]'>Here’s
                  what you get with your Pyrpose subscription
                </div>
                <div className='self-stretch flex-col justify-start items-start gap-4 flex'>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                    <div
                      className='grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-Inter text-start leading-normal'>Recurring
                      offsets through world class projects.
                    </div>
                  </div>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                    <div
                      className='grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-Inter text-start leading-normal'>Personal
                      profile to track your impact.
                    </div>
                  </div>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                    <div
                      className='grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-Inter text-start leading-normal'>Monthly
                      updates on your impact journey.
                    </div>
                  </div>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>

                    <div
                      className='grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-Inter text-start leading-normal'>100%
                      of your money funds climate innovators.
                    </div>
                  </div>
                </div>
              </div>
              <a href={'https://docs.pyrpose.io/fundamentals/faq'} onClick={() => {
                ReactGA.event({
                  category: 'RP_PRICING_VISIT_FAQ',
                  action: 'RP_PRICING_VISIT_FAQ',
                  label: 'RP_PRICING_VISIT_FAQ',
                })
                ReactGA.event({
                  category: 'RP_MENU_PRICING',
                  action: 'RP_MENU_PRICING',
                  label: 'RP_MENU_PRICING',
                })
              }}
                 className='px-5 py-3 cursor-pointer bg-black bg-opacity-10 rounded-lg justify-start items-center gap-2 inline-flex'>
                <div className='text-center text-stone-950 text-[17px] font-medium font-Inter leading-tight'>Visit
                  FAQ
                </div>
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4.16699 9.99935H15.8337M15.8337 9.99935L10.0003 4.16602M15.8337 9.99935L10.0003 15.8327'
                        stroke='#0F0F0F' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayBlock