import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReactGA from 'react-ga4'
import PrimaryButton from '../../../../components/PrimaryButton'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Bounce, toast } from 'react-toastify'
import { useAuth } from '../../../../utils/AuthContext/AuthProvider'

interface SignInFormProps {
  handleLogin: () => void
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().required('Required'),
})
const SignInForm: React.FC<SignInFormProps> = ({ handleLogin }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()
  const { signIn } = useAuth()


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      ReactGA.event({
        category: 'RP_BTN_SIGN_UP',
        action: 'RP_BTN_SIGN_UP',
        label: 'RP_BTN_SIGN_UP',
      })
      try {
        await signIn(values)
        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        let errorMessage = 'An error occurred during sign-up. Please try again.'
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message
        }
        toast.error(errorMessage, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
      }
    },
  })

  return (
    <form className={'gap-4 flex sm:max-w-[364px]  w-full flex-col'} onSubmit={formik.handleSubmit}>
      <div onClick={() => {
        navigate(-1)
      }}
           className={' text-[15px] cursor-pointer max-sm:hidden items-center justify-start  flex flex-row gap-2 leading-[20px] text-start text-[#737373] font-Inter'}>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M12.6665 7.99999H3.33317M3.33317 7.99999L7.99984 3.33333M3.33317 7.99999L7.99984 12.6667'
                stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
        Back
      </div>
      <div
        className={'font-semibold font-Inter sm:text-[28px] text-[20px]  pb-1 sm:leading-[32px] leading-[24px] max-sm:pt-4 max-sm:pb-5 text-[#0F0F0F] text-start'}>
        Sign In to Pyrpose
      </div>
      <div>
        <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
          Email
        </div>
        <input
          id='email'
          name='email'
          type='email'
          placeholder='Email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.email}</div>
        ) : null}
      </div>
      <div>
        <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
          Password
        </div>
        <input
          id='password'
          name='password'
          type='password'
          placeholder='Password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.password}</div>
        ) : null}
      </div>
      <div
        onClick={() => navigate('/request-password-change')}
        className={'font-Inter text-start pb-2 font-[500] text-[14px] flex items-center justify-end underline cursor-pointer'}>
        Forgot your password?
      </div>
      <PrimaryButton
        onClick={() => formik.handleSubmit()}
        disabled={isLoading}
        className=' !bg-[#37992E] !w-full h-[48px] !my-[16px] rounded-xl justify-center items-center gap-2 inline-flex'
      >
        {formik.isSubmitting ? (<CircularProgress size={24} />) :
          <div className='text-center text-white !text-[17px] font-semibold !font-Inter normal-case '>Continue</div>
        }
      </PrimaryButton>
      <span className={' text-[15px] leading-[20px] text-start text-[#737373] font-Inter'}>
          Dont have an account?  <span onClick={handleLogin}
                                       className={'underline cursor-pointer underline-offset-2 text-start text-[#525252]'}>Sign Up instead</span>
        </span>
    </form>
  )
}

export default SignInForm