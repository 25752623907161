import React from 'react'
import HeroFooterBackground from '../../assets/images/HeroBackground.webp'
import Button from '../Button'
import ReactGA from 'react-ga4'


interface HeroFooterProps {
  description?: string
}

const HeroFooter: React.FC<HeroFooterProps> = ({
  description,
}) => {
  return (
    <div
      className={` ${description ? ' h-[440]' : 'h-[480px]'} w-full bg-center bg-cover flex flex-col justify-between py-16 items-center text-white text-4xl `}
      style={{
        backgroundImage: `url(${HeroFooterBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)',
      }}>
      <div
        className={'w-full max-sm:px-4  flex-col justify-between items-start gap-8 inline-flex sm:max-w-[608px] sm:mx-auto h-full'}>
        <div
          className={`w-full text-white text-start ${description && ' px-4 ' }  sm:text-center text-[34px] sm:mx-auto font-medium font-['IBM Plex Serif'] leading-[38px]`}
          style={{
            fontFamily: 'IBM Plex Serif',
          }}
        >Create the
          future you wish to see
          {description && <div
            className="w-full text-white max-sm:hidden text-start pt-6 sm:text-center text-[17px] max-w-[446px] sm:mx-auto font-normal font-Inter leading-normal"
          >{description}
          </div>}
        </div>
        <a href='#landing_pay_block' className={`w-full sm:!max-w-[280px] sm:mx-auto ${description && ' px-4 ' }`}>
          <Button
            text={<div className="text-center text-white text-[17px] font-semibold font-['Inter'] leading-tight">Make
              a
              difference</div>}
            type={'L-Hero'}
            className={'w-full'}
            onClick={() => {
              ReactGA.event({
                category: 'HOME_BTN_MAKE_AN_IMPACT_CLICKED',
                action: 'HOME_BTN_MAKE_AN_IMPACT_CLICKED',
                label: 'HOME_BTN_MAKE_AN_IMPACT_CLICKED',
              })
            }}
          />
        </a>
      </div>
    </div>
  )
}

export default HeroFooter
