import React from 'react'

interface ButtonProps {
  text: React.ReactNode;
  onClick: () => void;
  type: 'L-Hero' | 'L' | 'M' | 'S' | 'XS';
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type,
  disabled = false,
  className = '',
}) => {
  switch (type) {
  case 'L-Hero':
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`w-full h-14 px-6 py-4 bg-black bg-opacity-70 rounded-xl justify-center items-center gap-2 inline-flex ${disabled ? 'opacity-50' : ''} focus:bg-[#00000070]`}
      >
        {text}
      </button>
    )
  case 'L':
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`w-full h-12 px-6 py-3.5 bg-[#0F0F0F] rounded-xl justify-center items-center gap-2 inline-flex ${disabled ? 'opacity-50' : ''} focus:bg-[#00000070]`}
      >
        {text}
      </button>
    )
  case 'M':
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${className} h-11 px-4 bg-black bg-opacity-10 hover:bg-[#00000029] focus:bg-[#00000080] rounded-lg ${disabled ? 'opacity-50' : ''} justify-start items-center gap-2 inline-flex`}
      >
        {text}
      </button>
    )
  case 'S':
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${className} h-8 px-3 py-1.5 bg-green-600 rounded-md justify-start items-center gap-1.5 inline-flex`}
      >
        {text}
      </button>
    )
  case 'XS':
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${className} h-5 rounded-sm justify-start items-center gap-1.5 inline-flex`}
      >
        {text}
      </button>
    )
  default:
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${className} h-[64px] flex items-center justify-center rounded-[16px] bg-[#000] text-white text-[24px] font-bold`}
      >
        {text}
      </button>
    )
  }
}

export default Button