import React, { useEffect } from 'react'
import Button from '../Button'
import { TextField } from '@mui/material'
import ReactGA from 'react-ga4'
import { CONSTANTS } from '../../../../constants/values'
import { useNavigate } from 'react-router-dom'

interface AmountObject {
  amount: string
  type: 'input' | 'button'
}

interface PayBlockProps {
  profileBlock?: boolean
  privateProfile?: boolean
  project?: boolean
}

const PayBlock: React.FC<PayBlockProps> = ({
  profileBlock = false,
  privateProfile = false,
  project = false,
}) => {
  const [amount, setAmount] = React.useState<AmountObject>({
    amount: '49',
    type: 'button',
  })
  const [error, setError] = React.useState<string | null>(null)
  const [isBlurred, setIsBlurred] = React.useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (amount.type === 'input') {
      if (parseFloat(amount.amount) < 20
      ) {
        setError('Amount must be greater than 20')
      } else if (parseFloat(amount.amount) > 900) {
        setError('Amount must be less than 900')
      } else if (amount.amount === '') {
        setError('Amount must be greater than 0')
      } else {
        setError(null)
      }
    }
  }, [amount.amount])

  let message

  if (profileBlock && privateProfile) {
    message = 'Make an additional contribution'
  } else if (profileBlock) {
    message = 'Multiply your influence with the snowball'
  } else {
    message = 'Choose the amount that works best for you'
  }


  if (profileBlock || project)
    return (
      <div
        className={`w-full max-sm:px-4 py-8 ${!profileBlock || !project && ' w-full'} `}
        id={'landing_pay_block'}>
        <div
          className={`w-full  flex-col justify-start items-start gap-8 inline-flex ${profileBlock || project ? 'sm:max-w-[608px] sm:self-center sm:mx-4 ' : 'sm:max-w-[400px]'}  md:mx-auto`}>
          <div
            className={`flex-col justify-start items-start flex w-full   ${profileBlock || project ? 'sm:flex-row gap-6' : 'sm:max-w-[400px]'}`}>
            <div className={'sm:!w-1/2'}>
              <div className='text-[#37992E] text-start text-xs pb-[8px] font-bold uppercase'>
                Make a lasting change
              </div>
              <div className='text-start text-[#0F0F0F] text-[28px] font-semibold leading-[36px]'
                   style={{
                     fontFamily: 'IBM Plex Serif',
                   }}
              >
                {message}
              </div>
              <div
                className=' text-neutral-600 text-start text-[17px] max-sm:hidden font-normal font-[Inter] leading-normal mt-4'>
                <div className=' flex-col justify-start items-start gap-[11px] inline-flex'>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                    <div
                      className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                      offset {((Number(amount.amount) || 0) / CONSTANTS.PRICE_PER_MT).toFixed(1)} tonnes of
                      future carbon emissions.
                    </div>
                  </div>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                    <div
                      className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                      fund a portfolio of climate projects.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'sm:!w-1/2 flex flex-col sm:gap-4 '}>
              <div className='flex-row flex w-full justify-between gap-4 sm:gap-2 sm:mt-[29px] max-sm:pb-4'>
                <Button
                  text={<div
                    className={` ${amount.type === 'button' && amount.amount === '49' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€49</div>}
                  onClick={() => {
                    setAmount({
                      amount: '49',
                      type: 'button',
                    })
                  }}
                  type='M'
                  className={` ${amount.type === 'button' && amount.amount === '49' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'} rounded-[8px] flex-1`}
                />
                <Button
                  text={<div
                    className={`${amount.type === 'button' && amount.amount === '99' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€99</div>}
                  onClick={() => {
                    setAmount({
                      amount: '99',
                      type: 'button',
                    })
                  }}
                  type='M'
                  className={`${amount.amount === '99' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'} rounded-[8px]  flex-1`}
                />
                <Button
                  text={<div
                    className={`${amount.amount === '199' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€199</div>}
                  onClick={() => {
                    setAmount({
                      amount: '199',
                      type: 'button',
                    })
                  }}
                  type='M'
                  className={`${amount.amount === '199' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'}   rounded-[8px] flex-1 `}
                />
              </div>
              <TextField
                variant='outlined'
                color={'primary'}
                className={'w-full !h-[64px]'}
                label={'or enter a custom amount'}
                value={amount.type === 'input' ? amount.amount : ''}
                onBlur={() => {
                  setIsBlurred(true)
                }}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/
                  if (e.target.value === '' || regex.test(e.target.value))
                    setAmount({ amount: e.target.value, type: 'input' })
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#FFFFFF',
                  },
                }}
              />
              {
                error !== null && isBlurred && amount.type === 'input' &&
                <div className='text-red-500 text-[12px] font-normal font-[Inter] leading-normal mt-[-16px]'>
                  {error}
                </div>
              }
              <div
                className=' text-neutral-600 text-start text-[17px] sm:hidden font-normal font-[Inter] leading-normal my-4'>
                <div className=' flex-col justify-start items-start gap-[11px] inline-flex'>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                    <div
                      className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                      offset {((Number(amount.amount) || 0) / CONSTANTS.PRICE_PER_MT).toFixed(1)} tonnes of
                      future carbon emissions.
                    </div>
                  </div>
                  <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                        stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                    <div
                      className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                      fund a portfolio of climate projects.
                    </div>
                  </div>
                </div>
              </div>
              <Button
                text={<div
                  className='text-center text-white text-[17px] font-semibold font-[Inter]'>{profileBlock ? privateProfile ? 'Make an impact' : 'Join the Snowball' : 'Make a difference'}</div>}
                onClick={() => {
                  ReactGA.event({
                    category: 'HOME_BTN_PRICING_CLICKED',
                    action: 'HOME_BTN_PRICING_CLICKED',
                    label: 'HOME_BTN_PRICING_CLICKED',
                  })
                  navigate('/checkout', {
                    state: {
                      amount: parseFloat(amount.amount || '10') || 10,
                      currency: 'eur',
                    },
                  })
                }}
                disabled={amount.type === 'input' && amount.amount !== '0001' ? error !== null : false}
                type='L'
              />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={'w-full max-w-[608px] py-16'} id={'landing_pay_block'}>
      <div
        className={`w-full flex-col justify-start items-start gap-8 inline-flex ${profileBlock ? 'sm:max-w-[640px]' : 'sm:max-w-[400px]'}  md:mx-auto`}>
        <div
          className={`flex-col justify-start items-start flex w-full   ${profileBlock ? 'sm:flex-row' : 'sm:max-w-[400px]'}`}>
          <div>
            <div className='text-[#37992E] text-start text-xs pb-[8px] font-bold uppercase'>
              Make a lasting change
            </div>
            <div className='text-start text-[#0F0F0F] text-[28px] font-semibold leading-[36px]'
                 style={{
                   fontFamily: 'IBM Plex Serif',
                 }}
            >
              {profileBlock ? 'Multiply your influence with the snowball' : 'Choose the amount that works best for you'}
            </div>
          </div>
          <div className='flex-row flex w-full justify-between gap-4 mt-6 pb-4'>
            <Button
              text={<div
                className={` ${amount.type === 'button' && amount.amount === '49' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€49</div>}
              onClick={() => {
                setAmount({
                  amount: '49',
                  type: 'button',
                })
              }}
              type='M'
              className={` ${amount.type === 'button' && amount.amount === '49' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'} rounded-[8px] !w-[30%]`}
            />
            <Button
              text={<div
                className={`${amount.type === 'button' && amount.amount === '99' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€99</div>}
              onClick={() => {
                setAmount({
                  amount: '99',
                  type: 'button',
                })
              }}
              type='M'
              className={`${amount.amount === '99' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'} rounded-[8px] !w-[30%] `}
            />
            <Button
              text={<div
                className={`${amount.amount === '199' ? '!text-white' : '!text-[#525252]'} w-full text-center text-[15px] font-semibold`}>€199</div>}
              onClick={() => {
                setAmount({
                  amount: '199',
                  type: 'button',
                })
              }}
              type='M'
              className={`${amount.amount === '199' ? '!bg-black' : 'bg-stone-950 bg-opacity-10'}   rounded-[8px] !w-[30%] `}
            />
          </div>
          <TextField
            variant='outlined'
            color={'primary'}
            className={'w-full'}
            label={'or enter a custom amount'}
            value={amount.type === 'input' ? amount.amount : ''}
            onBlur={() => {
              setIsBlurred(true)
            }}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/
              if (e.target.value === '' || regex.test(e.target.value))
                setAmount({ amount: e.target.value, type: 'input' })
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
              },
            }}
          />
          {
            error !== null && isBlurred && amount.type === 'input' &&
            <div className='text-red-500 text-[12px] font-normal font-[Inter] leading-normal mt-[-16px]'>
              {error}
            </div>
          }
          <div
            className=' text-neutral-600 text-start text-[17px] font-normal font-[Inter] leading-normal my-4'>
            <div className=' flex-col justify-start items-start gap-[11px] inline-flex'>
              <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                    stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                <div
                  className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                  offset {((Number(amount.amount) || 0) / CONSTANTS.PRICE_PER_MT).toFixed(1)} tonnes of
                  future carbon emissions.
                </div>
              </div>
              <div className='self-stretch justify-start items-start gap-2 inline-flex'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z'
                    stroke='#37992E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
                <div
                  className="grow shrink basis-0 text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">You
                  fund a portfolio of climate projects.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          text={<div
            className='text-center text-white text-[17px] font-semibold font-[Inter]'>{profileBlock ? 'Join the Snowball' : 'Make a difference'}</div>}
          onClick={() => {
            ReactGA.event({
              category: 'HOME_BTN_PRICING_CLICKED',
              action: 'HOME_BTN_PRICING_CLICKED',
              label: 'HOME_BTN_PRICING_CLICKED',
            })
            navigate('/checkout', {
              state: {
                amount: parseFloat(amount.amount || '10') || 10,
                currency: 'eur',
              },
            })
          }}
          disabled={amount.type === 'input' && amount.amount !== '0001' ? error !== null : false}
          type='L'
        />
      </div>
    </div>
  )
}

export default PayBlock