import React from 'react'
import { Header } from '../LandingV3/components'
import Footer from '../LandingV3/components/Footer'

interface VideoBlockProps {
  url?: string;
  title?: string;
  description?: string;
  homePage?: boolean;
}
const VideoBlock: React.FC<VideoBlockProps> = ({
  url = 'https://www.youtube.com/embed/NVv8fyFhyv0?si=OJI0J3rvV9pMkYU2',
  title = 'A Candid conversation with our visionary Chairwoman, Fleur Heyns.',
  description = 'An exclusive interview with Fleur Heyns! Listen to why she thinks technology can massively catalyze\n' +
  '                the investments for climate change and why is she optimistic on the next generation in climate!',
  homePage = false,
}) => {

  return (
    <div id='video-fleur' className='w-full bg-white flex items-center justify-center flex-col'>
      <div className='container mx-auto flex items-center justify-center text-center '>
        <div
          className={`text-center text-black  font-bold  mx-4 ${homePage ? 'sm:text-[64px] text-[32px]   sm:py-16 py-4 max-sm:pt-8' : 'sm:text-[28px] text-[24px]'} `}>
          {title}
        </div>
      </div>
      <div className='container mx-auto flex flex-row flex-wrap items-center justify-center py-[16px] px-4'>
        {description}
      </div>
      <div className='container mx-auto flex flex-row flex-wrap items-center justify-center px-4'>
        <div className='relative w-full h-0' style={{ paddingTop: '56.25%' }}>
          <iframe
            className='absolute top-0 left-0 w-full h-full'
            src={url}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}

const AboutUs = () => {
  return (
    <div className={'w-full bg-white flex-1'}>
      <Header />
      <div className='container mx-auto max-sm:px-4 flex flex-col items-center py-16'>
        <div
          className={'w-full flex flex-col  px-4'}
        >
                    <span className={'text-black sm:text-[64px] text-[24px] font-bold text-center '}>
                        Get rewarded for fighting climate change
                    </span>
          <div
            className='container mx-auto flex flex-row text-center flex-wrap items-center justify-center py-[32px] px-4'>
            💡 Pyrpose is a climate finance platform for the climate conscious. At Pyrpose, consumers gain
            access to the companies reducing future carbon emissions. Plus, the solutions funded are
            changing the quality of life for those that need it most!
          </div>
        </div>
        <VideoBlock />
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs