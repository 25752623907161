import React, { useRef } from 'react'
import Button from '../Button'
import Arrow from '../../icons/Arrow'
import CookingWomen from '../../assets/images/CookingWomen.webp'
import SolarPanels from '../../assets/images/SolarPanels.webp'
import SolarWorkers from '../../assets/images/SolarWorkers.webp'

import ReactGA from 'react-ga4'

interface ProjectCardProps {
  imgSrc: string;
  location: string;
  title: string;
  description: string;
  GaEvent: string;
  url?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ imgSrc, location, title, description, GaEvent, url }) => (
  <div className='bg-white rounded-xl shadow mb-4 sm:mb-6 flex-col sm:h-[220px] sm:flex-row-reverse justify-start sm:justify-between items-start inline-flex gap-4 flex-shrink-0'>
    <div className='w-[327px] sm:w-[280px] h-[280px] sm:h-[220px] relative'>
      <img className={'w-full sm:w-[280px] h-[280px] sm:h-[220px] absolute top-0 object-cover max-sm:rounded-t-[12px] sm:rounded-r-[12px]'} src={imgSrc} alt={title} />
      <div
        className='px-4 py-1.5 left-[12px] top-[236px] sm:top-[176px] absolute bg-white bg-opacity-75 rounded-[200px] justify-start items-center gap-1 inline-flex'>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fillRule='evenodd' clipRule='evenodd'
                d='M2 6.66669C2 3.35298 4.68629 0.666687 8 0.666687C11.3137 0.666687 14 3.35298 14 6.66669C14 8.35081 13.2354 9.82684 12.1949 11.178C11.3253 12.3071 10.2122 13.4106 9.10043 14.5127L9.09965 14.5135C8.88964 14.7217 8.67968 14.9298 8.4714 15.1381C8.21106 15.3984 7.78895 15.3984 7.5286 15.1381C7.32018 14.9297 7.1096 14.7209 6.89944 14.5126C5.78764 13.4104 4.6747 12.3071 3.80515 11.178C2.76455 9.82684 2 8.35081 2 6.66669ZM10 6.66669C10 7.77126 9.10457 8.66669 8 8.66669C6.89543 8.66669 6 7.77126 6 6.66669C6 5.56212 6.89543 4.66669 8 4.66669C9.10457 4.66669 10 5.56212 10 6.66669Z'
                fill='#525252' />
        </svg>
        <div
          className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">{location}</div>
      </div>
    </div>
    <div className='self-stretch max-sm:h-[196px] px-4 pt-5 pb-6 flex-col justify-between items-start gap-4 flex'>
      <div className='self-stretch flex-col justify-start sm:justify-between items-start gap-1 flex'>
        <div
          className="w-[295px] text-stone-950 text-xl font-semibold font-['Inter'] leading-7 text-start">{title}</div>
        <div
          className="w-[295px] text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal text-start">{description}</div>
      </div>
      <a href={url} target={'_self'}  className='px-3 py-1.5 bg-green-600 rounded-md justify-start items-center gap-1.5 inline-flex'
           onClick={() => {
             ReactGA.event({
               category: GaEvent,
               action: GaEvent,
               label: GaEvent,
             })
           }}>
        <div className="text-white text-[17px] cursor-pointer font-medium font-['Inter'] leading-tight ">Learn more</div>
        <Arrow stroke={'#fff'} />
      </a>
    </div>
  </div>
)

const ProjectList = () => {
  const scrollContainerRef = useRef(null);

  return (
    <div className='w-full px-4 py-16  bg-[#E3E3DD] flex flex-col justify-start items-start gap-8 '>
      <div className='flex-col justify-start items-start gap-5 flex w-full sm:max-w-[608px] sm:mx-auto'>
        <div className='flex-col justify-start items-start gap-2 flex w-full max-w-[100vw]'>
          <div className={'w-full flex flex-row justify-between items-center'}>
            <div
              className=' text-green-600 text-xs font-bold uppercase'
            >Our
              projects
            </div>
            <svg width='36' height='20' viewBox='0 0 36 20' className={'sm:hidden'} fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M3 9.99996H32.5M32.5 9.99996L26.6667 4.16663M32.5 9.99996L26.6667 15.8333' stroke='#37992E'
                    strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </div>
          <div className='w-full text-start text-[28px] font-semibold' style={{
            fontFamily: 'IBM Plex Serif',
          }}>Back climate innovators’ projects
          </div>
        </div>
        <div ref={scrollContainerRef} className='w-full flex flex-row sm:flex-col max-sm:gap-4 max-sm:pr-4 max-sm: overflow-x-auto max-sm:max-w-[95vw]'>
          <ProjectCard
            imgSrc={SolarWorkers}
            location='South Africa'
            title='Solar energy in schools'
            GaEvent={'HOME_BTN_PROJECT2_CLICKED'}
            // url={'/projects/solar-at-schools'}
            url={'#signup'}
            description='Transition from fossil fuels to renewable energy in schools.'
          />
          <ProjectCard
            imgSrc={SolarPanels}
            data-projectname='Solar energy in homes'
            location='Kenya, Tanzania'
            title='Solar energy in homes'
            GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
            // url={'/projects/solar-at-homes'}
            url={'#signup'}
            description='Replacement of kerosene with solar lamps in homes.'
          />
          <ProjectCard
            imgSrc={CookingWomen}
            data-projectname='Clean cooking in homes'
            location='Kenya, Tanzania'
            title='Clean cooking in homes'
            GaEvent={'HOME_BTN_PROJECT2_CLICKED'}
            // url={'/projects/clean-cooking'}
            url={'#signup'}
            description='Replacement of fossil fuels with clean cooking stoves in communities.'
          />
        </div>
        <Button text={
          <div className={'flex flex-row items-center gap-2'}>
            <div className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">Discover
              all
            </div>
            <Arrow />
          </div>
        } onClick={() => {
          ReactGA.event({
            category: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
            action: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
            label: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
          })
          window.open('/projects', '_self')
        }
        } type='M' />
      </div>
    </div>
  )
}

export default ProjectList
