import React from 'react'

interface GrayListCardProps {
  icon: string
  title: string
  description: string
}

const GrayListCard: React.FC<GrayListCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (<div
    className='w-full px-4 py-6 bg-[#E3E3DD] rounded-xl flex-col justify-start items-start gap-4 inline-flex'>
    <div className='self-stretch flex-col sm:flex-row justify-start items-start gap-4 flex'>
      <img src={icon} alt={title} className='w-12 h-12 relative' />
      <div className='self-stretch flex-col justify-start items-start gap-1 flex'>
        <div className="self-stretch text-start text-stone-950 text-xl font-semibold font-Inter">{title}
        </div>
        <div className="self-stretch text-start text-neutral-600 text-[17px] font-normal font-Inter">{description}
        </div>
      </div>
    </div>
  </div>)
}

export default GrayListCard