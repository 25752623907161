import React from 'react'
import HeroFooterBackground from '../../../../LandingV3/assets/images/HeroBackground.webp'
import SurveyPhone from '../../../../../assets/img/phone-survey.webp'

const SurveyBlock = () => {
  return (
    <div
      className=' w-full bg-center relative bg-cover flex flex-col justify-start px-4 items-center text-white text-4xl '
      style={{
        backgroundImage: `url(${HeroFooterBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)',
      }}>
      <div className={'flex z-[10] flex-col items-center  gap-[32px] pt-[42px] pb-[35px]'}>
        <div className="w-full text-white text-[34px] font-semibold font-['Inter'] text-start leading-[38px]">Learn your carbon footprint</div>
        <a href={'/start'} className="w-full sm:max-w-[230px] h-12 px-6 py-3.5 bg-black bg-opacity-75 rounded-xl justify-center items-center gap-2 inline-flex">
          <div className="text-center text-white text-[17px] font-semibold font-['Inter'] leading-tight">Take the Quiz</div>
        </a>
      </div>
      <img src={SurveyPhone} alt="survey phone" className={'z-10 max-h-[320px]'} />
      <div className='overlay' style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1,
      }}></div>
    </div>
  )
}

export default SurveyBlock
