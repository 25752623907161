import React, { type FC } from 'react'
import CircleButton from './CircleButton'

interface NumberSwitcherProps {
  value: number
  onPlus: () => void
  onMinus: () => void
  title: string
  description: string
}

const NumberSwitcher: FC<NumberSwitcherProps> = ({
  value,
  onPlus,
  onMinus,
  title,
  description,
}) => {
  return (
    <div
      className={
        'w-full py-6 flex items-center justify-center bg-[#ffffff] mb-4 rounded-[16px] shadow-sm'
      }
    >
      <div className={'flex flex-col items-center'}>
        <div className={'flex-row flex'}>
          <div className={'text-[#1A1A1A] text-[16px] font-bold'}>{title}</div>
          <div className={'text-[#1A1A1A] text-[16px] ml-1 pb-4'}>{description}</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className={'flex items-center justify-center rounded-[50%] border-[1px]'}>
            <CircleButton form={'minus'} onClick={onMinus} />
          </div>
          <div
            className={
              'text-[#1A1A1A] text-[40px] font-bold w-[124px] max-w-[124px] truncate text-center leading-[42px]'
            }
          >
            {value}
          </div>
          <div className={'flex items-center justify-center rounded-[50%] border-[1px]'}>
            <CircleButton form={'plus'} onClick={onPlus} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NumberSwitcher
