import * as React from 'react'
import { SVGProps } from 'react'

const QuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      fill={props.color || '#37992E'}
      fillRule='evenodd'
      d='M8 .667a7.333 7.333 0 1 0 0 14.666A7.333 7.333 0 0 0 8 .667Zm-.73 4.847a1.333 1.333 0 0 1 2.01 1.152c0 .313-.244.64-.703.946a4.033 4.033 0 0 1-.842.422.667.667 0 0 0 .422 1.265l.113-.04a5.365 5.365 0 0 0 1.047-.537c.54-.36 1.296-1.035 1.296-2.055a2.667 2.667 0 0 0-5.182-.888.667.667 0 0 0 1.258.442c.104-.297.31-.547.582-.707ZM8 10.667A.667.667 0 0 0 8 12h.007a.667.667 0 0 0 0-1.333H8Z'
      clipRule='evenodd'
      opacity={0.8}
    />
  </svg>
)
export default QuestionIcon
