import React, { useRef, useState } from 'react'
import { BarChart, Cloud, Expand, Flash, Hearts, Transparency } from '../../assets/svg/icons'
import BlueListCard from './BlueListCard'
import ArrowIcon from '../../../Profile/shared/icons/ArrowIcon'

const BlueListCards = [
  {
    icon: BarChart,
    title: '1. Financial Sustainability',
    description: 'By evaluating the financial health and sustainability of potential climate companies, we ensure the robust foundation of the latter for a long term-impact.',
  },
  {
    icon: Cloud,
    title: '2. Proven Impact on Carbon Emissions',
    description: 'With a comprehensive assessment of the environmental impact of the climate innovators’ products and/or services, we prioritise those that have demonstrated a clear reduction in carbon emissions. We look for companies with well-defined strategies to reduce carbon emissions across their entire operations. This includes not only their primary products but also their internal processes, supply chain management, and overall business practices.',
  },
  {
    icon: Flash,
    title: '3. Innovation in Clean Energy Solutions',
    description: 'Our selection process favours companies that showcase innovation in clean energy solutions. Whether through cutting-edge technologies, sustainable practices, or pioneering approaches, we seek companies that contribute significantly to the transition away from fossil fuels',
  },
  {
    icon: Transparency,
    title: '4. Transparency and Accountability',
    description: 'Transparency is crucial in our selection criteria. We lean towards companies that maintain a high level of transparency regarding their carbon reduction methodologies, progress, and overall business practices. Accountability ensures that they uphold the standards required for an impactful change.',
  },
  {
    icon: Hearts,
    title: '5. Social and Environmental Responsibility',
    description: 'Beyond carbon reduction, we consider the broader social and environmental responsibilities of climate innovators.',
  },
  {
    icon: Expand,
    title: '6. Scalability of Impact',
    description: 'The assessment of scalability of the impact a company can achieve is done through understanding the potential reach and influence of the products and/or services offered on a sustainable shift in the market and society.',
  },
]

const BlueList: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const goToPrev = () => {
    const newIndex = currentIndex === 0 ? BlueListCards.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    scrollToCard(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === BlueListCards.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    scrollToCard(newIndex);
  };

  const scrollToCard = (index : number) => {
    const node = carouselRef.current;
    if (node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const scrollX = index * node.offsetWidth;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.scrollTo({ left: scrollX, behavior: 'smooth' });
    }
  };



  return (<div className={'flex flex-col max-w-[608px] w-full max-sm:px-4 pb-16 overflow-hidden'}>
      <div ref={carouselRef} className='flex sm:flex-col overflow-x-scroll sm:gap-4 snap-x snap-mandatory w-full'>
        {BlueListCards.map((item, index) => (
          <div key={item.title} className={`snap-center ${index === currentIndex ? 'scale-100' : 'scale-90'} sm:!scale-100 flex-1 transition-transform duration-300`}>
            <BlueListCard
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          </div>
        ))}
      </div>
      <div className={'flex self-end items-center flex-row gap-4 mt-4 sm:hidden'}>
        <div
          className={` rounded-full flex items-center p-4 border-[1px] border-black cursor-pointer ${currentIndex === 0 && 'opacity-30'} `}
          onClick={goToPrev}>
          <ArrowIcon className={'rotate-180'} />
        </div>
        <div
          className={` rounded-full flex items-center p-4 border-[1px]  border-black cursor-pointer  ${currentIndex === (BlueListCards.length-1) && 'opacity-30'} `}
          onClick={goToNext}>
          <ArrowIcon />
        </div>
      </div>
    </div>
  )
}

export default BlueList