import React from 'react'
import DesktopBackground from '../../../LandingV3/assets/images/HeroBackground.webp'

interface HeroBlockProps {
  title: string
  description: string
  logo: string
  mainPhoto: string
}

const HeroBlock: React.FC<HeroBlockProps> = ({
  title,
  description,
  logo,
  mainPhoto,
}) => {
  return (
    <>
      <div className={'w-full md:bg-[#E3E3DD] md:hidden'}>
        <div className='w-full sm:w-[640px] sm:mx-auto  h-[554px] relative overflow-hidden'>
          <div className='w-full  h-[554px] left-0 top-0 absolute'>
            <img alt='images' className=' h-[554px] left-0 top-0 absolute object-cover' src={mainPhoto} />
          </div>
          <div className='w-full h-[554px] left-0 top-0 absolute opacity-40 bg-black' />
          <div
            className={'left-[16px] right-[16px]  absolute flex-col md:container md:mx-auto  justify-between py-[44px] items-start gap-6 inline-flex h-full '}>
            <div className={'w-full flex flex-col gap-[42px]'}>
              <div className='w-full text-white text-start text-[34px] font-medium leading-[38px]'
                   style={{
                     fontFamily: 'IBM Plex Serif',
                   }}
              >{title}
              </div>
              <div className='w-full text-white text-start text-[17px] font-normal leading-normal'>{description}
              </div>
            </div>
            <img src={logo} />
          </div>
        </div>
      </div>
      <div className={'max-md:hidden w-full'}
           style={{
             backgroundImage: `url(${DesktopBackground})`,
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'cover',
             backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)',

           }}
      >
        <div className={'flex flex-row justify-between max-w-[1100px] gap-[16px] mx-auto flex-grow py-[40px]'}>
          <div
            className={' flex flex-col gap-[42px] justify-between items-start bg-black bg-opacity-20 rounded-xl backdrop-blur-md p-[44px] w-[60%] '}>
            <div className={'flex flex-col gap-4'}>
              <div className=' text-white text-[48px] font-medium text-start leading-[52.80px]'
                   style={{
                     fontFamily: 'IBM Plex Serif',
                   }}
              >{title}
              </div>
              <div className='w-full text-white text-start text-[17px] font-normal leading-normal'>{description}
              </div>
            </div>
            <img src={logo} />
          </div>
          <div className={'flex flex-wrap flex-row justify-between w-[60%] gap-[4%] gap-y-4'}>
            <img src={mainPhoto} alt='AfroMother' className={'w-full rounded-xl object-fill'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroBlock