import * as React from 'react'
import { SVGProps, memo } from 'react'

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='svg-icon'
    style={{
      verticalAlign: 'middle',
      fill: 'currentColor',
      overflow: 'hidden',
    }}
    viewBox='0 0 1024 1024'
    {...props}
  >
    <path
      d='M843.283 870.116c-8.439-140.516-104.296-257.423-233.908-297.15C687.88 536.273 742.4 456.533 742.4 364.089c0-127.242-103.159-230.4-230.4-230.4s-230.4 103.158-230.4 230.4c0 92.444 54.519 172.184 133.12 208.877-129.612 39.727-225.47 156.634-233.908 297.15-.664 10.903 7.964 20.195 18.963 20.195 9.955 0 18.3-7.775 18.963-17.73C227.745 718.507 355.65 596.385 512 596.385s284.255 122.122 293.357 276.196c.569 9.955 8.913 17.73 18.963 17.73 10.999 0 19.627-9.292 18.963-20.195zM319.526 364.089c0-106.288 86.187-192.474 192.474-192.474S704.474 257.8 704.474 364.089c0 106.287-86.187 192.474-192.474 192.474s-192.474-86.187-192.474-192.474z' />
  </svg>
)
const Memo = memo(ProfileIcon)
export default Memo
