import React, { type FC } from 'react'

interface CircleButtonProps {
  onClick: () => void
  form: 'plus' | 'minus'
}
const CircleButton: FC<CircleButtonProps> = ({ onClick, form }) => {
  return (
    <button
      type='button'
      className={
        'flex items-center justify-center bg-white rounded-full w-[48px] h-[48px] cursor-pointer'
      }
      onClick={onClick}
    >
      {form === 'plus' ? (
        <svg
          width='22'
          height='23'
          viewBox='0 0 22 23'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M21 11.5L1 11.5' stroke='black' strokeWidth='2' strokeLinecap='round' />
          <path d='M11 21.5L11 1.5' stroke='black' strokeWidth='2' strokeLinecap='round' />
        </svg>
      ) : (
        <svg
          width='22'
          height='3'
          viewBox='0 0 22 3'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M21 1.5L1 1.5' stroke='black' strokeWidth='2' strokeLinecap='round' />
        </svg>
      )}
    </button>
  )
}

export default CircleButton
