import React from 'react'
import MainBackground from '../../assets/img/main-background.webp'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'


import ffNews from '../../assets/icons/ffnews.svg'
import finExtra from '../../assets/icons/finextra.svg'
import fintechGlobal from '../../assets/icons/fintechglobal@2x.png'
import startUpTicker from '../../assets/icons/startup-ticker.svg'
import tfounders from '../../assets/icons/tfounders@2x.png'
const SocialMedia = [
  {
    name: 'ffNews',
    icon: ffNews,
    link: 'https://ffnews.com/newsarticle/sustainability/climate-finance-startup-pyrpose-opens-public-beta-enabling-consumers-to-directly-support-climate-innovators/',
  },
  {
    name: 'finExtra',
    icon: finExtra,
    link: 'https://www.finextra.com/pressarticle/98276/climate-finance-startup-pyrpose-launches-beta-platform',
  },
  {
    name: 'fintechGlobal',
    icon: fintechGlobal,
    link: 'https://fintech.global/2023/05/30/pyrpose-secures-1-1m-to-fuel-climate-fintech-innovation/',
  },
  {
    name: 'startUpTicker',
    icon: startUpTicker,
    link: 'https://www.startupticker.ch/en/news/climate-finance-platform-raises-usd-1-1-million',
  },
  {
    name: 'tfounders',
    icon: tfounders,
    link: 'https://www.founderstoday.news/pyrpose-secures-1-million-in-funding/',
  },
]

const MainBlock: React.FC = () => {
  const navigate = useNavigate()
  return <div className={'w-full bg-white flex items-center justify-center sm:px-12 '}>
    <div
      className={'container w-full flex items-center justify-center sm:rounded-[12px] py-[120px] max-sm:py-[80px] lg:py-[160px] max-sm:px-4 sm:pb-[100px] max-w-[1400px]'}
      style={{
        backgroundImage: `url(${MainBackground})`,
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
      }}
    >
      <div className={'sm:px-[92px] w-full flex flex-col items-center justify-center gap-10'}>
        <div
            className='w-full text-center text-white lg:text-[72px] md:text-[64px] sm:text-[42px] text-[40px] lg:leading-[77px] md:leading-[68px] sm:leading-[47px] leading-[42px]  animate__animated animate__fadeInUp animate__delay-1s font-extrabold font-Inter '>Gain
          access to green<br/>
          investments
        </div>
        <div
            className=' text-center text-white text-[20px] font-medium animate__animated sm:pb-8 animate__fadeInUp animate__delay-1s font-Inter sm:w-7/12 w-full '>Explore
          how aligning your wealth to your values has the power to reshape our planet
        </div>
        <a href={'#signup'} onClick={() => {
          ReactGA.event({
            category: 'HP_BTN_CALCULATE',
            action: 'HP_BTN_CALCULATE',
            label: 'HP_BTN_CALCULATE',
          })
        }
        }
           className='sm:h-[64px] sm:w-[388px] h-[58px] w-[287px] cursor-pointer px-6 py-4 bg-[#E3E3DD] animate__animated animate__fadeInUp animate__delay-1s rounded-xl shadow justify-center items-center gap-2 inline-flex'>
          <div
              className='text-center text-stone-950 text-[15px] font-bold font-Inter uppercase tracking-wide'>Join Now
          </div>
        </a>

        <div className={'w-full sm:w-8/12 sm:mx-auto flex flex-col  gap-[42px]'}>
          <div className=' justify-between items-center flex w-full md:max-w-[512px] md:self-center'>
            {SocialMedia.map((item, index) => (
                <a href={item.link} target={'_blank'} key={`hero-carousel:${item.name}:${index}`}
                   className='w-[52px] h-[52px] relative rounded-lg hover:bg-[#00000066]' rel='noreferrer'>
                  <img alt={item.name} src={item.icon} className={'bg-cover'}/>
                </a>
            ))}
          </div>
        </div>

      </div>
    </div>
  </div>
}

export default MainBlock