import * as React from 'react'
import { SVGProps } from 'react'
const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={41}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M19.927 5.765c-6.761 4.333-10.853 11.85-10.853 19.932V41l2.652-1.867c5.087-3.582 7.997-9.868 7.997-13.646l-.373-.32c-2.02-1.735-1.783-4.949.47-6.364 5.664-3.632 9.095-9.931 9.095-16.7V0l-8.988 5.765Z"
    />
    <path
      fill="url(#b)"
      d="M19.524 27.449c.127-.543.199-1.962.199-1.962 0-8.081-3.973-15.389-10.734-19.726L0 0v2.12c0 6.76 3.427 13.05 9.082 16.675l8.629 5.308s2.08 1.243 1.813 3.341v.005Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={32.012}
        x2={2.371}
        y1={2.62}
        y2={35.782}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#81E0B2" />
        <stop offset={0.338} stopColor="#8EE896" />
        <stop offset={0.505} stopColor="#5EC968" />
        <stop offset={0.58} stopColor="#4DAC56" />
        <stop offset={0.733} stopColor="#7CE186" />
        <stop offset={1} stopColor="#99EBA1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={5.702}
        x2={14.96}
        y1={17.637}
        y2={10.809}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#67D79A" />
        <stop offset={0.38} stopColor="#60D26C" />
        <stop offset={0.922} stopColor="#89E5A0" />
      </linearGradient>
    </defs>
  </svg>
)
export default LogoIcon
