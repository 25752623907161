import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ClearCache = () => {
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.setItem('ref', ''), navigate('/')
  }, [])
  return (
    <div>
      <h1>Clearing cache...</h1>
    </div>
  )
}

export default ClearCache
