import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import NftMock from '../../../assets/img/nft-mock.png'
import { useQuery } from 'react-query'
import { AuthService, client, getAllTransactions } from '../../../utils/api'
import { formatDate } from '../../../utils/helpers'
import LogoIntro from '../../../assets/svg/logo_intro.svg'
import LoaderPulse from '../../../components/LoaderPulse'
import { HeaderBlock } from '../PrivateProfile/components'
import ArrowIcon from '../shared/icons/ArrowIcon'
import LinkedInIcon from '../shared/icons/LinkedInIcon'
import InstagramIcon from '../shared/icons/InstagramIcon'
import YouTubeIcon from '../shared/icons/YouTubeIcon'

function formatAddress(address: string) {
  const prefix = address.slice(0, 4)
  const suffix = address.slice(-3)
  return `${prefix}...${suffix}`
}

interface NFTCardProps {
  uri: string
  amountSpent: number
  createdAt: string
  tokenId: number
  address: string
  stripeEventId: string
  handleNext?: () => void
  activeNft?: number
  handleBack?: () => void
  totalNfts?: number
}

const InfoLine = (props: { title: string; value: string; link?: string }) => {
  return (
    <div className={'flex flex-row justify-between items-center w-full max-w-[340px]'}>
      <span className={'text-[16px] font-[500] text-[#737373]'}>{props.title}</span>
      {props.link ? (
        <a
          target={'_blank'}
          href={props.link}
          className={'text-[16px] font-[500] text-stone-700 hover:text-stone-900'}
          rel='noreferrer'
        >
          {formatAddress(props.value)}
        </a>
      ) : (
        <span className={'text-[16px] font-[500]'}>{props.value}</span>
      )}
    </div>
  )
}

const ContainerCard = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
`

const NFTCard = (nft: NFTCardProps) => {
  // const [showInfo, setShowInfo] = React.useState(false)
  const { data: nftObject, isLoading } = useQuery(['nft', nft.tokenId], async () => {
      const res = await fetch(nft.uri)
      return await res.json()
    },
  )

  const imageUri = nftObject?.image ? `https://ipfs.io/ipfs/${nftObject?.image.substring(7)}` : null


  return (
    <ContainerCard className={'sm:max-w-[640px] flex flex-col items-center'}>
      <div className={'flex flex-row w-full px-[16px] justify-between items-center pt-[24px] pb-[16px]'}>
        <div className={` rounded-full flex items-center p-4 border-[1px] border-black cursor-pointer  ${nft?.activeNft === 0 && 'opacity-30'} ` } onClick={nft.handleBack}>
          <ArrowIcon className={'rotate-180'} />
        </div>
        <div className="text-center text-stone-950 text-[17px] font-normal font-['Inter'] leading-normal">{(nft?.activeNft || 0) + 1} of {nft?.totalNfts}</div>
        <div className={` rounded-full flex items-center p-4 border-[1px] ${nft?.activeNft === (nft?.totalNfts || 0) - 1 && 'opacity-30'} border-black cursor-pointer`} onClick={nft.handleNext}>
          <ArrowIcon />
        </div>
      </div>
      {isLoading ? (
          <div className='w-full sm:max-w-[343px] mb-4 h-[300px] flex items-center justify-center object-cover mx-auto animate-pulse'>
            <img src={LogoIntro} alt='logo-intro' className={' w-[10vw] h-[10vh]'} />
          </div>
      ) : imageUri && nft.uri ? (
        <img
          src={imageUri}
          alt={'Climate commit'}
          className={'w-full sm:max-w-[343px] rounded-[16px] mb-4'}
        />
      ) : (
        <img
          src={NftMock}
          alt={'Climate commit'}
          className={'w-full sm:max-w-[343px] rounded-[16px] mb-4'}
        />
      )}
      <InfoLine title={'Pledge'} value={`${nft.amountSpent} EUR`} />
      <InfoLine title={'Date'} value={formatDate(new Date(nft.createdAt))} />
      {/* <div */}
      {/*   className={'text-[16px] text-start py-4'} */}
      {/*   onClick={() => { */}
      {/*     setShowInfo(!showInfo) */}
      {/*   }} */}
      {/* > */}
      {/*   {showInfo ? 'Hide' : 'Show'} info */}
      {/* </div> */}
      {/* {showInfo && ( */}
      {/*   <div className={'flex flex-col gap-2 pb-4'}> */}
      {/*     <InfoLine title={'Token Id'} value={String(nft.tokenId)} /> */}
      {/*     /!* <InfoLine title={'Transaction Hash'} value={nft.transactionHash} /> *!/ */}
      {/*     /!* <InfoLine *!/ */}
      {/*     /!*   title={'Address'} *!/ */}
      {/*     /!*   value={nft.address} *!/ */}
      {/*     /!*   link={`https://polygonscan.com/tx/${nft.address}`} *!/ */}
      {/*     /!* /> *!/ */}
      {/*   </div> */}
      {/* )} */}
      <div className={'w-full flex justify-end align-center gap-4 mt-4 max-w-[340px] '}>
        <a
          href='mailto:support@pyrpose.io'
          style={{ textDecoration: 'none', display: 'inline-block' }}
        >
          <div className="w-[98px] h-11 px-5 py-3 bg-black bg-opacity-10 rounded-lg justify-start items-center gap-2 inline-flex">
            <div className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">Refund</div>
          </div>
        </a>
      </div>
    </ContainerCard>
  )
}

interface ProfileAchievementsScreenProps {
  email?: string
  name?: string
}

const ProfileAchievementsScreen: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get('email')
  const token = queryParams.get('token') || localStorage.getItem('token') || ''
  const navigate = useNavigate()
  const [profile, setProfile] = useState<ProfileAchievementsScreenProps>({})
  const [activeNft, setActiveNft] = useState(0)

  const handleVerifyToken = async (token: string) => {
    try {
      await AuthService.verifyToken(token).then(r => {
          if (r.isUser) {
            localStorage.setItem('token', token)
            client.defaults.headers.common['Authorization'] = `Bearer ${token}`
          } else {
            navigate('/login')
          }
        },
      ).catch(() => navigate('/login'))
    } catch
      (error) {
      console.log(error)
    }
  }

  const getProfile = async () => {
    try {
      const token = localStorage.getItem('token')
      if (!token) new Error('No token found')

      const response = await client.get('/auth/profile', {
        headers: { Authorization: `Bearer ${token}` },
      })
      setProfile(response.data)
    } catch (error) {
      console.error('Error fetching profile:', error)
      navigate('/login')
    }
  }

  useEffect(() => {
    if (!token) navigate('/login')
    handleVerifyToken(token || '').then(() => getProfile())
  }, [])


  const { data, isLoading } = useQuery(['profile'], () => getAllTransactions(email || '', {
    headers: { Authorization: `Bearer ${token}` },
  }), {
    enabled: !!profile,
  })

  if (isLoading) {
    return <LoaderPulse />
  }

  const nfts = data ? data?.ownTransactions?.map((item: NFTCardProps) => {
      return {
        uri: item.uri,
        amountSpent: item.amountSpent,
        createdAt: item.createdAt,
        tokenId: item.tokenId,
        address: item.address,
        stripeEventId: item.stripeEventId,
      }
    },
  ) : []

  const handleNext = () => {
    if (activeNft === nfts.length - 1) return
    setActiveNft(activeNft + 1)
  }

  const handleBack = () => {
    if (activeNft === 0) return
    setActiveNft(activeNft - 1)
  }

  return (
    <div className={'w-full h-full bg-[#E3E3DD] min-h-screen flex flex-col  items-center justify-between'}>
      <HeaderBlock email={profile?.email} name={profile?.name} />
      <div className="max-w-[640px] w-full mt-8 px-4 flex-col justify-start items-start gap-4 inline-flex">
        <a href={'/profile'} className="rounded-sm max-sm:hidden justify-start items-center gap-1.5 inline-flex">
          <ArrowIcon stroke={'#37992E'} className={'rotate-180'} />
          <div className="text-[#37992E] text-[17px] font-medium font-[Inter] leading-tight">Back to Profile</div>
        </a>
        <div className=" text-stone-950 text-[34px] font-medium font-[IBM] leading-[38px]">My Climate Artifacts</div>
      </div>
      <div
        className={
          'flex flex-col my-8 px-4 sm:flex-row flex-wrap sm:items-center justify-center w-full'
        }
      >
        <NFTCard {...nfts[activeNft]} key={`key:nft:item:${nfts[activeNft].stripeEventId}`} activeNft={activeNft}
                 totalNfts={nfts.length} handleBack={handleBack} handleNext={handleNext} />
      </div>
      <div className={'flex flex-col px-4 w-full mx-auto gap-8 mb-16 items-center justify-center'}>
      <a href={'mailto:support@pyrpose.io'}
         className='w-full h-12 px-6 py-3.5 rounded-xl border border-stone-950 justify-center max-w-[640px] items-center gap-2 inline-flex'>
        <div className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">Any
          questions?
          Contact us
        </div>
      </a>
      <div className={'flex flex-row w-full justify-between items-center max-w-[640px]'}>
        <a href={'mailto:support@pyrpose.io'}
           className="text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">support@pyrpose.io</a>
        <div className={'flex flex-row items-center gap-2'}>
          <a href='https://www.linkedin.com/company/pyrpose/' target={'_blank'} rel='noreferrer'>
            <LinkedInIcon />
          </a>
          <a href='https://www.instagram.com/pyrpose.io/' target={'_blank'} rel='noreferrer'>
            <InstagramIcon />
          </a>
          <a href={'https://www.youtube.com/@Pyrpose'} target={'_blank'} rel='noreferrer'>
            <YouTubeIcon />
          </a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default ProfileAchievementsScreen