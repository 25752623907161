import { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PyrposeLogo from '../../assets/svg/logo-bw.svg'
import { Markup } from 'interweave'
import { animated, useSpring } from '@react-spring/web'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { ISurvey } from '../../screen.interfaces'
import quizStore from '../../stores/answers'
import config from '../../config'
import SecondaryButton from '../../components/SecondaryButton'
import Screen from './components/Screen'
import { activeQuizTypes } from '../../utils/enums'
import LoaderPulse from '../../components/LoaderPulse'
import { postFunnel, postSurvey } from '../../utils/api'
import { formatDate } from '../../utils/helpers'

const Survey = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [steps, setSteps] = useState<ISurvey[]>([])
  // eslint-disable-next-line
  const [visible, setVisible] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const isStep = searchParams.get('step')
  const height = window?.visualViewport?.height || window.innerHeight
  const color = steps[activeStep]?.color || '#000000'
  const questions = quizStore.getQuestions()
  const answers = quizStore.getUserAnswers()
  const referenceCode = searchParams.get('ref')

  const items = document.getElementsByClassName('helpcrunch-iframe-wrapper');

  if (items.length > 0) {
    (items[0] as HTMLElement).style.display = 'none';
  }


  useEffect(() => {
    quizStore.clearUserAnswers()
  }, [])

  useEffect(() => {
    if (isStep) {
      setActiveStep(parseInt(isStep))
    }
  }, [isStep])

  useEffect(() => {
    if (questions && questions.screens) {
      const activeScreens = questions.screens.filter((screen) => {
        return screen.questions.some((question) => activeQuizTypes.includes(question.type))
      })
      setSteps(activeScreens)
    }
  }, [questions])

  // eslint-disable-next-line
  const [springProps, set] = useSpring(() => ({
    display: 'none',
    opacity: '0',
    config: { mass: 1, tension: 260, friction: 60 },
  }))

  const [springColor, setSpringColor] = useSpring(() => ({
    backgroundColor: '#ffffff',
    config: { mass: 1, tension: 260, friction: 60, duration: 1000 },
  }))


  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  // eslint-disable-next-line
  const handleNext = async (values: any) => {
    if (activeStep === steps.length - 1) {
      setIsLoading(true)
      const finalAnswers = { ...answers, ...values }
      quizStore.setUserAnswers(answers ? finalAnswers : values)
      await postSurvey(Object.values(finalAnswers)).then((res) => {
        setIsLoading(false)
        if (res) {
          localStorage.setItem('footprint', JSON.stringify(res))
        }
      })
      setIsLoading(false)
      ReactGA.event({
        category: 'Survey',
        action: 'FinishSurvey',
        label: 'FinishSurvey',
      })
      postFunnel({
        quizFinishDatetime: formatDate(new Date()),
      }).then()
      navigate('/results', { state: { answers: { ...answers, ...values }, ref: referenceCode } })
    } else {
      quizStore.setUserAnswers(answers ? { ...answers, ...values } : values)
      let newSkipped = skipped
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStep)
      }
      setSearchParams(
        referenceCode
          ? { ref: referenceCode, step: (activeStep + 1).toString() }
          : { step: (activeStep + 1).toString() },
      )
      ReactGA.event({
        category: steps[activeStep].category,
        action: `NextScreen ${steps[activeStep].id}`,
        label: steps[activeStep].id,
      })
      setSkipped(newSkipped)
    }
  }

  const handleBack = () => {
    ReactGA.event({
      category: steps[activeStep].category,
      action: `GoBack ${steps[activeStep].id}`,
      label: steps[activeStep].id,
    })
    setSearchParams(
      referenceCode
        ? { ref: referenceCode, step: (activeStep - 1).toString() }
        : { step: (activeStep - 1).toString() },
    )
  }

  useEffect(() => {
    setSpringColor({ backgroundColor: color })
  }, [color])
  if (isLoading) {
    return <LoaderPulse />
  }

  return (
    <animated.div
      className={'flex sm:flex-row flex-col w-full'}
      style={{
        minHeight: height,
        ...springColor,
      }}
    >
      <Box className={'sm:w-4/12 sm:h-full md:w-3/12 flex max-sm:hidden flex-col'}>
        <Box
          className={'sm:py-10 sm:px-4 sm:h-full flex sm:justify-between justify-center '}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: visible ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.1)',
            backdropFilter: visible ? 'blur(0)' : 'blur(58px)',
          }}
        >
          <div className={'sm:flex hidden'}>
            <img src={PyrposeLogo} alt={'PyrposeLogo'} height={'53px'} width={'171px'} />
          </div>
          <div className={'flex flex-col justify-center h-full'}>
            <Typography
              className={'sm:text-left text-center'}
              sx={{
                color: '#fff',
                fontSize: '96px',
                fontWeight: '700',
                maxWidth: '100%',
                lineHeight: '88px',
                '@media (max-width: 768px)': {
                  fontSize: '40px',
                  lineHeight: '40px',
                  marginTop: '40px',
                  marginBottom: '16px',
                },
              }}
            >
              {steps[activeStep]?.category}
            </Typography>
          </div>
        </Box>
        <div className={'sm:flex hidden'}>
          {steps[activeStep]?.illustration && (
            <img
              src={`https://${config.staticAssets}${steps[activeStep].illustration}`}
              alt={'Illustration'}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </div>
      </Box>

      <animated.div
        className={'sm:w-8/12 w-full flex md:w-9/12 justify-center relative h-full'}
        style={{
          ...springColor,
        }}
      >
        <Box className={'max-w-[462px] px-4 w-full h-full flex justify-between flex-col'}>
          <Screen
            step={steps[activeStep]}
            key={steps[activeStep]?.id}
            handleNext={handleNext}
            answers={answers}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
          />
        </Box>
        <animated.div
          className={
            'absolute top-0 left-0 right-0 bottom-0 max-w-full h-full justify-center items-center backdrop-blur-[28px]'
          }
          style={{
            ...springProps,
            backgroundColor: steps[activeStep]?.infoColor || steps[activeStep]?.color,
          }}
        >
          <Box
            className={'max-w-[462px] px-4 w-full h-full flex bet'}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div />
            <div className={'flex flex-col items-center justify-center'}>
              <Markup content={steps[activeStep]?.info} />
            </div>
            <Box className={'pb-10'}>
              {steps[activeStep]?.isLast === true ? (
                <SecondaryButton next={true} nextLabel={'Go to results'} onClick={handleNext} />
              ) : (
                <SecondaryButton next={true} onClick={handleNext} />
              )}
            </Box>
          </Box>
        </animated.div>
      </animated.div>
    </animated.div>
  )
}

export default memo(Survey)
