import React from 'react'
import EuroLine from '../../assets/svg/euro-line.svg'
import LogoTextWhite from '../../../../assets/svg/logo-text-white.svg'
import LogoIntro from '../../../../assets/svg/logo_intro.svg'

const HeaderList: React.FC = () => {
  return (<div
    className='max-w-[608px] bg-zinc-900 mx-auto self-center bg-opacity-20 rounded-xl border border-white backdrop-blur-[80px] flex-col justify-start items-center inline-flex'>
    <div className='self-stretch px-4 py-8 rounded-xl flex-col justify-start items-center gap-4 flex'>
      <div className='self-stretch text-center text-white text-2xl font-semibold font-Inter'>Climate
        Backers
      </div>
      <div
        className='self-stretch text-center text-white text-opacity-70 text-[17px] font-normal font-Inter'>You
        back climate innovators, providing them with capital to supply clean, affordable and stable electricity where it
        is needed the most. This way, you make a direct environmental and social impact with your money.
      </div>
    </div>
    <img src={EuroLine} alt={'euroLine'} className={'w-full object-center object-contain'} />
    <div className='self-stretch px-4 pt-6 pb-8 rounded-xl flex-col justify-start items-center gap-4 flex'>
      <div className={'sm:flex-row flex-col flex items-center justify-center gap-2 w-full '}>
        <img src={LogoIntro} alt={'LogoIntro'} className={'sm:w-[42px] w-[52px]'} />
        <img src={LogoTextWhite} alt={'LogoText'} className={'w-[110px]'} />
      </div>
      <div
        className='self-stretch text-center text-white text-opacity-70 text-[17px] font-normal font-Inter '>We monitor
        climate innovators’ performance and impact at a regular basis. You receive updates on your contribution and can
        follow the impact you make through your Pyrpose Profile.
      </div>
    </div>
    <img src={EuroLine} alt={'euroLine'} className={'w-full object-center object-contain'} />
    <div className='self-stretch px-4 py-8 rounded-xl flex-col justify-start items-center gap-6 flex'>
      <div className='self-stretch text-center text-white text-2xl font-semibold font-Inter'>Climate
        Innovators
      </div>
      <div
        className='self-stretch text-center text-white text-opacity-70 text-[17px] font-normal font-Inter '>Climate
        companies use the capital to fund their business and provide products and services to customers — different
        facilities and families.
      </div>
    </div>
  </div>)
}

export default HeaderList