import React from 'react'
import Circle from '../../assets/svg/circle.svg'

const CircleBlock: React.FC = () => {
  return (
    <div className={'max-w-[608px] max-sm:px-4 flex flex-col sm:flex-row w-full py-16 '}>
      <div className='w-full flex-col justify-start items-start gap-2 inline-flex'>
        <div
          className="self-stretch text-green-600  text-xs font-bold font-Inter uppercase text-start tracking-wide">Behind
          the scene
        </div>
        <div className="self-stretch text-stone-950 text-[28px] font-semibold font-IBM text-start">How We
          Select Climate Innovators
        </div>
        <div className="w-full text-neutral-600 text-[17px] font-normal font-Inter text-start py-4">By incorporating
          these rigorous criteria, we aim to collaborate with climate innovators that align with our values and contribute
          meaningfully to the global effort to combat climate change.
        </div>
      </div>
      <img src={Circle} alt={'CircleHowItWork'} className={'w-full object-contain'} />
    </div>
  )
}

export default CircleBlock