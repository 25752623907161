import React from 'react'
import ArrowIcon from '../../../shared/icons/ArrowIcon'
import WEF from '../../../shared/assets/WEF.png'
import Team from '../../../shared/assets/Team.png'
import Improving from '../../../shared/assets/Improving.png'

interface ProjectCardProps {
  imgSrc: string;
  location: string;
  title: string;
  description: string;
  GaEvent: string;
  url?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ imgSrc, location, title, description, url }) => (
  <a href={url} target={'_blank'}
     className='bg-white rounded-xl w-[320px] sm:w-[32%] hover:shadow-xl shadow flex-col justify-start cursor-pointer items-start inline-flex gap-4 flex-shrink-0'
     rel='noreferrer'>
    <div className=' h-[280px] w-full  relative'>
      <img className={'w-full h-[280px]  rounded-t-[12px] '}
           src={imgSrc} alt={title} />
      <div
        className='px-4 py-1.5 left-[12px] top-[236px] absolute bg-white bg-opacity-75 rounded-[200px] justify-start items-center gap-1 inline-flex'>
        {/* <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> */}
        {/*   <path fillRule='evenodd' clipRule='evenodd' */}
        {/*         d='M2 6.66669C2 3.35298 4.68629 0.666687 8 0.666687C11.3137 0.666687 14 3.35298 14 6.66669C14 8.35081 13.2354 9.82684 12.1949 11.178C11.3253 12.3071 10.2122 13.4106 9.10043 14.5127L9.09965 14.5135C8.88964 14.7217 8.67968 14.9298 8.4714 15.1381C8.21106 15.3984 7.78895 15.3984 7.5286 15.1381C7.32018 14.9297 7.1096 14.7209 6.89944 14.5126C5.78764 13.4104 4.6747 12.3071 3.80515 11.178C2.76455 9.82684 2 8.35081 2 6.66669ZM10 6.66669C10 7.77126 9.10457 8.66669 8 8.66669C6.89543 8.66669 6 7.77126 6 6.66669C6 5.56212 6.89543 4.66669 8 4.66669C9.10457 4.66669 10 5.56212 10 6.66669Z' */}
        {/*         fill='#525252' /> */}
        {/* </svg> */}
        <div
          className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">{location}</div>
      </div>
    </div>
    <div className='self-stretch px-4 pt-3 pb-6 flex-col justify-between items-start gap-4 flex'>
      <div className='self-stretch flex-col justify-start items-start gap-1  flex'>
        <div
          className=" text-black text-[17px] font-semibold font-['Inter'] leading-normal text-start">{title}</div>
        <div
          className=" text-neutral-600 text-[17px] font-normal font-['Inter'] h-[72px] leading-normal text-start">{description}</div>
      </div>
      <a className=' px-4 py-1.5 bg-[#37992E] mt-4 rounded-md justify-start items-center gap-1 inline-flex' href={url}
         target={'_blank'} rel='noreferrer'>
        <div
          className="text-center text-white text-[17px] flex flex-row gap-x-1 font-medium font-['Inter'] leading-tight">Learn
          more <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.16699 9.99984H15.8337M15.8337 9.99984L10.0003 4.1665M15.8337 9.99984L10.0003 15.8332'
                  stroke='white' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
      </a>
    </div>
  </a>
)
const NewsBlock = () => {
  const [activeNews, setActiveNews] = React.useState(0)
  const handleArrowClick = (direction: string) => {
    if (direction === 'left' && activeNews !== 0) {
      setActiveNews(activeNews - 1)
    } else if (direction === 'right' && activeNews !== 1) {
      setActiveNews(activeNews + 1)
    }
  }
  return (
    <div className=' flex items-center justify-center gap-6  py-[100px] w-full max-w-[100vw]'>
      <div className={'flex-col justify-start items-start px-4  flex max-w-[1200px]'}>
        <div className='flex-col justify-start items-start gap-2 flex w-full max-w-[100%]'>
          <div className={'w-full flex flex-row justify-between items-center'}>
            <div
              className=' text-green-600 text-[12px] font-bold uppercase'
            >Updates
            </div>
          </div>
          <div className={'sm:flex-row flex-col flex items-center justify-between pb-[16px] w-full'}>
            <div className='max-sm:w-full text-start text-[28px] font-semibold' style={{
              fontFamily: 'Inter',
            }}>News and Insights
            </div>
            <div className={'flex flex-row gap-4 justify-between items-center  max-sm:hidden'}>
              <div
                className={` rounded-full flex items-center p-4 border-[1px] border-black cursor-pointer ${activeNews === 0 && 'opacity-30'} `}
                onClick={() => handleArrowClick('left')}>
                <ArrowIcon className={'rotate-180'} />
              </div>
              <div
                className={` rounded-full flex items-center p-4 border-[1px]  border-black cursor-pointer  ${activeNews === 1 && 'opacity-30'} `}
                onClick={() => handleArrowClick('right')}>
                <ArrowIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-row gap-4 justify-between max-sm:hidden'}>
          {activeNews === 0 ? (
            <>
              <ProjectCard
                imgSrc={WEF}
                data-projectname='Solar energy in homes'
                location='Fintech World'
                title='Davos Fintech needs more invites'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/davos-fintech-needs-more-invites-a6d7a72edce3'}
                description='Fintech is the innovative solution we’ve all been looking for to tackle climate change.'
              />
              <ProjectCard
                imgSrc={Improving}
                data-projectname='Solar energy in homes'
                location='Climate Impact'
                title='Extreme weather has global leaders concerned'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/extreme-weather-has-global-leaders-concerned-f148bd03c865'}
                description='1,500 leaders ranked the top 3 global risks and all are environmental.'
              />
              <ProjectCard
                imgSrc={Team}
                data-projectname='Solar energy in homes'
                location='Pyrpose News'
                title='How to haven an aligned board and team?'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/you-know-how-you-have-an-aligned-team-and-board-db49a1cef0aa'}
                description='Pyrpose CEO spills the secrets to a strong and hardworking team!'
              />
            </>
          ) : (
            <>
              <ProjectCard
                imgSrc={Team}
                data-projectname='Solar energy in homes'
                location='Pyrpose News'
                title='How to haven an aligned board and team?'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/you-know-how-you-have-an-aligned-team-and-board-db49a1cef0aa'}
                description='Pyrpose CEO spills the secrets to a strong and hardworking team!'
              />
              <ProjectCard
                imgSrc={WEF}
                data-projectname='Solar energy in homes'
                location='Fintech World'
                title='Davos Fintech needs more invites'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/davos-fintech-needs-more-invites-a6d7a72edce3'}
                description='Fintech is the innovative solution we’ve all been looking for to tackle climate change.'
              />
              <ProjectCard
                imgSrc={Improving}
                data-projectname='Solar energy in homes'
                location='Climate Impact'
                title='Extreme weather has global leaders concerned'
                GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
                url={'https://news.pyrpose.io/extreme-weather-has-global-leaders-concerned-f148bd03c865'}
                description='1,500 leaders ranked the top 3 global risks and all are environmental.'
              />
            </>
          )}
        </div>
        <div className='w-full max-w-[95vw] sm:hidden overflow-x-scroll'>
          <div className={'flex flex-row gap-4'}>
            <ProjectCard
              imgSrc={WEF}
              data-projectname='Solar energy in homes'
              location='Fintech World'
              title='Davos Fintech needs more invites'
              GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
              url={'https://news.pyrpose.io/davos-fintech-needs-more-invites-a6d7a72edce3'}
              description='Fintech is the innovative solution we’ve all been looking for to tackle climate change.'
            />
            <ProjectCard
              imgSrc={Improving}
              data-projectname='Solar energy in homes'
              location='Climate Impact'
              title='Extreme weather has global leaders concerned'
              GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
              url={'https://news.pyrpose.io/extreme-weather-has-global-leaders-concerned-f148bd03c865'}
              description='1,500 leaders ranked the top 3 global risks and all are environmental.'
            />
            <ProjectCard
              imgSrc={Team}
              data-projectname='Solar energy in homes'
              location='Pyrpose News'
              title='How to haven an aligned board and team?'
              GaEvent={'HOME_BTN_PROJECT1_CLICKED'}
              url={'https://news.pyrpose.io/you-know-how-you-have-an-aligned-team-and-board-db49a1cef0aa'}
              description='Pyrpose CEO spills the secrets to a strong and hardworking team!'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsBlock