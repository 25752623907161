import React, { memo } from 'react'
import LogoIntro from '../assets/svg/logo_intro.svg'
const LoaderPulse = () => {
  return (
    <div
      className='min-h-screen flex items-center justify-center w-full'
      style={{ background: 'linear-gradient(180deg, #67D79A10 0%, #67D79A70 100%)' }}
    >
      <div className='w-[10vw] h-[10vh] object-cover mx-auto animate-pulse'>
        <img src={LogoIntro} alt='logo-intro' className={' w-full h-full'} />
      </div>
    </div>
  )
}

export default memo(LoaderPulse)
