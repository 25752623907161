import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { Girl, People, Building, Cooking } from '../../../../../assets/img/Carousel'
import PrimaryButton from '../../../../../components/PrimaryButton'
import InfoEcosystem from '../../../../../assets/svg/info-ecosystem.svg'
import ReactGA from 'react-ga4'
import TranslationText from '../../../../../components/TranslationText'

interface CarouselItemProps {
  title: string
  img: string
  text: string
  onClick?: () => void
  landing?: boolean
  lines?: {
    title: string
    text: string
  }[]
  isLanding?: boolean
}

interface PhotoCarouselProps {
  landing?: boolean
}


const CarouselItems = [
  {
    title: 'result-page.photo-carousel.card1.title',
    img: Girl,
    landing: true,
    lines: [
      {
        title: 'Estimated gatherings',
        text: '$349 K',
      },
      {
        title: 'CO2 offset (metric tonnes)',
        text: '3,847',
      },
      {
        title: 'Partnership since',
        text: '2023',
      },
    ],
    text: 'result-page.photo-carousel.card1.text',
  },
  {
    flag: '🇿🇦',
    title: 'result-page.photo-carousel.card2.title',
    img: People,
    landing: true,
    lines: [
      {
        title: 'Estimated gatherings',
        text: '$349 K',
      },
      {
        title: 'CO2 offset (metric tonnes)',
        text: '3,847',
      },
      {
        title: 'Partnership since',
        text: '2023',
      },
    ],
    text: 'result-page.photo-carousel.card2.text',
  },
  {
    title: 'result-page.photo-carousel.card3.title',
    img: Building,
    lines: [
      {
        title: 'Estimated gatherings',
        text: '$349 K',
      },
      {
        title: 'CO2 offset (metric tonnes)',
        text: '3,847',
      },
      {
        title: 'Partnership since',
        text: '2023',
      },
    ],
    landing: true,
    text: 'result-page.photo-carousel.card3.text',
  },
  {
    title: 'result-page.photo-carousel.card4.title',
    img: Cooking,
    landing: false,
    text: 'result-page.photo-carousel.card4.text',
  },
];


const CarouselItem: React.FC<CarouselItemProps> = ({
  title,
  img,
  onClick,
  lines,
  landing = false,
  isLanding = false,
}) => {
  if (isLanding && isLanding === landing || !isLanding) {
    return (
      <div className={'mx-6 rounded-[16px] flex-flex-col gap-4 relative overflow-hidden'}
           style={{
             boxShadow: '0px 12px 20px 0px #0000000D',
             background: 'linear-gradient(180deg, #00000080 0%, rgba(32, 32, 32, 0) 70%',
             marginBottom: isLanding ? '16px' : '0px',
             height: isLanding ? 'auto' : '530px',
             backgroundColor: isLanding ? '#00000070' : 'transparent',
           }}
      >
        <div className={'w-full  '}
             style={{
               height: isLanding ? 'auto' : '330px',
             }}
        >
          <img src={img} alt={'placeholder'} className={'w-full h-full bg-contain'} />
        </div>
        <div
          className='w-full px-4 py-8 flex-col justify-center items-start mt-[-240px] gap-4 inline-flex'
          style={{
            background: 'linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(0, 0, 0, 0.59098) 9.52%, #000000 25.57%, #030303 98.4%)',
            height: isLanding ? 'auto' : '298px',
          }}
        >
          <TranslationText className="self-stretch text-start text-white text-[22px] font-bold font-['Inter'] leading-relaxed"
                  translationKey={title} />
          {
            lines && landing && (
              <div className={'flex flex-col justify-between w-full gap-4'}>
                {lines.map((line, index) => (
                  <div key={index} className={'flex flex-row items-center w-full justify-between'}>
                    <div className={'text-white font-normal text-[16px]'}>{line.title}</div>
                    <div className={'text-white font-bold text-[16px]'}>{line.text}</div>
                  </div>
                ))}
              </div>
            )
          }
          <div className={'flex flex-row justify-start align-center w-full text-start gap-4 cursor-pointer'}
               onClick={onClick}>
            <div className="text-white font-normal font-['Inter'] hover:text-active-green ">Know more
            </div>
            <div className={'w-5 h-5 flex items-center justify-center pt-1 hover:fill-active-green'}>
              <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='9' cy='9.57324' r='8.5' stroke='white' />
                <path d='M6.92969 5.38623L11.165 9.5734L6.92969 13.7606' stroke='white' strokeLinecap='round'
                      strokeLinejoin='round' />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  } else return null
}
const PhotoCarouselModal: React.FC<CarouselItemProps> = ({
  title,
  img,
  text,
  onClick,
}) => {
  return (
    <div
      className={'fixed top-0 left-0 right-0 bottom-0 sm:max-w-[512px] sm:left-[50%] sm:ml-[-240px] rounded-[16px] mt-4 mx-4 bg-white z-[9999] overflow-y-hidden'}
      onScroll={(e) => {
        e.preventDefault()
      }
      }
    >
      <div className={'flex flex-col rounded-[16px] w-full relative overflow-y-scroll max-h-[calc(100vh)] mb-[100px]'}>
        <img src={img} alt={'placeholder'} className={'w-full rounded-t-[16px] bg-contain'} />
        <TranslationText className="self-stretch text-start text-[32px] font-bold mt-8 font-['Inter'] px-4 leading-[40px]"
                translationKey={title} />
        <div className={'h-[3px] w-1/3 my-4 ml-4'}>
          <div className={'w-full h-[1px] pt-[1px] bg-[#000000]'} />
        </div>
        <TranslationText className={'flex flex-row justify-start align-center w-full text-start px-4 '}
                translationKey={text} />
        <div className={'h-[100px] w-full py-[100px] '}>
        </div>
        <div className={'fixed bottom-0 left-4 right-4 z-[1100] max-w-[512px] sm:left-[50%] sm:ml-[-240px]'}>
          <div className={'w-full py-4 bg-white px-4 rounded-t-[16px] overflow-hidden z-[1100] '}
               style={{
                 boxShadow: '0px 0px 4px 0px #00000030',
               }}
          >
            <a href='#reduce-block' className={'z-[1100]'}>
              <PrimaryButton
                onClick={onClick}
                className={'w-full !shadow-2xl drop-shadow-2xl'}
              >
                <div className={'text-[24px] font-bold normal-case text-active-green'}>Make Impact</div>
              </PrimaryButton>
            </a>
          </div>
        </div>
        <div className={'absolute top-4 right-4 '} onClick={onClick}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='32' height='32' rx='16' fill='black' fillOpacity='0.5' />
            <path d='M20.9503 11.0503L11.0508 20.9498M20.9503 20.9498L11.0508 11.0503' stroke='white' strokeWidth='2'
                  strokeLinecap='round' />
          </svg>
        </div>
      </div>
    </div>
  )
}


const PhotoCarousel: React.FC<PhotoCarouselProps> = ({ landing = false }) => {
  const [activeModal, setActiveModal] = useState<CarouselItemProps | null>(null)
  return (
    <div className={'w-full flex-col items-center justify-center'}>
      <div
        className="w-full text-center text-stone-900 text-[32px] pb-8 font-bold font-['Inter'] leading-[35px]">Projects
        we support:
      </div>
        <Carousel
          showStatus={false}
          showThumbs={false}
          // showIndicators={false}
          showArrows={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          transitionTime={500}
          className={'w-full h-[570px]'}
        >
          {CarouselItems.map((item, index) => (
            <CarouselItem key={index} title={item.title} img={item.img} text={item.text}
                          isLanding={landing}
                          onClick={
                            () => {
                              ReactGA.event({
                                category: 'Scrolling Result',
                                action: `ResultProject${index}`,
                                label: `ResultProject${index}`,
                              })
                              setActiveModal(item)
                            }
                          } />
          ))}
        </Carousel>
      {activeModal && (
        <PhotoCarouselModal
          title={activeModal.title}
          img={activeModal.img}
          text={activeModal.text}
          onClick={() => {
            setActiveModal(null)
          }
          }
        />
      )}
      {!landing && (
        <div className={'pt-[56px] w-full flex-col gap-4 flex items-center justify-center px-4'}>
          <div
            className="w-full text-center pb-6 text-stone-900 text-[32px] font-bold font-['Inter'] leading-[35px]">How
            you make an impact:
          </div>
          <img src={InfoEcosystem} alt={'placeholder'} className={'w-full bg-contain'} />
        </div>
      )}
    </div>
  )
}

export default PhotoCarousel