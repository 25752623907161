import React from 'react'
import styled from 'styled-components'

interface ModalContainerProps {
  show: boolean
}

const ModalContainer = styled.div<ModalContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.2);
  backdrop-filter: blur(14px);
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  transition: all 0.8s ease-in-out;
  display: flex;
  z-index: ${(props) => (props.show ? '9999' : '-1')};
  opacity: ${(props) => (props.show ? '1' : '0')};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${(props) => (props.show ? 'fadeIn 0.4s' : 'none')};
`

const ModalContent = styled.div`
  padding: 32px;
  background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.07),
    0px -12px 20px rgba(0, 0, 0, 0.05), inset 0px 1px 0px #ffffff;
  border-radius: 32px;
  max-height: 90vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    border-radius: 32px 32px 0 0;
  }
`

interface ModalProps {
  show: boolean
  children: React.ReactNode
}

const Modal = ({ show, children }: ModalProps) => {
  return (
    <ModalContainer show={show}>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  )
}

export default Modal
