import React from 'react'

import Coins from '../../assets/icons/coins.svg'
import Impact from '../../assets/icons/impact.svg'
import Transparent from '../../assets/icons/transparency.svg'
import Support from '../../assets/icons/support.svg'
import Button from '../Button'
import Arrow from '../../icons/Arrow'
import ReactGA from 'react-ga4'


const impactData = [
  {
    title: 'Fund Climate Companies',
    description: 'Your funds are strategically allocated to carefully curated selection of climate companies. Companies create impactful change in areas such as renewable energy, and eco-friendly technologies.',
    icon: Coins,
  },
  {
    title: 'Reduce Future Emissions',
    description: 'The climate companies funded through Pyrpose are actively engaged in the process of directly contributing to the reduction of carbon emissions.',
    icon: Impact,
  },
  {
    title: 'Track Your Impact',
    description: 'You can track the real-time impact of your investment in your profile. Through regular email updates, you can see how your contribution is making a positive difference in the world.',
    icon: Transparent,
  },
  {
    title: 'Regenerate Your Influence',
    description: 'Your funds will be reused to initiate the same sustainable transformative process. At any point in your impact journey, you can decide to get your money back.',
    icon: Support,
  },
]

function ImpactSection() {
  return (
    <div className='w-full px-4 py-16 bg-white  '>
      <div className={'sm:w-[608px] w-full flex flex-col justify-start items-start gap-8 sm:mx-auto '}>
        <div className='flex flex-col justify-start items-start gap-2'>
          <div className='text-[#37992E] text-xs font-bold tracking-wide uppercase'>make an impact</div>
          <div className='text-stone-950 text-[28px] font-semibold leading-9 text-start' style={{
            fontFamily: 'IBM Plex Serif',
          }}>How you’re making a difference
          </div>
        </div>
        <div className='flex flex-col justify-start items-start gap-10 sm:flex-row sm:flex-wrap'>
          {impactData.map((item, index) => (
            <div key={index} className='flex flex-col justify-start items-start gap-4 sm:w-[45%]'>
              <div
                className='w-12 h-12 relative bg-[#59B250] rounded-[28px] border-8 border-[#E4F2E3] flex items-center justify-center'>
                <img className='w-6 h-6' src={item.icon} />
              </div>
              <div className='flex flex-col justify-start items-start gap-1'>
                <div className='text-black text-xl font-semibold text-start'>{item.title}</div>
                <div
                  className='text-[#525252] text-[17px] font-normal leading-normal text-start'>{item.description}</div>
              </div>
            </div>
          ))}
        </div>
        <Button text={<div className={'flex flex-row items-center justify-center gap-2'}>
          <div className='text-center text-[16px] font-medium '>Learn more</div>
          <Arrow />
        </div>} onClick={
          () => {
            ReactGA.event({
              category: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
              action: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
              label: 'HOME_BTN_MISSION_CLICKED_NEAR_VIDEO',
            })
            window.open('/how-it-works', '_self')
          }
        } disabled={false} type={'M'} />
      </div>
    </div>
  )
}

export default ImpactSection
